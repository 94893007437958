import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetManagePharmaList = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/pdv/por-usuario');

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const GetDetailPdv = async (uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get(`/incentive/pdv/${uuid}`);

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const PostManagerEdit = ({
  cnpj,
  corporate_name,
  company,
  zip,
  address,
  number,
  comp,
  district,
  city,
  state,
  manager_name,
  manager_email,
  recaptchaToken,
  uuid,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/pdv/alterar', {
        cnpj,
        corporate_name,
        company,
        zip,
        address,
        number,
        comp,
        district,
        city,
        state,
        manager_name,
        manager_email,
        uuid,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const CreatePdv = ({
  cnpj,
  corporate_name,
  company,
  zip,
  address,
  number,
  comp,
  district,
  city,
  state,
  manager_name,
  manager_email,
  recaptchaToken,
  uuid,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/pdv/cadastrar', {
        cnpj,
        corporate_name,
        company,
        zip,
        address,
        number,
        comp,
        district,
        city,
        state,
        manager_name,
        manager_email,
        uuid,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(response);
    } catch (error) {
      resolve(error);
    }
  });
};
