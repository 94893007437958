/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import RegisterConcludedSvg from '../../../../../Media/Incentive/Icons/checked-success.svg';

import './RegisterConcludedStyles.scss';
import Button from '../../../../../../Components/Button';
import { useNavigate } from 'react-router-dom';

const RegisterConcluded = ({ setStep, setVariation }) => {
  const sex = localStorage.getItem('sex');

  useEffect(() => {
    setVariation('variation-2');
  }, []);

  const navigate = useNavigate();

  return (
    <section
      className="register-cnpj-associated-success w-[320px] 
    rounded-[10px] flex flex-col items-center justify-center pt-[29px] 
    pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[20px] lg:mt-[0] lg:mb-[0]"
    >
      <img src={RegisterConcludedSvg} alt="" />
      <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
        CADASTRO REALIZADO
        <br />
        COM SUCESSO
      </h1>
      <p className="text-[18px] text-center leading-[1.2]  mt-[15px]">
        {`Seja bem-vind${sex === 'F' ? 'a' : 'o'}!`}
        <br />
        Aproveite as vantagens <br />
        exclusivas da nossa <br />
        excelente parceria.
      </p>

      <Button
        variant="bg-[#F7D64C] w-full text-[#031C7A] mt-[30px]"
        text="COMO PONTUAR"
        type="button"
        onClick={() => navigate('/como-pontuar')}
      />

      <Button
        variant="bg-[#28CEE3] w-full text-[#031C7A] mt-[14px]"
        text="PREMIAÇÃO"
        type="button"
        onClick={() => navigate('/premiacoes')}
      />
    </section>
  );
};

export default RegisterConcluded;
