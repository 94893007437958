/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from 'react-router-dom';
import Button from '../../../../Components/Button';
import WelcomeSVG from '../../../Media/Incentive/Pages/RecoverPassword/recover-password.svg';

import './WelcomeStyles.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useEffect, useState } from 'react';
import CreatePasswordOneEmail from './CreatePasswordOneEmail';
import CreatePasswordMoreThanOneEmail from './CreatePasswordMoreThanOneEmail';
import CreatePasswordZeroEmail from './CreatePasswordZeroEmail';
import RecoverPasswordSuccess from '../RecoverPasswordSuccess';
import Init from "../../../Utils/Initializer";

const Welcome = () => {
  Init();
  const [step, setStep] = useState();
  const location = useLocation();

  useEffect(() => {
    setStep(location.state.step);
  }, []);
  return (
    <LoginTemplate variation="variation-2">
      {(step === 1 || step === 5) && (
        <section className="welcome w-[320px] rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
          <img src={WelcomeSVG} alt="" />
          <h1 className="text-[24px] text-center mt-[15px] mb-[19px] leading-[1.2]">
            BEM VINDO <br /> AO NOVO SISTEMA
          </h1>
          <p className="text-[18px] text-center leading-[1.1] mb-[20px]">
            <span>Crie uma nova senha</span> para <br />
            ter ainda <span>mais segurança</span> e<br />
            <span>personalização</span> no seu acesso
            <br />
            ao Neo Pharma Excelência.
          </p>

          <Button
            variant="bg-[#F7D64C] w-full text-[#031C7A]"
            text="CRIAR NOVA SENHA"
            type="button"
            onClick={() => setStep( (step === 5) ? 6 : 2 )}
          />
        </section>
      )}

      {step === 2 && <CreatePasswordOneEmail setStep={setStep} />}
      {step === 3 && <CreatePasswordMoreThanOneEmail setStep={setStep} />}
      {step === 6 && <CreatePasswordZeroEmail setStep={setStep} />}
      {step === 4 && <RecoverPasswordSuccess />}
    </LoginTemplate>
  );
};

export default Welcome;
