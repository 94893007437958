/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import './RegisterNewPharmaDataStyles.scss';
// import step1PersonalDataMobile from '../../../../../../../Media/Incentive/Pages/Register/step2-personal-data-mobile.svg';
import { Controller, useForm } from 'react-hook-form';
import { getUfAndCity } from '../../../../Backend/getUfAndCity';
import InputText from '../../../../Components/InputText';
import Button from '../../../../Components/Button';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { validateCnpjOnBlur } from '../../../Utils/validateCnpj';
import { verifyCompleteNameOnBlur } from '../../../Utils/verifyCompleteName';
import CnpjNotEligible from './Steps/CnpjNotEligible';
import CpfNotEligible from './Steps/CpfNotEligible';
import CnpjAssociated from './Steps/CnpjAssociated';
import RegisterNewPharmaSuccess from '../RegisterNewPharmaSuccess';
import RegisterCnpjAssociatedSuccess from '../RegisterCnpjAssociatedSuccess';
import { CreatePdv } from '../../../../Backend/managePharma';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { CheckCnpj } from '../../../../Backend/checkCnpj';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from '../../../Utils/Initializer';

const RegisterNewPharma = () => {
  Init();
  const [step, setStep] = useState(0);
  const [cpfAndCnpjAssociated, setCpfAndCnpjAssociated] = useState();
  const [errorOutForm, setErrorOutForm] = useState();
  const [disableFields, setDisableFields] = useState(true);
  const [showScreen, setShowScreen] = useState();
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const {
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,

    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const recaptchaRef = useRef(null);

  const zipValue = watch('zip') ? watch('zip').replace(/-/, '') : '';

  const CheckTypedCnpj = async (recaptchaToken) => {
    setLoading(true);
    setErrorOutForm(null);
    const response = await CheckCnpj(watch('cnpj'), recaptchaToken);

    if (response.success) {
      setDisableFields(false);

      const arrayFields = Object.keys(response.data);

      arrayFields.forEach((field) => {
        if (field === 'email') {
          setValue('manager_email', response.data['email']);
          clearErrors('manager_email');
        }

        if (field === 'contact') {
          setValue('manager_name', response.data['contact']);
          clearErrors('manager_name');
        }
        setValue(field, response.data[field]);
        clearErrors(field);
      });
    } else if (
      response.data.statusCNPJ === 'inelegivel' ||
      response.code === 422
    ) {
      setStep(1);
    } else if (response.data.statusCNPJ === 'cpf_inelegivel') {
      setStep(5);
    } else if (response.data.statusCNPJ === 'associado') {
      setCpfAndCnpjAssociated({
        cnpj: watch('cnpj'),
      });
      setStep(2);
      setLoading(false);
      return;
    } else if (
      response.code !== 200 ||
      response.code !== 402 ||
      response.code !== 422
    ) {
      setErrorOutForm('Ocorreu um erro. Tente novamente ou contate o suporte.');
    }

    setLoading(false);
  };

  const onSubmit = async (data, recaptchaToken) => {
    setErrorOutForm(null);
    const response = await CreatePdv({ ...data, recaptchaToken });
    setLoading(false);

    if (response?.response?.data?.statusCNPJ === 'inelegivel') {
      setStep(1);
      setLoading(false);
      return;
    }

    if (response?.response?.data?.statusCNPJ === 'associado') {
      setCpfAndCnpjAssociated({
        cpf: data.cpf,
        cnpj: data.cnpj,
      });
      setStep(2);
      setLoading(false);
      return;
    }

    if (response?.data === 'PDV cadastrado com sucesso.') {
      setStep(3);
      return;
    }
  };

  const handleValidationsCnpj = (value) => {
    if (value) {
      recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
        recaptchaRef.current.reset();
        const isValid = validateCnpjOnBlur({
          inputCnpj: value,
          setError,
          clearErrors,
        });

        if (isValid) {
          CheckTypedCnpj(recaptchaToken);
        }
      });
    }
  };

  const handleCustomSubmit = (data) => {
    setLoading(true);
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    if (step === 0) {
      setDisableFields(true);
      setValue('cnpj', '');
      setValue('corporate_name', '');
      setValue('company', '');
      setValue('zip', '');
      setValue('address', '');
      setValue('number', '');
      setValue('comp', '');
      setValue('district', '');
      setValue('city', '');
      setValue('manager_name', '');
      setValue('manager_email', '');
    }
  }, [step]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <>
      {showScreen === true && (
        <>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
          {step === 1 && <CnpjNotEligible />}
          {step === 5 && <CpfNotEligible />}
          {step === 2 && (
            <CnpjAssociated
              setStep={setStep}
              cpf={cpfAndCnpjAssociated.cpf}
              cnpj={cpfAndCnpjAssociated.cnpj}
            />
          )}
          {step === 3 && <RegisterNewPharmaSuccess setStep={setStep} />}
          {step === 4 && <RegisterCnpjAssociatedSuccess setStep={setStep} />}
          {step === 0 && (
            <LoginTemplate variation="variation-1">
              <section
                className="register-new-pharma w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[130px] lg:!pl-[150px] lg:!pr-[150px] "
              >
                {/* <img src={step1PersonalDataMobile} alt="" /> */}

                <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[50px] w-[255px] lg:w-[768px] h-[99px] lg:h-[40px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
                  <h1 className="font-semibold">
                    PREENCHA AS
                    <br className="lg:hidden" /> INFORMAÇÕES DA{' '}
                    <br className="lg:hidden" /> NOVA FARMÁCIA
                  </h1>
                </div>

                <form
                  noValidate
                  onSubmit={handleSubmit(handleCustomSubmit)}
                  className="w-full"
                >
                  <div className="w-full flex flex-col gap-[20px] mt-[20px]">
                    <Controller
                      control={control}
                      name="cnpj"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="CNPJ"
                            styleLabel="!text-[#031E78]"
                            inputMode="numeric"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('cnpj');
                            }}
                            onBlur={() => {
                              handleValidationsCnpj(value);
                            }}
                            value={value}
                            mask="99.999.999/9999-99"
                            placeholder="00.000.000/0000-00"
                            errors={errors?.cnpj}
                          />
                        );
                      }}
                    />
                    {!disableFields && (
                      <>
                        <Controller
                          control={control}
                          name="corporate_name"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label="Razão social"
                                styleLabel="!text-[#031E78]"
                                placeholder="Razão social"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  clearErrors('company');
                                }}
                                value={value}
                                errors={errors?.corporate_name}
                              />
                            );
                          }}
                        />

                        <Controller
                          control={control}
                          name="company"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label="Nome fantasia"
                                styleLabel="!text-[#031E78]"
                                placeholder="Nome fantasia"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  clearErrors('corporate_name');
                                }}
                                value={value}
                                errors={errors?.company}
                              />
                            );
                          }}
                        />
                        <Controller
                          control={control}
                          name="zip"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label="CEP"
                                styleLabel="!text-[#031E78]"
                                placeholder="CEP"
                                inputMode="numeric"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  clearErrors('zip');
                                }}
                                value={value}
                                mask="99999-999"
                                errors={errors?.zip}
                                onBlur={() => {
                                  if (zipValue && zipValue.length === 8) {
                                    getUfAndCity({ zipValue, setValue });
                                  }
                                }}
                              />
                            );
                          }}
                        />

                        <Controller
                          control={control}
                          name="address"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label="Endereço"
                                placeholder="Endereço"
                                styleLabel="!text-[#031E78]"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  clearErrors('address');
                                }}
                                value={value}
                                errors={errors?.address}
                              />
                            );
                          }}
                        />
                        <div className="flex flex-col lg:flex-row gap-[18px]">
                          <Controller
                            control={control}
                            name="number"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputText
                                  label="Número"
                                  placeholder="Número"
                                  styleLabel="!text-[#031E78]"
                                  inputMode="numeric"
                                  onChange={(e) => {
                                    onChange(
                                      e.target.value.replace(/[^0-9]/g, '')
                                    );
                                    clearErrors('number');
                                  }}
                                  value={value}
                                  errors={errors?.number}
                                />
                              );
                            }}
                          />
                          <Controller
                            control={control}
                            name="comp"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputText
                                  label="Complemento"
                                  placeholder="Complemento"
                                  styleLabel="!text-[#031E78]"
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    clearErrors('comp');
                                  }}
                                  value={value}
                                  errors={errors?.comp}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="flex flex-col lg:flex-row gap-[18px]">
                          <Controller
                            control={control}
                            name="district"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputText
                                  label="Bairro"
                                  placeholder="Bairro"
                                  styleLabel="!text-[#031E78]"
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    clearErrors('district');
                                  }}
                                  value={value}
                                  errors={errors?.district}
                                />
                              );
                            }}
                          />
                          <Controller
                            control={control}
                            name="city"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputText
                                  label="Cidade"
                                  placeholder="Cidade"
                                  styleLabel="!text-[#031E78]"
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    clearErrors('city');
                                  }}
                                  value={value}
                                  errors={errors?.city}
                                />
                              );
                            }}
                          />
                        </div>
                        <Controller
                          control={control}
                          name="manager_name"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label={
                                  <span>
                                    Nome do responsável{' '}
                                    <br className="lg:hidden" />
                                    para receber comunicações
                                  </span>
                                }
                                placeholder="Nome do responsável"
                                styleLabel="!text-[#031E78]"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  clearErrors('manager_name');
                                }}
                                onBlur={() => {
                                  verifyCompleteNameOnBlur({
                                    inputName: value,
                                    fieldName: 'manager_name',
                                    setError,
                                    clearErrors,
                                  });
                                }}
                                value={value}
                                errors={errors?.manager_name}
                              />
                            );
                          }}
                        />
                        <Controller
                          control={control}
                          name="manager_email"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputText
                                label={
                                  <span>
                                    Email do responsável{' '}
                                    <br className="lg:hidden" />
                                    para receber comunicações
                                  </span>
                                }
                                placeholder="Email do responsável"
                                styleLabel="!text-[#031E78]"
                                onChange={(e) => {
                                  onChange(e.target.value.trim());
                                  clearErrors('manager_email');
                                }}
                                // onBlur={() => checkIfEmailIsValid()}
                                value={value}
                                errors={errors?.manager_email}
                              />
                            );
                          }}
                        />
                      </>
                    )}

                    <div className="flex items-center flex-col lg:flex-row justify-center w-full gap-[18px] lg:gap-[28px]">
                      <Button
                        text="VOLTAR"
                        variant="bg-[#28CEE3] text-[#031E78] w-full  lg:w-[220px]"
                        type="button"
                        onClick={() => navigate('/gerenciar-farmacias')}
                      />
                      <Button
                        text="PROSSEGUIR"
                        variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full  lg:w-[220px]"
                        type="submit"
                      />
                    </div>
                    {errorOutForm && (
                      <p className="text-center text-[#cc0000]">
                        {errorOutForm}
                      </p>
                    )}
                  </div>
                </form>

                <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
                  Restrito a farmácias aptas <br className="lg:hidden" />
                  a participar do programa. <br />
                  Os dados cadastrados <br className="lg:hidden" />
                  devem ser de acordo <br className="lg:hidden" />
                  com <br className="hidden lg:block" /> o estabelecimento{' '}
                  <br className="lg:hidden" />e seus responsáveis.
                </div>
              </section>
            </LoginTemplate>
          )}
        </>
      )}
    </>
  );
};

export default RegisterNewPharma;
