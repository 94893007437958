import { useNavigate, useLocation } from 'react-router-dom';
import { IsLogged } from '../../Backend/auth';
import { useContext } from 'react';
import { LoadingContext } from '../../Shared/LoadingContext';

const useVerifyIfIsLogged = () => {
  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();

  const navigate = useNavigate();
  const verifyIfIsLogged = async (setShowScreen) => {
    const response = await IsLogged();

    if (response.success) {
      localStorage.setItem(
        'nextPoints',
        JSON.stringify(response.data.nextPoints)
      );

      localStorage.setItem(
        'distributor',
        (response.data.stores.length > 0 ? 
        `${response.data.stores[0].state} - ${
          response.data.stores[0].distributor ?? ''
        }`
        : '')
      );

      localStorage.setItem('isLogged', 'logged');
      localStorage.setItem('name', String(response.data.name).split(' ')[0]);

      if (response.data.stores.length === 0) {
        if(location.pathname !== '/cadastrar-nova-farmacia') {
          navigate('/cadastrar-nova-farmacia');
        }
      } else {
        if (response.data.complete === false) {
          navigate('/confirmar-cadastro', { state: response.data });
        }
      }

      setLoading(false);
      setShowScreen(true);
    } else {
      localStorage.removeItem('isLogged');
      localStorage.removeItem('sex');
      localStorage.removeItem('name');
      navigate('/entrar');
      setLoading(false);
    }
  };

  return { verifyIfIsLogged };
};

export default useVerifyIfIsLogged;
