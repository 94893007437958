import Client from './Client';
import { formatResponse } from './formatResponse';

export const ValidateToken = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/forgot/password/form', {
        token,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
