import Client from './Client';
import qs from 'qs';
import { formatResponse } from './formatResponse';

export async function ForgotPassword(cpf, recaptchaToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        '/incentive/forgot/password',
        qs.stringify({ cpf, 'g-recaptcha-response': recaptchaToken })
      );
      resolve(formatResponse(response));
    } catch (err) {
      resolve(formatResponse(err));
    }
  });
}

export async function ForgotPasswordSave(
  password,
  password_confirmation,
  token,
  recaptchaToken
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        '/incentive/forgot/password/save',
        qs.stringify({
          password,
          password_confirmation,
          token,
          'g-recaptcha-response': recaptchaToken,
        })
      );
      resolve(formatResponse(response));
    } catch (err) {
      resolve(formatResponse(err));
    }
  });
}
