import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import RegisterConcludedSvg from '../../../../Resources/Media/Incentive/Icons/checked-success.svg';
import './EditPharmaSuccessStyles.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import Init from "../../../Utils/Initializer";

const EditPharmaSuccess = () => {
  Init();
  const navigate = useNavigate();
  return (
    <LoginTemplate variation="variation-2">
      <section className="edit-pharma-success w-[320px] h-[393px] rounded-[10px]  flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
        <img src={RegisterConcludedSvg} alt="" />
        <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
          DADOS DA FARMÁCIA <br />
          EDITADOS COM SUCESSO
        </h1>
        <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
          Obrigado por manter suas <br />
          <span>informações atualizadas.</span>
        </p>

        <div className="flex flex-col w-full gap-[14px]">
          <Button
            variant="bg-[#28CEE3] w-full text-[#031C7A] text-[18px]"
            text="GERENCIAR FARMÁCIAS"
            type="button"
            onClick={() => navigate('/gerenciar-farmacias')}
          />

          <Button
            variant="bg-[#F7D64C] w-full text-[#031C7A] text-[18px]"
            text="COMO PONTUAR"
            type="button"
            onClick={() => navigate('/como-pontuar')}
          />
        </div>
      </section>
    </LoginTemplate>
  );
};

export default EditPharmaSuccess;
