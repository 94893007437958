import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import ContactSuccessSvg from '../../../Media/Incentive/Icons/checked-success.svg';
import './ContactSuccessStyles.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useContext } from 'react';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import Init from "../../../Utils/Initializer";

const ContactSuccess = () => {
  Init();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  return (
    <LoginTemplate variation="variation-2">
      <section className="contact-success w-[320px]  rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
        <img src={ContactSuccessSvg} alt="" />
        <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
          SUA MENSAGEM
          <br />
          FOI ENVIADA
        </h1>
        <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
          Obrigado por entrar em contato.
          <br />
          <br />
          Nossa equipe responderá
          <br />o mais rápido possível.
        </p>

        <Button
          variant="bg-[#F7D64C] w-full text-[#031C7A]"
          text="COMO PONTUAR"
          type="button"
          onClick={() => {
            setLoading(true);
            navigate('/como-pontuar');
          }}
        />
      </section>
    </LoginTemplate>
  );
};

export default ContactSuccess;
