export function validateCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  //   Verificar se o CNPJ possui 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verificar se todos os dígitos são iguais (caso contrário, não é um CNPJ válido)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Validar o primeiro dígito verificador
  let sum = 0;
  let weight = 5;

  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight;
    weight--;
    if (weight < 2) {
      weight = 9;
    }
  }

  let mod = sum % 11;
  let digit = mod < 2 ? 0 : 11 - mod;

  if (parseInt(cnpj.charAt(12)) !== digit) {
    return false;
  }

  // Validar o segundo dígito verificador
  sum = 0;
  weight = 6;

  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight;
    weight--;
    if (weight < 2) {
      weight = 9;
    }
  }

  mod = sum % 11;
  digit = mod < 2 ? 0 : 11 - mod;

  if (parseInt(cnpj.charAt(13)) !== digit) {
    return false;
  }

  // Se passou por todas as verificações, o CNPJ é válido
  return true;
}

export const validateCnpjOnBlur = ({ setError, cleaErrors, inputCnpj }) => {
  const isValid = validateCNPJ(inputCnpj);

  if (!isValid) {
    setError('cnpj', { type: 'manual', message: 'Digite um CNPJ válido' });
  }

  return isValid;
};
