/* eslint-disable react-hooks/exhaustive-deps */
import './CnpjAssociatedStyles.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { schema } from './schema';
import InputText from '../../../../../../Components/InputText';
import Button from '../../../../../../Components/Button';
import { useContext, useEffect, useRef, useState } from 'react';

import { checkIfPhoneIsCompleted } from '../../../../../../Shared/validations';
import { LoadingContext } from '../../../../../../Shared/LoadingContext';
import { ChangeCnpjManager } from '../../../../../../Backend/register';
import { LoginTemplate } from '../../../../Templates/LoginTemplate';
import { verifyCompleteNameOnBlur } from '../../../../../Utils/verifyCompleteName';
import { GetUserCpf } from '../../../../../../Backend/auth';
import { checkIfEmailIsValid } from '../../../../../Utils/validateEmail';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrollToFirstError } from '../../../../../Utils/ScrollToErrors';

const CnpjAssociated = ({ cpf, cnpj, setStep }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({ resolver: yupResolver(schema) });

  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const { setLoading } = useContext(LoadingContext);

  const inputMobile = watch('mobile');

  const recaptchaRef = useRef(null);

  const getCpf = async () => {
    const response = await GetUserCpf();

    if (response.success) {
      setValue('cpf', response.data.cpf);
    }
  };

  const setErrorsInputs = (errors) => {
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        setError(field, {
          type: 'manual',
          message: errors[field][0],
        });

        setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      }
    }

    setLoading(false);
  };

  const verifyResponse = (response) => {
    if (response.success) {
      setStep(4);
      setLoading(false);
    } else {
      setErrorsInputs(response.data);
    }
  };

  const onSubmit = async (data, recaptchaToken) => {
    setLoading(true);

    const response = await ChangeCnpjManager({
      cpf: data.cpf.replace(/[^\d]/g, ''),
      cnpj: data.cnpj.replace(/[^\d]/g, ''),
      name: data.name,
      email: data.email,
      mobile: data.mobile.replace(/[^\d]/g, ''),
      recaptchaToken,
    });

    verifyResponse(response);
  };

  const handleCustomSubmit = (data) => {
    setLoading(true);
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  useEffect(() => {
    setValue('cpf', cpf);
    setValue('cnpj', cnpj);
  }, []);

  useEffect(() => {
    if (inputMobile?.length === 15) {
      clearErrors('mobile');
    }
  }, [inputMobile]);

  useEffect(() => {
    getCpf();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <LoginTemplate variation="variation-1">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />

      <section
        className="cnpj-associated w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
    items-center justify-center mb-[60px] mt-[130px] lg:!pl-[150px] lg:!pr-[150px]"
      >
        <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[70px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[20px] lg:pr-[20px] flex flex-col items-center justify-center pt-[7px] lg:mt-[30px]">
          <h1 className="font-semibold">
            SOLICITAR TROCA <br className="lg:hidden" />
            DE RESPONSÁVEL
          </h1>
        </div>
        <p className="text-center text-[20px] text-[#031E78] leading-[1.2] mb-[25px] mt-[20px]">
          Já existe um{' '}
          <strong>
            CPF <br className="lg:hidden" />
            cadastrado nesta farmácia.
          </strong>
          <br className="lg:hidden" />
          <br />
          Caso deseje, preencha <br className="lg:hidden" />o formulário e{' '}
          <strong>
            solicite a troca
            <br className="lg:hidden" /> de responsável.{' '}
          </strong>
        </p>

        <form
          className="flex flex-col items-center w-full"
          onSubmit={handleSubmit(handleCustomSubmit)}
        >
          <div className="w-full flex flex-col gap-[20px]">
            <div className="flex flex-col lg:flex-row w-full gap-[20px]">
              <Controller
                control={control}
                name="cpf"
                render={({ field: { value } }) => {
                  return (
                    <InputText
                      label="CPF"
                      value={value}
                      disabled
                      mask="999.999.999-99"
                      errors={errors?.cpf}
                      inputMode="numeric"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="cnpj"
                render={({ field: { value } }) => {
                  return (
                    <InputText
                      label="CNPJ"
                      value={value}
                      disabled
                      errors={errors?.cnpj}
                      inputMode="numeric"
                    />
                  );
                }}
              />
            </div>

            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => {
                return (
                  <InputText
                    label="Nome completo"
                    placeholder="Nome completo"
                    onChange={onChange}
                    value={value}
                    errors={errors?.name}
                    onBlur={() => {
                      verifyCompleteNameOnBlur({
                        inputName: value,
                        fieldName: 'name',
                        setError,
                        clearErrors,
                      });
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => {
                return (
                  <InputText
                    label="E-mail"
                    placeholder="E-mail"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value.trim());
                    }}
                    errors={errors?.email}
                    onBlur={() => {
                      checkIfEmailIsValid({
                        inputEmail: value,
                        setError,
                        clearErrors,
                      });
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="mobile"
              render={({ field: { onChange, value } }) => {
                return (
                  <InputText
                    label="Celular"
                    placeholder="Celular"
                    onChange={onChange}
                    value={value}
                    errors={errors?.mobile}
                    mask="(99) 99999-9999"
                    inputMode="numeric"
                    onBlur={() =>
                      checkIfPhoneIsCompleted({
                        setError,
                        clearErrors,
                        inputMobile: value,
                      })
                    }
                  />
                );
              }}
            />
          </div>

          <Button
            text="ENVIAR"
            variant="bg-[#ffd800] text-[#031E78] mt-[28px] lg:w-[287px]"
            type="submit"
          />
        </form>

        <div className="bg-[#28CEE3] text-[18px] w-full lg:w-[488px] text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
          Restrito a farmácias aptas <br className="lg:hidden" />
          a participar do programa. <br />
          Os dados cadastrados <br className="lg:hidden" />
          devem ser de acordo <br className="lg:hidden" />
          com <br className="hidden lg:block" /> o estabelecimento{' '}
          <br className="lg:hidden" />e seus responsáveis.
        </div>
      </section>
    </LoginTemplate>
  );
};

export default CnpjAssociated;
