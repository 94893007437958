import React from 'react';

import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useLocation } from 'react-router-dom';

const Maintenance = () => {
  const location = useLocation();

  const origin = location.search.split('=')[1];

  const renderMessageByOrigin = () => {
    if (origin === 'painel') {
      return <p>MENSAGEM DO PAINEL</p>;
    } else if (origin === 'backoffice') {
      return <p>MENSAGEM DO BACKOFFICE</p>;
    } else if (!origin || origin !== 'painel' || origin !== 'backoffice') {
      return (
        <p className="text-center font-medium text-[20px] lg:text-[24px] leading-[23px] lg:leading-[25px] text-[#606060]">
          Retorne no dia <br className="lg:hidden" />
          <span className="text-[#031E78]">18 de dezembro</span>
          <br className="hidden lg:block" /> para <br className="lg:hidden" />
          <span className="text-[#031E78]">atualizar seus dados.</span>
        </p>
      );
    }
  };
  return (
    <LoginTemplate variation="variation-3">
      <section className="maintenance block w-full">
        <h1 className="text-[26px] lg:text-[30px] text-center mb-[26px]">
          SITE EM MANUTENÇÃO
        </h1>
        <div className="w-full max-w-[320px] lg:max-w-[798px] m-auto bg-[#031E78] rounded-[10px] lg:rounded-[20px] block p-[15px] py-[25px] lg:py-[45px]">
          <h2 className="!font-normal text-white text-[20px] lg:text-[24px] text-center leading-[25px] lg:leading-[30px] block max-w-[232px] lg:max-w-[592px] m-auto">
            Estamos trabalhando na atualização do Portal <br />
            Neo Pharma Excelência, retorne em breve.
            {/* na atualização <br className="hidden lg:block" /> do Portal{' '}
            <br className="lg:hidden" />
            Neo Pharma Excelência, <br className="lg:hidden" />
            para oferecer <br className="hidden lg:block" /> muito mais{' '}
            <br className="lg:hidden" />
            <span className="text-[#FFD800]">praticidade</span> e{' '}
            <span className="text-[#FFD800]">segurança</span>{' '}
            <br className="lg:hidden" />a você, parceiro. */}
          </h2>
          {/* <div className="w-full max-w-[286px] lg:max-w-[553px] border border-1 border-[#707070] m-auto bg-[#FFFFFF] rounded-[10px] lg:rounded-[39px] block p-[15px] py-[5px] mt-5">
            {renderMessageByOrigin()}
          </div> */}
        </div>
      </section>
    </LoginTemplate>
  );
};

export default Maintenance;
