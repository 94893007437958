import Client from './Client';
import { formatResponseCheckCnpj } from './formatResponse';

export const CheckCnpj = (cnpj, recaptchaToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/pdv', {
        param: 'valid_pdv_by_cnpj',
        cnpj,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponseCheckCnpj(response));
    } catch (error) {
      resolve(formatResponseCheckCnpj(error));
    }
  });
};
