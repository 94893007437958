/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom';
import './LoginStyles.scss';
import { SignIn } from './Signin';
import { Register } from './Register';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { InitWithoutEffect } from '../../../Utils/Initializer';

const Login = () => {
  const [showScreen, setShowScreen] = useState();

  const { setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const isLogged = localStorage.getItem('isLogged');

  useEffect(() => {
    if (isLogged) {
      navigate('/');
    } else {
      InitWithoutEffect();
      setLoading(false);
      setShowScreen(true);
    }
  }, []);
  return (
    <>
      {showScreen === true && (
        <LoginTemplate variation="variation-1">
          <section className="flex flex-col items-center justify-center mb-[60px] lg:mb-[0px]">
            <h1 className="text-[30px] text-center leading-[1] hidden lg:block mt-[33px]">
              ACESSE E APROVEITE
              <br />O MELHOR DA NOSSA PARCERIA
            </h1>
            <div className="flex flex-col lg:flex-row gap-[25px] lg:gap-[130px] mt-[23px] lg:mt-[60px] lg:mb-[60px]">
              <SignIn />
              <Register />
            </div>
          </section>
        </LoginTemplate>
      )}
    </>
  );
};

export default Login;
