import Client from './Client';
import { formatResponse } from './formatResponse';

export async function PostEditProfile({
  zip,
  city,
  cpf,
  name,
  mobile,
  birth_at,
  sex,
  birth,
  state,
  email,
  companies,
  email_confirmation,
  password,
  password_confirmation,
  optin_rules,
  optin_privacy,
  recaptchaToken,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/atualizar-dados', {
        cpf,
        name,
        mobile,
        birth,
        sex,
        email,
        email_confirmation,
        password,
        password_confirmation,
        'g-recaptcha-response': recaptchaToken,
      });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
