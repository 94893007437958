import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string().required('Esta informação é necessária'),
  birth: Yup.string().required('Esta informação é necessária.'),
  sex: Yup.string().required('Esta informação é necessária.'),
  mobile: Yup.string().required('Esta informação é necessária.'),
  email: Yup.string().required('Esta informação é necessária.'),
  email_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('email')], 'Os e-mails não correspondem'),
  password: Yup.string()
    .test('valid', '', (value) => {
      if (!value) {
        return false;
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);

      return hasUpperCase && hasLowerCase && hasNumber;
    })
    .min(6, 'A senha deve conter no mínimo 6 caracteres.')
    .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula.')
    .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula.')
    .matches(/[0-9]/, 'A senha deve conter ao menos um número.'),
  password_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('password')], 'As senhas não correspondem'),
  cnpj: Yup.string().required('Esta informação é necessária.'),
  corporate_name: Yup.string().required('Esta informação é necessária.'),
  company: Yup.string().required('Esta informação é necessária.'),
  zip: Yup.string()
    .required('Esta informação é necessária.')
    .test('verify if zip is complete', 'Digite um CEP válido', (value) => {
      const isCompleted = value.replace(/-/, '').length === 8;

      return isCompleted;
    }),
  address: Yup.string().required('Esta informação é necessária.'),
  number: Yup.string().required('Esta informação é necessária.'),
  comp: Yup.string(),
  district: Yup.string().required('Esta informação é necessária.'),
  city: Yup.string().required('Esta informação é necessária.'),
  manager_name: Yup.string().required('Esta informação é necessária.'),
  manager_email: Yup.string()
    .required('Esta informação é necessária.')
    .email('Digite um email válido.'),
  optin_rules: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf(
      [true],
      'Aceite o regulamento e receber informações do Neo Pharma Excelência para prosseguir.'
    ),
  optin_privacy: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf(
      [true],
      'Aceite os termos de políticas de privacidade para prosseguir.'
    ),
  optin_commercial: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf([true], 'É necessário confirmar para prosseguir.'),
});
