import Init from "../../../Utils/Initializer";

const notFound = () => {
  Init();
  return (
    <>
      <h1>Not Found</h1>
    </>
  )
}

export default notFound
