import Client from './Client';
import { formatResponse } from './formatResponse';

export const ValidateFirstStep = ({
  email,
  mobile,
  recaptchaToken,
  email_confirmation,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(`/incentive/register-valid-step1`, {
        email,
        email_confirmation,
        mobile,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const ValidateSecondStep = ({ cnpj, recaptchaToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(`/incentive/register-valid-step2`, {
        cnpj,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

/**/

export const UpdateValidateFirstStep = ({
  email,
  mobile,
  recaptchaToken,
  email_confirmation,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(`/incentive/atualizar-dados/register-valid-step1`, {
        email,
        email_confirmation,
        mobile,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const UpdateValidateSecondStep = ({ cnpj, recaptchaToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(`/incentive/atualizar-dados/register-valid-step2`, {
        cnpj,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};