import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../../Components/Button';
import InputText from '../../../../../Components/InputText';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useNavigate } from 'react-router-dom';
import { VerifyRegister } from '../../../../../Backend/register';
import { stepsEnum } from '../../Register/stepsEnum';
import { useContext, useEffect, useRef, useState } from 'react';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import useControlErrors from './hooks/useControlErrors';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrollToFirstError } from '../../../../Utils/ScrollToErrors';

export const Register = () => {
  const [errorOutForm, setErrorOutForm] = useState();
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const { verifyErrorResultAndSetPage } = useControlErrors({
    setError,
    setLoading,
    setErrorOutForm,
    setScrollToErrorUpdate,
    scrollToErrorUpdate,
  });

  const recaptchaRefRegister = useRef(null);

  const handleCustomSubmit = (data) => {
    setErrorOutForm(null);
    recaptchaRefRegister.current.executeAsync().then((recaptchaToken) => {
      recaptchaRefRegister.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const onSubmit = async (data, recaptchaToken) => {
    setLoading(true);
    const response = await VerifyRegister(data.cpf, data.cnpj, recaptchaToken);

    if (response.success) {
      navigate('/cadastre-se', {
        state: {
          cpf: data.cpf,
          cnpj: data.cnpj,
          step: stepsEnum.correctCpfAndCnpj,
        },
      });
      setLoading(false);
    } else {
      verifyErrorResultAndSetPage({ response, data });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <form onSubmit={handleSubmit(handleCustomSubmit)}>
      <div className="register pl-[17px] pr-[17px] pt-[35px] pb-[60px] rounded-[10px] w-[320px] flex flex-col items-center justify-center">
        <h2 className="text-[24px]">CADASTRE-SE</h2>
        <ReCAPTCHA
          ref={recaptchaRefRegister}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
        <Controller
          control={control}
          name="cpf"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="CPF"
                errors={errors.cpf}
                placeholder="Insira aqui seu CPF"
                classInputContainer={`${errors.cpf ? '' : 'mb-5'}`}
                onChange={onChange}
                mask="999.999.999-99"
                inputType="text"
                inputMode="numeric"
                value={value}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="cnpj"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="CNPJ"
                errors={errors.cnpj}
                placeholder="Insira aqui seu CNPJ"
                classInputContainer={`${errors.cnpj ? '' : 'mb-[28px]'}`}
                onChange={onChange}
                mask="99.999.999/9999-99"
                inputType="text"
                inputMode="numeric"
                value={value}
              />
            );
          }}
        />

        <Button
          variant={`bg-[#F7D64C] w-full text-[#031C7A] mt-[36px] `}
          text="CADASTRAR"
        />
        {errorOutForm ? (
          <p className="text-[#cc0000]">{errorOutForm}</p>
        ) : undefined}
      </div>
    </form>
  );
};
