import * as Yup from 'yup';
import { validateCPF } from '../../../../../Utils/validateCPF';

export const schema = Yup.object({
  cpf: Yup.string()
    .required('Esta informação é necessária.')
    .test('validateCpf', 'Insira um CPF válido.', (value) => {
      const validation = validateCPF(value);

      return validation;
    }),
  cnpj: Yup.string().required('Esta informação é necessária.'),
  name: Yup.string().required('Esta informação é necessária.'),

  mobile: Yup.string().required('Esta informação é necessária.'),

  email: Yup.string()
    .email('Insira um e-mail válido.')
    .required('Esta informação é necessária.'),
});
