import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const TableResult = ({
  inputYear,
  setArrayMonth,
  dataTable,
  setPeriodValue,
  loadingTable,
  setLoadingTable,
}) => {
  const [arrayMonths, setArrayMonths] = useState(inputYear?.[0]?.months);
  const [selectedYear, setSelectedYear] = useState(inputYear?.[0]?.year);

  return (
    <>
      <div className="result-table w-[320px] lg:!w-full flex flex-col items-center justify-center">
        <form className=" mx-auto mb-10 flex flex-col w-full  px-[20px]  lg:flex-row items-center justify-center gap-[20px]">
          <select
            onChange={(e) => {
              const arraySelected = inputYear.find(
                (item) => item.year === e.target.value
              )?.months;

              setSelectedYear(e.target.value);
              setArrayMonths(arraySelected);
              setLoadingTable(true);
              setPeriodValue(
                (prev) => `${e.target.value ?? ''}${arraySelected?.[0]?.month}`
              );
            }}
            name=""
            id=""
            className="rounded-full w-full lg:w-[260px]"
          >
            {inputYear?.map((item, index) => {
              return (
                <option key={index} value={item.year}>
                  {item.year}
                </option>
              );
            })}
          </select>
          <select
            onChange={(e) => {
              setLoadingTable(true);
              setPeriodValue(() => `${selectedYear}${e.target.value}`);
            }}
            name=""
            id=""
            className="rounded-full w-full lg:w-[260px]"
          >
            {arrayMonths?.map((item) => {
              return (
                <option className="p-[10px]" value={item.month}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </form>
        {loadingTable ? (
          <div className="w-full flex items-center justify-center">
            <FaSpinner className="animate-spin mr-2 w-full h-[32px]" />
          </div>
        ) : (
          <>
            <div className="result-table-div-table bg-[#04287F] mx-auto">
              <h3 className="text-center text-[#FFD800]">
                PONTUAÇÃO {String(dataTable.result?.[0]?.period).toUpperCase()}
              </h3>
            </div>

            <table className="hidden lg:block">
              <thead>
                <tr>
                  <th></th>
                  <th className="bg-[#F3F5FF] text-[#031C7A]">
                    Comprou, Ganhou
                  </th>
                  <th className="bg-[#F3F5FF] text-[#031C7A]">Aceleradores</th>
                  <th className="bg-[#031C7A] text-[#2EDED8]">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Compras</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    R$ {dataTable.result?.[0]?.values?.price}
                  </td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    R$ {dataTable.result?.[0]?.values?.price_accelerator}
                  </td>
                  <td className="bg-[#031C7A] text-[#2EDED8]">
                    R$ {dataTable.result?.[0]?.values?.price}
                  </td>
                </tr>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Pontos</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    {dataTable.result?.[0]?.points?.sale}
                  </td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    {dataTable.result?.[0]?.points?.accelerator}
                  </td>
                  <td className="bg-[#031C7A] text-[#2EDED8]">
                    {dataTable.result?.[0]?.points?.total}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="lg:hidden mx-auto">
              <thead>
                <tr>
                  <th colspan="2" className="bg-[#F3F5FF] text-[#031C7A]">
                    Comprou, Ganhou
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Compras</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    R$ {dataTable.result?.[0]?.values?.price}
                  </td>
                </tr>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Pontos</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    {dataTable.result?.[0]?.points?.sale}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colspan="2" className="bg-[#F3F5FF] text-[#031C7A]">
                    Aceleradores
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Compras</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    R$ {dataTable.result?.[0]?.values?.price_accelerator}
                  </td>
                </tr>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Pontos</td>
                  <td className="bg-[#F7D64C] text-[#031C7A]">
                    {dataTable.result?.[0]?.points?.accelerator}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr>
                  <th colspan="2" className="bg-[#031C7A] text-[#2EDED8]">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Compras</td>
                  <td className="bg-[#031C7A] text-[#2EDED8]">
                    R$ {dataTable.result?.[0]?.values?.price}
                  </td>
                </tr>
                <tr>
                  <td className="bg-[#F3F5FF] text-[#031C7A]">Pontos</td>
                  <td className="bg-[#031C7A] text-[#2EDED8]">
                    {' '}
                    {dataTable.result?.[0]?.points?.total}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default TableResult;
