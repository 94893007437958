export const NoChecked = ({ error }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g
        id="Grupo_4107"
        data-name="Grupo 4107"
        transform="translate(-922 -554)"
      >
        <g
          id="Elipse_155"
          data-name="Elipse 155"
          transform="translate(922 554)"
          fill="#fff"
          stroke="#031e78"
          stroke-width="1"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6.5" fill="none" />
        </g>
        <circle
          id="Elipse_156"
          data-name="Elipse 156"
          cx="4"
          cy="4"
          r="4"
          transform="translate(925 557)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export const Checked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g
        id="Grupo_4108"
        data-name="Grupo 4108"
        transform="translate(-922 -554)"
      >
        <g
          id="Elipse_155"
          data-name="Elipse 155"
          transform="translate(922 554)"
          fill="#fff"
          stroke="#031e78"
          stroke-width="1"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6.5" fill="none" />
        </g>
        <circle
          id="Elipse_156"
          data-name="Elipse 156"
          cx="4"
          cy="4"
          r="4"
          transform="translate(925 557)"
          fill="#031e78"
        />
      </g>
    </svg>
  );
};
