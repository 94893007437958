/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import DefaultTemplate from '../../Templates/DefaultTemplate';
import './ResultStyles.scss';

import premius from '../../../Media/Incentive/Pages/Result/products-results.webp';
import PointsLevel from '../../../../Components/PointsLevel';
import TableResult from './Table';
import Button from '../../../../Components/Button';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import {
  ChangeCurrentStore,
  GetResults,
  GetResultsTable,
} from '../../../../Backend/results';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import Init from "../../../Utils/Initializer";

const Result = () => {
  Init();
  const [showScreen, setShowScreen] = useState();
  const [nextPoints, setNextPoints] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [dataResult, setDataResult] = useState({ stores: [], periods: [] });
  const [periodValue, setPeriodValue] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const [dataTable, setDataTable] = useState([]);

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const { setLoading } = useContext(LoadingContext);

  const handleSetPeriods = ({ year, month }) => {
    if (year || month) {
      setPeriodValue(`${year ?? ''}${month ?? ''}`);
    }
  };

  const getData = async () => {
    setLoading(true);
    const response = await GetResults();
    if (response.success) {
      setDataResult({
        stores: response.data.stores,
        periods: response.data.periods,
      });

      setSelectedStore(response.data.active_store);

      handleSetPeriods({
        year: response.data.periods?.[0]?.year,
        month: response.data.periods?.[0]?.months?.[0]?.month,
      });
      setNextPoints(response.data.nextPoints);

      setLoading(false);
    }
  };

  const getDataTable = async () => {
    setLoadingTable(true);
    const response = await GetResultsTable(periodValue);

    if (response.success) {
      setDataTable(response.data);
      setLoadingTable(false);
    }
  };

  const handleChangeStore = async (e) => {
    setLoading(true);
    const response = await ChangeCurrentStore(e.target.value);

    if (response.success) {
      window.location.reload();
    }
  };

  const points = localStorage.getItem('points');

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getData();
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    if (dataResult?.periods?.length) {
      getDataTable();
    }
  }, [dataResult, periodValue]);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="result lg:mt-0 -mt-2 mb-16">
            <div className="result-banner">
              <div data-aos="fade-right">
                <div class="title flex items-end">
                  <div class="title-col bg-[#031C7A]">
                    <h1 className="text-[#F7D64C] font-semibold">RESULTADOS</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-point lg:mt-8 mt-32">
              <h1 className="lg:text-[30px] text-[18px] text-[#04287F] font-semibold lg:text-center text-start lg:w-[558px] w-[184px] mx-auto">
                ACOMPANHE SEU DESEMPENHO E SUAS CONQUISTAS NO NOSSO PROGRAMA.
              </h1>

              <div className="mb-[40px] px-[20px] mt-[40px] w-full flex items-center justify-center">
                <select
                  onChange={(e) => {
                    handleChangeStore(e);
                    setSelectedStore(e.target.value);
                    // setSelectedMonth(e.target.value);
                  }}
                  value={selectedStore}
                  name=""
                  id=""
                  className="rounded-full  w-[320px] lg:w-[260px]"
                >
                  {dataResult.stores?.map((item) => {
                    return <option value={item.uuid}>{item.title}</option>;
                  })}
                </select>
              </div>

              <div className="flex lg:flex-row flex-col justify-center items-center gap-5 mt-6">
                <div className="flex flex-col items-center justify-end bg-[#FFD800] rounded-[30px] lg:w-[444px] w-[320px] h-[310px]">
                  <div className="relative top-8 lg:top-0  text-center">
                    <h1 className="text-[28px] text-[#04287F] font-semibold">
                      {points === '-' ? '-' : points} PONTOS
                    </h1>
                    <p className="text-[22px] text-[#04287F] font-medium text-center leading-10">
                      DISPONÍVEIS
                      <br />
                      PARA RESGATE.
                    </p>
                  </div>
                  <picture>
                      <img
                        src={premius}
                        alt="Prêmios"
                        width={256}
                        className="mx-auto lg:mt-5 mt-10"
                      />
                    </picture>
                </div>
                <div>
                  <PointsLevel data={nextPoints} />
                </div>
              </div>

              {dataResult?.periods?.length && (
                <>
                  <div className="result-bang bg-[#FFD800] lg:w-[908px] w-[320px] rounded-full mx-auto lg:py-3 py-2 lg:my-10 my-8">
                    <h1 className="text-[#04287F] lg:text-[24px] text-[20px] font-semibold text-center leading-none lg:ml-0 ml-8">
                      VEJA OS PONTOS
                      <br className="lg:hidden" /> CONQUISTADOS
                      <br className="lg:hidden" /> A CADA MÊS.
                    </h1>
                  </div>

                  <div className="flex flex-col justify-center items-center gap-10">
                    <TableResult
                      inputYear={dataResult.periods}
                      dataTable={dataTable}
                      setPeriodValue={setPeriodValue}
                      loadingTable={loadingTable}
                      setLoadingTable={setLoadingTable}
                    />

                    <Link
                      to="/resgatar-premios"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        variant="lg:mt-8 mt-10"
                        text="RESGATAR PRÊMIOS"
                      />
                    </Link>

                  </div>
                </>
              )}
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default Result;
