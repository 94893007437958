import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from './Shared/LoadingContext';
import { UserProvider } from './Shared/UserContext';
import { PopupProvider } from './Shared/PopupContext';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingProvider>
        <UserProvider>
          <PopupProvider>
            <App />
          </PopupProvider>
        </UserProvider>
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>
);
