import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import RecoverPasswordSuccessSvg from '../../../Media/Incentive/Icons/checked-success.svg';
import './RecoverPasswordSuccessStyles.scss';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { useEffect } from 'react';
import Init from "../../../Utils/Initializer";

const RecoverPasswordSuccess = () => {
  Init();
  const navigate = useNavigate();
  return (
    <section className="recover-password-success w-[320px] h-[454px] rounded-[10px]  flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
      <img src={RecoverPasswordSuccessSvg} alt="" />
      <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
        NOVA SENHA <br />
        SOLICITADA COM
        <br />
        SUCESSO
      </h1>
      <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
        Caso seu CPF esteja <br />
        cadastrado, enviaremos <br />
        um <span>link por e-mail.</span>
        <br />
        <br />
        <span>
          Confira sua caixa
          <br /> de entrada,
        </span>{' '}
        spam ou lixo
        <br /> eletrônico e siga as instruções
      </p>

      <Button
        variant="bg-[#F7D64C] w-full text-[#031C7A]"
        text="VOLTAR AO LOGIN"
        type="button"
        onClick={() => navigate('/entrar')}
      />
    </section>
  );
};

export default RecoverPasswordSuccess;
