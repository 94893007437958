import { useEffect } from "react";
import TitleAndDescription from "./Metadata";
import PageView from "./Gtm";

const Init = (title = null, description = null) => {
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;

      TitleAndDescription(title, description);
      PageView();
    }
  }, []);
};

export const InitWithoutEffect = (title = null, description = null) => {
  TitleAndDescription(title, description);
  PageView();
};

export default Init;
