import * as Yup from 'yup';
import { validateCPF } from '../../../../Utils/validateCPF';

export const schema = Yup.object({
  login: Yup.string()
    .required('Esta informação é necessária.')
    .test('validateCpf', 'Insira um CPF válido.', (value) => {
      const isValid = validateCPF(value);

      return isValid;
    }),
  password: Yup.string().required('Esta informação é necessária.'),
});
