/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../../Components/Button';
import InputText from '../../../../../Components/InputText';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Login } from '../../../../../Backend/auth';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import useControlErrors from './Hooks/useControlErrors';
import { scrollToFirstError } from '../../../../Utils/ScrollToErrors';

export const SignIn = () => {
  const [errorOutForm, setErrorOutForm] = useState();
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const recaptchaRefSignin = useRef(null);

  const { verifyErrorResultAndSetPage, setUserLogged } = useControlErrors({
    setError,
    setErrorOutForm,
    setScrollToErrorUpdate,
    scrollToErrorUpdate,
  });

  const { setLoading } = useContext(LoadingContext);

  const makeLogin = async (data, recaptchaToken) => {
    setLoading(true);

    try {
      const response = await Login(data.login, data.password, recaptchaToken);

      if (response.success) {
        setUserLogged(response);
      } else {
        verifyErrorResultAndSetPage(response);
      }
    } catch (error) {
      verifyErrorResultAndSetPage(error);
    }
  };

  const handleCustomSubmit = (data) => {
    recaptchaRefSignin.current.executeAsync().then((recaptchaToken) => {
      recaptchaRefSignin.current.reset();
      makeLogin(data, recaptchaToken);
    });
  };

  const isLogged = localStorage.getItem('isLogged');

  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false, errorIsYellow: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <form onSubmit={handleSubmit(handleCustomSubmit)}>
      <ReCAPTCHA
        ref={recaptchaRefSignin}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      <div className="login pl-[17px] pr-[17px] pt-[35px] pb-[30px] rounded-[10px] w-[320px] flex flex-col items-center justify-center ">
        <h2 className="text-[24px]">FAÇA SEU LOGIN</h2>

        <Controller
          control={control}
          name="login"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="CPF"
                errors={errors.login}
                placeholder="Insira aqui seu CPF"
                classInputContainer={`${errors.login ? '' : 'mb-5'}`}
                onChange={onChange}
                styleLabel="!text-[white]"
                mask="999.999.999-99"
                inputType="text"
                inputMode="numeric"
                yellowError
              />
            );
          }}
        />

        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="SENHA"
                errors={errors.password}
                placeholder="Insira aqui sua senha"
                classInputContainer={`${errors.password ? '' : 'mb-[28px]'}`}
                onChange={onChange}
                inputPassword
                styleLabel="!text-[white]"
                inputType="text"
                yellowError
              />
            );
          }}
        />

        <Button
          variant="bg-[#F7D64C] w-full text-[#031C7A] mb-5"
          text="ENTRAR"
          type="submit"
        />
        <Button
          type="button"
          onClick={() => navigate('/recuperar-senha')}
          variant="h-auto outlined w-full normal-case text-[20px]"
          text="Esqueci minha senha"
        />
        {errorOutForm && (
          <p className="text-center text-[#F7D64C] leading-[1]">
            {errorOutForm}
          </p>
        )}
      </div>
    </form>
  );
};
