import { useNavigate } from 'react-router-dom';
import { stepsEnum } from '../../../Register/stepsEnum';

const statusResponseVerify = {
  cnpjNotEligible: 'CNPJ não elegível, por favor, verifique o CNPJ digitado',
  alreadyRegistered: 'CPF já cadastrado, por favor, realize o login.',
  realizeChangeManager: 'Deseja realizar a troca de responsável?.',
};

const useControlErrors = ({
  setError,
  setLoading,
  setErrorOutForm,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
}) => {
  const navigate = useNavigate();

  const setErrorInFields = (response) => {
    const errors = response.data;

    if (!errors) {
      setErrorOutForm('Ocorreu um erro. Tente novamente ou contate o suporte.');
      return;
    }

    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        setError(field, {
          type: 'manual',
          message: errors[field][0],
        });

        setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      }
    }

    setLoading(false);
  };

  const setPagesOfResult = ({ response, data }) => {
    if (
      response?.data?.cnpj &&
      response?.data?.cnpj[0] === statusResponseVerify.cnpjNotEligible
    ) {
      navigate('/cadastre-se', {
        state: {
          cpf: data.cpf,
          cnpj: data.cnpj,
          step: stepsEnum.cnpjNotEligible,
        },
      });

      return 'page_seted';
    }

    if (
      response?.data?.cpf &&
      response?.data?.cpf[0] === statusResponseVerify.alreadyRegistered
    ) {
      setError('cpf', {
        type: 'manual',
        message: 'CPF já cadastrado, por favor, realize o login.',
      });

      return 'page_seted';
    }

    if (
      response?.data?.cnpj &&
      response?.data?.cnpj[0] === statusResponseVerify.realizeChangeManager
    ) {
      navigate('/cadastre-se', {
        state: {
          cpf: data.cpf,
          cnpj: data.cnpj,
          step: stepsEnum.cnpjWithCpfAssociated,
        },
      });

      return 'page_seted';
    }
  };
  const verifyErrorResultAndSetPage = ({ response, data }) => {
    const pageSeted = setPagesOfResult({ response, data });

    if (pageSeted) {
      setLoading(false);
    } else {
      setErrorInFields(response);
    }
  };

  return {
    verifyErrorResultAndSetPage,
  };
};

export default useControlErrors;
