import * as Yup from 'yup';
import { validateCPF } from '../../../Utils/validateCPF';
import { validateBirth } from '../../../Utils/validateBirth';
export const schema = Yup.object({
  cpf: Yup.string()
    .required('Esta informação é necessária.')
    .test('validateCpf', 'Insira um CPF válido.', (value) => {
      const isValid = validateCPF(value);
      return isValid;
    }),

  name: Yup.string()
    .required('Esta informação é necessária.')
    .test('validateName', 'Digite seu nome completo.', (value) => {
      const isComplete = !!value.split(' ')[1];
      return isComplete;
    }),

  birth: Yup.string()
    .required('Esta informação é necessária.')
    .test('validateBirth', 'Digite uma data válida.', (value) => {
      const isValid = validateBirth(value);
      return isValid;
    }),

  sex: Yup.string().required('Esta informação é necessária.'),

  mobile: Yup.string().required('Esta informação é necessária.'),

  email: Yup.string()
    .required('Esta informação é necessária.')
    .email('Insira um email válido'),

  email_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('email')], 'Os e-mails não correspondem'),

  password: Yup.string()
    .nullable()
    .test(
      'input length',
      'A senha deve conter no mínimo 6 caracteres.',
      (value) => {
        return !!value ? value.length >= 6 : true;
      }
    )
    .test(
      'capital letter',
      'A senha deve conter ao menos uma letra maiúscula.',
      (value) => {
        const verifyCapital = /[A-Z]/;

        const hasCapitalLetter =
          !!value === true ? verifyCapital.test(value) : true;

        return hasCapitalLetter;
      }
    )
    .test(
      'lower case',
      'A senha deve conter ao menos uma letra minúscula.',
      (value) => {
        const verifyLower = /[a-z]/;
        const hasLowerCase = !!value ? verifyLower.test(value) : true;
        return hasLowerCase;
      }
    )
    .test('has number', 'A senha deve conter ao menos um número.', (value) => {
      const testNumber = /[0-9]/;
      const hasNumber = !!value ? testNumber.test(value) : true;
      return hasNumber;
    }),

  password_confirmation: Yup.string()
    .test(
      'has_password_value',
      'Esta informação é necessária.',
      function (value) {
        const campo1 = this.resolve(Yup.ref('password'));
        const hasValue = !!value;
        if (campo1?.length > 0 && !hasValue) {
          return false;
        }
        return true;
      }
    )
    .test(
      'password_confirmation_is_equal',
      'As senhas não correspondem',
      function (value) {
        const campo1 = this.resolve(Yup.ref('password'));
        if (campo1 !== value && campo1?.length > 0) {
          return false;
        }
        return true;
      }
    ),
});
