/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import './ContactStyles.scss';
import AOS from 'aos';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CheckedFaq,
  NoCheckedFaq,
} from '../../../Media/Incentive/Icons/CheckIconsOptins';
import { GetFaqData, PostFaqContact } from '../../../../Backend/faq';
import Button from '../../../../Components/Button';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { schema } from './schema';
import DOMPurify from 'dompurify';

import blueArrow from '../../../Media/Incentive/Pages/Contact/arrowDetailBlue.png';
import blackArrow from '../../../Media/Incentive/Pages/Contact/arrowDetailBlack.png';
import ContactSuccess from '../ContactSuccess';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from "../../../Utils/Initializer";

const Contact = () => {
  Init();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectedCategory, setSelectedCategory] = useState(1);
  const [successPage, setSuccess] = useState(false);
  const [showScreen, setShowScreen] = useState();
  const [categories, setCategories] = useState([]);
  const [categoriesDetail, setCategoriesDetail] = useState([]);
  const [openedAnswer, setOpenedAnswer] = useState([]);
  const [currentCategory, setCurrentCategory] = useState();
  const [scrollToErrorUpdate] = useState(0);

  const [arraySubject, setArraySubject] = useState([
    {
      id: 0,
      uuid: null,
      title: 'Selecione',
      guard: null,
      order: null,
      status: null,
    },
  ]);

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const { setLoading } = useContext(LoadingContext);

  const recaptchaRef = useRef(null);

  const createMarkup = (content) => {
    return { __html: DOMPurify.sanitize(content) };
  };

  const handleOpenAnswer = ({ questionId }) => {
    const isOpen = !!openedAnswer.find((idAnswer) => idAnswer === questionId);

    if (isOpen) {
      const newArr = openedAnswer.filter((idAnswer) => idAnswer !== questionId);
      setOpenedAnswer(newArr);
      return;
    } else {
      setOpenedAnswer((prev) => {
        return [...prev, questionId];
      });
    }
  };

  const loadData = async () => {
    const response = await GetFaqData();

    if (response.success) {
      setArraySubject((prev) => {
        const newSubjects = response.data?.subject || [];

        return [prev[0], ...newSubjects];
      });

      setCategories(response.data?.faq_category);

      setCategoriesDetail(response.data?.faq);
      setCurrentCategory(response.data?.faq[1]);
    }
  };

  const onSubmit = async (data, recaptchaToken) => {
    const response = await PostFaqContact({ ...data, ...recaptchaToken });

    if (response.success) {
      setLoading(false);
      setSuccess(true);
    }
  };

  const handleCustomSubmit = (data) => {
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      setLoading(true);
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      verifyIfIsLogged(setShowScreen);
    }, 200);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <>
      {showScreen === true && (
        <>
          {successPage === true ? (
            <ContactSuccess />
          ) : (
            <DefaultTemplate>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <section className="contact">
                <div className="contact-banner">
                  <div data-aos="fade-right">
                    <div class="title flex items-end">
                      <div class="title-col bg-[#F7D64C] h-[136px] flex items-center">
                        <h1 className="text-[#04287F] font-semibold leading-none !py-3">
                          FALE CONOSCO
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:mt-8 mt-[7.5rem]">
                  <h1 className="lg:text-[20px] text-center text-[18px] text-[#04287F] font-semibold lg:text-center mb-4 leading-tight lg:w-full w-[255px] mx-auto">
                    CONFIRA AS PERGUNTAS MAIS FREQUENTES.
                  </h1>

                  <h3 className="lg:text-[20px] text-center text-[18px] text-[#04287F] font-normal lg:text-center  leading-tight lg:w-full w-[255px] mx-auto">
                    Selecione o assunto que deseja saber mais a respeito.
                  </h3>
                </div>

                <div
                  style={{ margin: '0 auto', maxWidth: '1140px' }}
                  className="flex flex-col items-center justify-center"
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    padding="12px"
                  >
                    {categories.map((category) => {
                      const isSelected = category.id === selectedCategory;
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Button
                            type="button"
                            onClick={() => {
                              setCurrentCategory(categoriesDetail[category.id]);
                              setSelectedCategory(category.id);
                            }}
                            variant={`w-full bg-white text-[#04287F] hover:bg-[#04287F] hover:text-[#F7D64C] ${
                              isSelected ? 'selected' : ''
                            }`}
                            text={category.title}
                            style={{ border: '2px solid #04287F' }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div className="px-4 w-full pt-[1rem] lg:pt-[3rem]">
                    <div
                      className="rounded-[30px] w-full py-5 px-6"
                      style={{ border: '2px solid #04287F' }}
                    >
                      <p className="text-[25px] text-[#04287F] font-[500] lg:px-[2.5rem]">
                        {currentCategory?.category}
                      </p>
                      {currentCategory?.data.map((question) => {
                        const answerIsOpen = !!openedAnswer.find(
                          (answerId) => answerId === question.id
                        );

                        return (
                          <div className={`question`}>
                            <div className="text-[16px] text-[#04287F] font-[600] py-[1.25rem] lg:px-[2.5rem] flex items-center justify-between">
                              <p
                                className="hover:cursor-pointer w-[80%] lg:w-full"
                                onClick={() => {
                                  handleOpenAnswer({ questionId: question.id });
                                }}
                              >
                                {question.question}
                              </p>

                              <img
                                onClick={() => {
                                  handleOpenAnswer({ questionId: question.id });
                                }}
                                src={answerIsOpen ? blueArrow : blackArrow}
                                className={`question__icon-button h-[10px] w-[15px] hover:cursor-pointer ${
                                  answerIsOpen ? 'rotate-180' : '' // Adiciona a classe rotate-180 se answerIsOpen for verdadeiro
                                }`}
                                alt="open"
                                style={{
                                  transition: 'all 0.2s ease-in-out',
                                  transform: answerIsOpen
                                    ? 'rotate(-180deg)'
                                    : 'rotate(0deg)', // Aplica a rotação diretamente via estilo inline com base em answerIsOpen
                                }}
                              />
                            </div>

                            <div
                              className={`question__answer-container ${
                                openedAnswer.find(
                                  (answerId) => answerId === question.id
                                )
                                  ? 'question__answer-container__open'
                                  : ''
                              }`}
                            >
                              {answerIsOpen && (
                                <p
                                  className={`question__answer  text-[#585858] font-[400] pt-[1.25rem] pb-0 lg:px-[2.5rem] answer `}
                                  dangerouslySetInnerHTML={createMarkup(
                                    question.answer
                                  )}
                                ></p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div class="flex items-end mb-[30px] mt-[30px]">
                  <div class="title-col bg-[#F7D64C] h-[68px] lg:h-[120px] flex items-center lg:max-w-[875px] items-center justify-center">
                    <h1 className="text-[#04287F] !text-[24px] lg:!text-[34px] font-semibold leading-none !py-3 flex items-center justify-center">
                      ENTRE EM CONTATO, PARCEIRO.
                    </h1>
                  </div>
                </div>
                <div class="text-center px-6 lg:p-0">
                  <h2 className="text-[22px] lg:text-[25px] text-[#04287F] font-[500]">
                    Ficou com alguma dúvida, quer dar uma sugestão ou precisa de
                    mais detalhes?
                  </h2>
                  <p class="mt-3 text-[20px] text-[#04287F]">
                    Fique à vontade para mandar sua mensagem. Responderemos o
                    mais breve possível.
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit(handleCustomSubmit)}
                  className="form-container lg:w-[500px] p-[16px]"
                >
                  <>
                    <div
                      className={`form-container__inputs-container mb-[5px] ${
                        errors?.contact_subject ? 'error' : ''
                      }`}
                    >
                      <label className={`px-5 py-1 font-[600] text-[#04287F]`}>
                        ASSUNTO
                      </label>
                      <div className={`!rounded-xl h-14 `}>
                        <Controller
                          control={control}
                          name="contact_subject"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <select
                                style={{
                                  border: errors?.contact_subject
                                    ? '1px solid #cc0000'
                                    : '',
                                }}
                                className="!bg-[#8598c2] rounded-[28px]"
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                              >
                                {arraySubject.map((option) => (
                                  <option value={option.uuid}>
                                    {option.title}
                                  </option>
                                ))}
                              </select>
                            );
                          }}
                        />
                      </div>

                      {errors?.contact_subject && (
                        <div className="errors-message">
                          {/* <img src={RightCornerElement} alt="" /> */}
                          <p className="error">
                            {errors?.contact_subject?.message}
                          </p>
                        </div>
                      )}
                    </div>
                  </>

                  <>
                    <div
                      className={` ${
                        errors?.contact_message ? 'error' : ''
                      } form-container__inputs-container`}
                    >
                      <label className={`px-5 py-1 font-[600] text-[#04287F]`}>
                        MENSAGEM
                      </label>
                      <div>
                        <Controller
                          name="contact_message"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <textarea
                                onChange={onChange}
                                rows={11}
                                className="!bg-[#8598c2] !h-[450px]"
                              />
                            );
                          }}
                        />
                      </div>
                      {errors?.contact_message && (
                        <div className="errors-message">
                          {/* <img src={RightCornerElement} alt="" /> */}
                          <p className="error">
                            {errors?.contact_message?.message}
                          </p>
                        </div>
                      )}
                    </div>
                  </>

                  <div className="flex flex-col mt-[20px] items-center justify-center">
                    <label
                      className={`px-5 py-1 font-[600] text-[#04287F] ${
                        errors?.contact_contacted ? 'text-[#cc0000]' : ''
                      } text-center `}
                    >
                      Como deseja ser contatado?
                    </label>
                    <div className="flex items-center justify-center mt-[20px]">
                      <div>
                        <Checkbox
                          onChange={() => {
                            setValue('contact_contacted', 'E-mail');
                          }}
                          icon={<NoCheckedFaq />}
                          checked={watch('contact_contacted') === 'E-mail'}
                          checkedIcon={<CheckedFaq />}
                          sx={{
                            paddingRight: '8px !important',
                            height: '14px',
                          }}
                        />
                        <label
                          className={`px-5 py-1 font-[500] text-[#031e78] text-[14px] text-center `}
                        >
                          E-mail
                        </label>
                      </div>
                      <div>
                        <Checkbox
                          onChange={() => {
                            setValue('contact_contacted', 'Ligação');
                          }}
                          value={false}
                          checked={watch('contact_contacted') === 'Ligação'}
                          icon={<NoCheckedFaq />}
                          checkedIcon={<CheckedFaq />}
                          sx={{
                            paddingRight: '8px !important',
                            height: '14px',
                          }}
                        />
                        <label
                          className={`px-5 py-1 font-[500] text-[14px] text-[#031e78] text-center `}
                        >
                          Ligação
                        </label>
                      </div>
                    </div>
                    {errors?.contact_contacted && (
                      <div className="errors-message !mt-[10px]">
                        <p className="error">
                          {errors?.contact_contacted?.message}
                        </p>
                      </div>
                    )}
                    <Button
                      type="submit"
                      variant="lg:mt-8 mt-10"
                      text="ENVIAR"
                    />
                  </div>
                </form>
                <div className="pl-4 pr-4">
                  <div className="contact-bang-phone bg-[#FFD800] lg:w-[558px] w-full rounded-full mx-auto lg:py-3 py-2 lg:my-10 my-8">
                    <h1 className="text-[#04287F] text-[20px] font-semibold text-center leading-none lg:ml-0 ml-8">
                      Caso prefira, <br className="md:hidden" /> ligue para{' '}
                      <br className="md:hidden" />
                      <a href="tel:1142003120">(11) 4200-3120</a>
                    </h1>
                  </div>
                  <div className="contact-bang-mobile bg-[#FFD800] lg:w-[558px] w-full rounded-full mx-auto lg:py-3 py-2 lg:my-10 my-8">
                    <h1 className="text-[#04287F] text-[20px] font-semibold text-center leading-none lg:ml-0 ml-8">
                      Atendimento
                      <br className="md:hidden" /> via WhatsApp{' '}
                      <br className="md:hidden" />
                      <a href="https://api.whatsapp.com/send?phone=5541987981042">
                        (41) 98798-1042
                      </a>
                    </h1>
                  </div>
                </div>

                <div className="grid justify-center items-center lg:grid-cols-2 lg:w-[1088px] mx-auto mb-16"></div>
              </section>
            </DefaultTemplate>
          )}
        </>
      )}
    </>
  );
};

export default Contact;
