import './PointsLevelStyles.scss';

const PointsLevel = ({ data }) => {
  return (
    <>
      <div data-aos="zoom-in" class="points">
        <div>
          <p className="mb-3">
            <strong className="font-semibold">R$ {data?.value}</strong>
          </p>
          <p className="mb-3">PARA VOCÊ CONQUISTAR</p>
          <p className="mb-3">
            <strong className="font-semibold">
              MAIS {data?.points} PONTOS
            </strong>
          </p>
          <p>EM {String(data?.month).toUpperCase()}.</p>

          <div class="progress progress-point">
            <div
              class="progress-bar"
              role="progressbar"
              style={{
                width: `${data?.perc}%`,
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsLevel;
