import { useContext, useEffect } from 'react';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { GetRescueAwards } from '../../../../Backend/awards';

const RescueAwards = () => {
  const { setLoading } = useContext(LoadingContext);
  const getRescueAwards = async () => {
    const response = await GetRescueAwards();

    if (response.data.data.url) {
      setLoading(true);
      window.location.href = response.data.data.url;
    }
  };

  useEffect(() => {
    setLoading(true);
    getRescueAwards();
  }, []);

  return <></>;
};

export default RescueAwards;
