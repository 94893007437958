import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import RegisterNewPharmaSuccessSvg from '../../../Media/Incentive/Icons/checked-success.svg';
import './RegisterCnpjAssociatedSuccess.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import Init from "../../../Utils/Initializer";

const RegisterCnpjAssociatedSuccess = ({ setStep }) => {
  Init();
  const navigate = useNavigate();
  return (
    <LoginTemplate variation="variation-2">
      <section className="recover-password-success w-[320px] h-[520px] rounded-[10px]  flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] ">
        <img src={RegisterNewPharmaSuccessSvg} alt="" />
        <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
        SOLICITAÇÃO REALIZADA<br />
        COM SUCESSO
        </h1>
        <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
          Em até 72 horas úteis, <br />
          nossa central entrará <br />
          em contato para devolutiva <br />
          da sua solicitação.
        </p>

        <div
          className="bg-[#28CEE3] text-[18px] w-[257px]  text-center rounded-[12px] 
        text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] mb-[20px]"
        >
          Restrito a farmácias aptas <br />
          a participar do programa. <br />
          Os dados cadastrados <br />
          devem ser de acordo com <br className="" /> o estabelecimento <br />e
          seus responsáveis.
        </div>

        <div className="flex items-center flex-col justify-center w-full gap-[14px]">
          <Button
            variant="bg-[#F7D64C] w-full text-[#031C7A]"
            text="ADICIONAR NOVA FARMÁCIA"
            type="button"
            onClick={() => setStep(0)}
          />
        </div>
      </section>
    </LoginTemplate>
  );
};

export default RegisterCnpjAssociatedSuccess;
