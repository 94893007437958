export function validateBirth(data) {
  const regexData = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

  return regexData.test(data);
}

export const validateBirthOnBlur = ({ setError, inputBirth, clearErrors }) => {
  const isValid = validateBirth(inputBirth);

  const currentDate = new Date();
  const inputDate = new Date(inputBirth?.split('/').reverse().join('-')); // Convertendo a string para objeto de data

  const differenceInYears = currentDate.getFullYear() - inputDate.getFullYear();

  if (!isValid || differenceInYears > 100) {
    setError('birth', { type: 'manual', message: 'Digite uma data válida.' });
  } else {
    clearErrors('birth');
  }
};
