export const validateEmail = (email) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
};

export const checkIfEmailIsValid = ({ inputEmail, clearErrors, setError }) => {
  const isValid = validateEmail(inputEmail);

  if (isValid || !inputEmail) {
    clearErrors('email');
  } else {
    setError('email', { type: 'manual', message: 'Insira um email válido.' });
  }
};
