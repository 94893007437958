/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import DefaultTemplate from "../../Templates/DefaultTemplate";
import "./AwardStyles.scss";

import coins from "../../../Media/Incentive/Icons/coins.svg";
import stores from "../../../Media/Incentive/Pages/Award/brands.webp";
import products from "../../../Media/Incentive/Pages/Award/produtos.webp";
import Button from "../../../../Components/Button";
import useVerifyIfIsLogged from "../../../Utils/useVerifyIfIsLogged";
import { Link } from "react-router-dom";
import Init from "../../../Utils/Initializer";
import { AwardsPage } from '../../../../Backend/Page';

const Award = () => {
  Init();
  const [showScreen, setShowScreen] = useState();
  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    const logPage = async () => {
      const response = await AwardsPage();
      return response.success;
    }
    logPage();
  }, []);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="award lg:mt-0 -mt-2">
            <div className="award-banner">
              <div data-aos="fade-right">
                <div className="title flex items-end">
                  <div className="title-col bg-[#F7D64C]">
                    <h1 className="text-[#031C7A] font-semibold">PREMIAÇÕES</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:mt-8 mt-[9rem]">
              <h1 className="lg:text-[30px] text-[18px] text-[#04287F] font-semibold lg:text-center text-start leading-tight lg:w-full w-[212px] mx-auto lg:mb-16">
                APROVEITE AS MILHARES DE OPÇÕES DE RESGATE.
              </h1>
              <div className="flex justify-center items-center fixed bottom-1 right-0 left-0 m-auto z-50">
                <Link
                  to="/resgatar-premios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    type="button"
                    variant="lg:mt-8 mt-10 lg:w-[720px]"
                    text="RESGATAR PRÊMIOS"
                  />
                </Link>
              </div>
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center lg:w-[329px] w-[275px] h-[69px] lg:mx-0 mx-auto bg-[#04287F] rounded-full lg:mt-[35px] mt-10">
                  <img src={coins} className="relative right-3" alt="" />
                  <h1 className="lg:text-[24px] text-[18px] text-white font-semibold mr-8">
                    1 PONTO = R$ 1,00
                  </h1>
                </div>
              </div>
            </div>

            <div className="award-premius bg-[#F7D64C] mt-[33px]">
              <div className="award-premius-container lg:gap-[50px] pb-2">
                <div>
                  <div className="lg:mt-[40px] mt-[24px] lg:pt-0">
                    <h2 className="text-[#031C7A] text-[22px] font-semibold lg:w-[323px] w-[320px] leading-tight  px-6 lg:px-0">
                      São diversos produtos, serviços e comodidades
                    </h2>
                    <ul className=" mt-4 px-6 lg:px-0 ">
                      <li>
                        <span>•</span> Pagamento de boletos;
                      </li>
                      <li>
                        <span>•</span> Eletrodomésticos e eletrônicos;
                      </li>
                      <li>
                        <span>•</span> Vale-presentes e vale-compras;
                      </li>
                      <li>
                        <span>•</span> Recarga de crédito para celular;
                      </li>
                      <li>
                        <span>•</span> Automotivo;
                      </li>
                      <li>
                        <span>•</span> Vale-combustível;
                      </li>
                      <li>
                        <span>•</span> E muito mais!
                      </li>
                    </ul>
                    <picture className="hidden lg:block">
                      <img
                        src={products}
                        alt="Produtos"
                        className="lg:w-[590px] w-[367px]"
                      />
                    </picture>
                  </div>
                </div>
                <div data-aos="zoom-in" data-aos-duration="2000">
                  <div>
                    <picture>
                      <img
                        src={stores}
                        alt="Marcas"
                        className="lg:w-[584px] w-[320px] lg:pt-7 mt-6 lg:mt-0 mb-6 lg:mb-0"
                      />
                    </picture>
                  </div>
                </div>
                
              </div>
              <div>
                <picture className=" lg:hidden">
                      <img
                        src={products}
                        alt="Produtos"
                        className="w-[280px] lg:w-[250px] m-auto block mt-[-85px] bottom-[-70px] relative"
                      />
                    </picture>
              </div>
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default Award;
