import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetRescueAwards = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/shop/url');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
