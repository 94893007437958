import { useContext } from 'react';
import { LoadingContext } from '../../../Shared/LoadingContext';
import Client from '../../../Backend/Client';
import { useNavigate } from 'react-router-dom';

const useHandlersHeader = ({
  setIsOpen,
  isOpen,
  touchStartX,
  touchEndX,
  menuRef,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    touchEndX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current && touchEndX.current) {
      const touchDiff = touchEndX.current - touchStartX.current;
      if (touchDiff > 50) {
        setIsOpen(false);
      } else if (touchDiff < -50) {
        setIsOpen(true);
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const scrollIntoHome = (elementId) => {
    const componentToScroll = document.getElementById(elementId);
    window.scrollBy({
      top: componentToScroll?.getBoundingClientRect()?.top - 110,
      behavior: 'smooth',
      scrollMode: 'always',
    });
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await Client.get('/incentive/logout');
      navigate('/entrar');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('name');
      setLoading(false);
    } catch (error) {
      navigate('/entrar');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('name');
      setLoading(false);
    }
  };

  return {
    toggleMenu,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleClickOutside,
    scrollIntoHome,
    handleLogout,
  };
};

export default useHandlersHeader;
