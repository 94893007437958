/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import RegisterCnpjNotEligible from './Components/RegisterCnpjNotEligible';
import RegisterCnpjAssociated from './Components/RegisterCnpjAssociated';
import RegisterCnpjAssociatedSuccess from './Components/RegisterCnpjAssociatedSuccess';
import { useLocation, useNavigate } from 'react-router-dom';
import CorrectCpfAndCnpj from './Components/CorrectCpfAndCnpj';
import { stepsEnum } from './stepsEnum';
import RegisterConcluded from './Components/RegisterConclued';
import Init from "../../../Utils/Initializer";

const Register = () => {
  Init();
  const [variation, setVariation] = useState('variation-1');
  const [step, setStep] = useState();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setStep(location.state.step);
  }, []);

  const isLogged = localStorage.getItem('isLogged');
  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
  }, []);

  return (
    <LoginTemplate variation={variation}>
      {step === stepsEnum.cnpjNotEligible && (
        <RegisterCnpjNotEligible setStep={setStep} />
      )}

      {step === stepsEnum.cnpjWithCpfAssociated && (
        <RegisterCnpjAssociated
          setStep={setStep}
          cnpj={location.state.cnpj}
          cpf={location.state.cpf}
        />
      )}

      {step === stepsEnum.cnpjWithCpfAssociatedSuccess && (
        <RegisterCnpjAssociatedSuccess
          setStep={setStep}
          setVariation={setVariation}
        />
      )}

      {step === stepsEnum.correctCpfAndCnpj && (
        <CorrectCpfAndCnpj
          cnpj={location.state.cnpj}
          cpf={location.state.cpf}
          setStep={setStep}
        />
      )}

      {step === stepsEnum.registerConcluded && (
        <RegisterConcluded setVariation={setVariation} />
      )}
    </LoginTemplate>
  );
};

export default Register;
