export const checkIfPhoneIsCompleted = ({
  inputMobile,
  setError,
  clearErrors,
}) => {
  if (inputMobile?.length > 1 && inputMobile?.length < 15) {
    setError('mobile', {
      type: 'manual',
      message: 'Número de telefone incompleto.',
    });
  }

  if (inputMobile?.length === 1) {
    clearErrors('mobile');
  }
};
