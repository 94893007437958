/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import DefaultTemplate from '../../Templates/DefaultTemplate';
import Button from '../../../../Components/Button';

import './BlogStyles.scss';

import icon from '../../../Media/Incentive/Icons/news.svg';
import { GetBlog } from '../../../../Backend/blog';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import Init from "../../../Utils/Initializer";

const Blog = () => {
  Init();
  const [blogsLinks, setBlogsLinks] = useState();
  const [showScreen, setShowScreen] = useState();

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    AOS.init();
  }, []);

  const navigate = useNavigate();

  const loadBlogs = async () => {
    const response = await GetBlog();
    setBlogsLinks(response.data.posts);
    setLoading(false);
  };

  const handleNavigate = async (blogDetail) => {
    setLoading(true);
    navigate(`/blog/post/${blogDetail?.uuid}`);
  };

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);
  useEffect(() => {
    setLoading(true);
    loadBlogs();
  }, []);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="blog">
            <div className="blog-banner">
              <div data-aos="fade-right">
                <div class="title flex items-end">
                  <div class="title-col bg-[#031C7A]">
                    <h1 className="text-[#F7D64C] font-semibold leading-none !py-3">
                      BLOG NEO PHARMA
                      <br /> EXCELÊNCIA
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mt-8 mt-[7.5rem]">
              <h1 className="lg:text-[30px] text-[18px] text-[#04287F] font-semibold lg:text-center text-start leading-tight lg:w-full w-[255px] mx-auto">
                NOVIDADES, DICAS E CONTEÚDOS EXCLUSIVOS
                <br />
                PARA VOCÊ FICAR POR DENTRO DE TUDO!
              </h1>
            </div>

            <div className="bg-[#F7D64C] rounded-full lg:w-[536px] w-[286px] py-3 mx-auto lg:mt-10 mt-7 lg:mb-5">
              <h4 className="text-[18px] text-[#04287F] font-semibold text-center">
                ÚLTIMAS NOVIDADES DO BLOG.
              </h4>
            </div>

            <div className="grid justify-center items-center lg:grid-cols-2 lg:w-[1088px] mx-auto mb-16">
              {blogsLinks?.map((blogLink) => {
                return (
                  <>
                    <div
                      className="flex flex-col justify-center items-center lg:mb-3"
                      key={blogLink.id}
                    >
                      <div className="relative top-10 flex justify-center items-center h-[41px] bg-[#FFD800] rounded-full pl-[55px] pr-[20px] justify-end flex">
                        <img src={icon} className="absolute left-0" />
                        <h1 className="text-[#001C7A] font-semibold lg:text-[16px] text-[14px] lg:ml-2 ml-10">
                          {blogLink?.category}
                        </h1>
                      </div>
                      <img
                        src={blogLink?.image}
                        className="lg:w-[444px] h-[180px] lg:!h-[264px] rounded-3xl hidden lg:block hover:cursor-pointer mt-[23px]"
                        alt="Imagem Blog"
                        onClick={() => handleNavigate(blogLink)}
                      />
                      <img
                        src={blogLink?.image}
                        className="lg:w-[444px] h-[180px] lg:!h-[264px] mt-[23px] lg:hidden h-[264px] rounded-3xl block hover:cursor-pointer"
                        alt="Imagem Blog"
                        onClick={() => handleNavigate(blogLink)}
                      />

                      <p className="text-[#04287F] font-semibold lg:w-[282px] w-[238px] text-center leading-tight lg:text-[16px] text-[14px] mt-2 mb-4">
                        {blogLink?.highlight}
                      </p>
                      <Button
                        type="button"
                        onClick={() => handleNavigate(blogLink)}
                        variant="button-light bg-[#28CEE3] text-[#04287F]"
                        text={blogLink?.button_text?.toUpperCase()}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default Blog;
