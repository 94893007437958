/* eslint-disable react-hooks/exhaustive-deps */
import './CreatePasswordStyles.scss';

import { LoginTemplate } from '../../Templates/LoginTemplate';
import RecoverPasswordSVG from '../../../Media/Incentive/Pages/RecoverPassword/recover-password.svg';
import InputText from '../../../../Components/InputText';
import Button from '../../../../Components/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useContext, useEffect, useRef, useState } from 'react';
import CreatePasswordSuccess from '../CreatePasswordSuccess';
import { useLocation, useNavigate } from 'react-router-dom';
import { ForgotPasswordSave } from '../../../../Backend/forgotPassword';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { FirstLoginSave } from '../../../../Backend/firstLogin';
import ReCAPTCHA from 'react-google-recaptcha';
import { ValidateToken } from '../../../../Backend/validateToken';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from '../../../Utils/Initializer';
import PasswordChecklist from 'react-password-checklist';

const CreatePassword = () => {
  Init();
  const [success, setSuccess] = useState(false);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const [tokenIsValid, setTokenIsValid] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const originCreatePassword = searchParams.get('origin');

  const { setLoading } = useContext(LoadingContext);

  const {
    handleSubmit,
    setError,
    control,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const recaptchaRef = useRef(null);

  const sendRequestForgotPassword = async (data, recaptchaToken) => {
    const response = await ForgotPasswordSave(
      data.password,
      data.password_confirmation,
      token,
      recaptchaToken
    );

    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      setError('password_confirmation', {
        type: 'manual',
        message: response.data.cpf[0],
      });
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      setLoading(false);
    }
  };

  const setErrorsInputs = (errors) => {
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        setError(field, {
          type: 'manual',
          message: errors[field][0],
        });
      }
    }

    setLoading(false);
  };
  const sendRequestFirstLogin = async (data, recaptchaToken) => {
    const response = await FirstLoginSave(
      data.password,
      data.password_confirmation,
      token,
      recaptchaToken
    );

    if (response.success) {
      navigate('/');
      setLoading(false);
      localStorage.setItem('name', response.data.user.name.split(' ')[0]);
      localStorage.setItem('isLogged', 'logged');
    } else {
      setErrorsInputs(response.data);
      setLoading(false);
    }
  };

  const validateToken = async () => {
    setLoading(true);

    const response = await ValidateToken(token);
    if (response.success) {
      setTokenIsValid(true);
    } else {
      setTokenIsValid(false);
    }

    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      if (originCreatePassword === 'primeiro-login') {
        sendRequestFirstLogin(data, recaptchaToken);
      } else if (originCreatePassword === 'esqueceu-senha') {
        sendRequestForgotPassword(data, recaptchaToken);
      }
    });
  };

  const isLogged = localStorage.getItem('isLogged');
  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false, errorIsYellow: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <LoginTemplate variation="variation-2">
      {success ? (
        <CreatePasswordSuccess />
      ) : (
        <>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />

          <section className="create-password w-[320px] h-auto rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
            <img src={RecoverPasswordSVG} alt="" />
            <h1 className="text-[24px] text-center mt-[15px] mb-[5px]">
              CRIE SUA NOVA SENHA
            </h1>
            {tokenIsValid === false && (
              <p className="text-white">CHAVE DE RECUPERAÇÃO INVÁLIDA</p>
            )}

            {tokenIsValid === true && (
              <>
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full"
                >
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputText
                          label="Nova Senha"
                          errors={errors.password}
                          inputPassword
                          placeholder="Insira aqui sua nova senha"
                          classInputContainer={`${
                            errors.password ? '' : 'mb-5'
                          }`}
                          onChange={onChange}
                          inputType="text"
                          styleLabel="!text-[white]"
                          yellowError
                        />
                      );
                    }}
                  />

                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputText
                          label="Confirmar Nova Senha"
                          errors={errors.password_confirmation}
                          placeholder="Confirme aqui sua nova senha"
                          classInputContainer={`${
                            errors.password_confirmation ? '' : 'mb-5'
                          }`}
                          onChange={onChange}
                          inputPassword
                          inputType="text"
                          styleLabel="!text-[white]"
                          yellowError
                        />
                      );
                    }}
                  />

                  {watch('password') ? (
                    <PasswordChecklist
                      className="style-checklist-white"
                      rules={[
                        'minLength',
                        'number',
                        'capital',
                        'lowercase',
                        'match',
                      ]}
                      value={watch('password') ? watch('password') : ''}
                      messages={{
                        minLength:
                          'A senha deve conter no mínimo 6 caracteres.',
                        number: 'A senha deve conter ao menos um número.',
                        capital:
                          'A senha deve conter ao menos uma letra maiúscula.',
                        lowercase:
                          'A senha deve conter ao menos uma letra minúscula.',
                        match: 'As senhas coincidem.',
                      }}
                      minLength={6}
                      valueAgain={
                        watch('password_confirmation')
                          ? watch('password_confirmation')
                          : ''
                      }
                    />
                  ) : undefined}

                  <Button
                    variant="bg-[#F7D64C] w-full text-[#031C7A] mb-[14px]"
                    text="PROSSEGUIR"
                    type="submit"
                  />
                </form>
              </>
            )}
          </section>
        </>
      )}
    </LoginTemplate>
  );
};

export default CreatePassword;
