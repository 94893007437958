/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import RegisterCnpjAssociatedSuccessSvg from '../../../../../Media/Incentive/Icons/checked-success.svg';

import './RegisterCnpjAssociatedSuccessStyles.scss';

const RegisterCnpjAssociatedSuccess = ({ setStep, setVariation }) => {
  useEffect(() => {
    setVariation('variation-2');
  }, []);

  return (
    <section
      className="register-cnpj-associated-success w-[320px] 
    rounded-[10px] flex flex-col items-center justify-center pt-[29px] 
    pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[20px] lg:mt-[0] lg:mb-[0]"
    >
      <img src={RegisterCnpjAssociatedSuccessSvg} alt="" />
      <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
        SOLICITAÇÃO REALIZADA
        <br />
        COM SUCESSO
      </h1>
      <p className="text-[18px] text-center leading-[1.2]  mt-[15px]">
        Em até 72 horas úteis, <br />
        nossa central entrará <br />
        em contato para devolutiva <br />
        da sua solicitação.
      </p>

      <div
        className="bg-[#28CEE3] text-[18px] w-[257px]  text-center rounded-[12px] 
      text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] "
      >
        Restrito a farmácias aptas <br />
        a participar do programa. <br />
        Os dados cadastrados <br />
        devem ser de acordo com <br className="" /> o estabelecimento <br />e
        seus responsáveis.
      </div>
    </section>
  );
};

export default RegisterCnpjAssociatedSuccess;
