import { createContext, useState } from 'react';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingState, setLoadingState] = useState(true);

  const setLoading = (value) => {
    setLoadingState(value);
  };

  return (
    <LoadingContext.Provider value={{ setLoading, loadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};
