import Client from './Client';
import { formatResponse } from './formatResponse';

export async function GetBanners() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/banners');
      resolve(formatResponse(response));
    } catch (error) {
      return { success: false };
    }
  });
}
