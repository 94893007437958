import './InputStyles.scss';
// import RightCornerElement from '../../Resources/Media/Incentive/Icons/arrow.svg';
import { FaSpinner } from 'react-icons/fa';

const InputSelect = (props) => {
  return (
    <>
      <div
        className={`${props.errors ? 'error' : ''} 
         inputs-container ${props.classInputContainer}`}
      >
        <label
          className={`px-5 py-1 font-bold ${props.disabled ? 'text-[]' : ''}`}
        >
          {props.label}
        </label>
        <div
          className={`${
            props.errors ? 'error' : ''
          } input-field rounded-xl md:h-10 xl:h-14`}
        >
          <select {...props}>
            {props.optionsSelect?.map((option) => (
              <option value={props.requestInOnChange ? option : option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {props.loadingInput && <FaSpinner className="animate-spin mr-2" />}
        </div>
        {props.errors && (
          <div className="errors-message">
            <p className="error">{props.errors.message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputSelect;
