import Client from './Client';
import { formatResponse } from './formatResponse';

export async function HomePage() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/home');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function HowScorePage() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/how_score');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function AwardsPage() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/awards');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function GetContactPage() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/contact');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function PostContactPage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/contact', payload);
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function Release() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/release');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function BannerHit(uuid) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/banners/hit/' + uuid);
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}