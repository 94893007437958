import { useContext } from 'react';
import { RegisterUser } from '../../../../../../Backend/register';
import { stepsEnum } from '../../stepsEnum';
import { LoadingContext } from '../../../../../../Shared/LoadingContext';
import moment from 'moment';
import {
  fieldsInFirstStep,
  fieldsInSecondStep,
  fieldsInThirdStep,
} from './Utils/variables';

const useHandlers = ({
  setError,
  recaptchaRef,
  setStep,
  setStepRegister,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
}) => {
  const { setLoading } = useContext(LoadingContext);

  const verifyScreenOfFieldWithError = (fieldWithError) => {
    const fieldErrorIsInFirstStep = fieldsInFirstStep.find(
      (fieldInFirstStep) => fieldInFirstStep === fieldWithError
    );

    const fieldErrorIsInSecondStep = fieldsInSecondStep.find(
      (fieldInSecondStep) => fieldInSecondStep === fieldWithError
    );

    const fieldErrorIsInThirdStep = fieldsInThirdStep.find(
      (fieldInThirdtStep) => fieldInThirdtStep === fieldWithError
    );
    if (fieldErrorIsInFirstStep) {
      return 1;
    }

    if (fieldErrorIsInSecondStep) {
      return 2;
    }

    if (fieldErrorIsInThirdStep) {
      return 3;
    }
  };

  const redirectScreenWithError = (response) => {
    const arrOfErrorsFields = Object.keys(response.data);

    let page;

    arrOfErrorsFields.forEach((errorField) => {
      const pageNumber = verifyScreenOfFieldWithError(errorField);

      page = pageNumber ? pageNumber : 3;

      if (!pageNumber) {
        setError('optin_commercial', {
          type: 'manual',
          message: 'Ocorreu um erro. Tente novamente.',
        });
      }
    });

    setStepRegister(page);

    setTimeout(() => {
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
    }, 1000);
  };
  const onSubmit = async (data, recaptchaToken) => {
    const response = await RegisterUser({
      cpf: data.cpf,
      cnpj: data.cnpj,
      name: data.name,
      email: data.email,
      email_confirmation: data.email_confirmation,
      birth: moment(data.birth, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      sex: data.sex,
      mobile: data.mobile,
      zip: data.zip,
      city: data.city,
      company: data.company,
      corporate_name: data.corporate_name,
      password: data.password,
      password_confirmation: data.password_confirmation,
      optin_rules: data.optin_rules,
      optin_privacy: data.optin_privacy,
      address: data.address,
      number: data.number,
      comp: data.comp,
      district: data.district,
      manager_name: data.manager_name,
      manager_email: data.manager_email,
      optin_commercial: data.optin_commercial,
      recaptchaToken: recaptchaToken,
    });

    if (response.success === true) {
      localStorage.setItem('isLogged', 'logged');
      localStorage.setItem('sex', response?.data?.user?.sex);
      localStorage.setItem('name', response?.data?.user?.name);
      setLoading(false);
      setStep(stepsEnum.registerConcluded);
    } else {
      setLoading(false);

      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }

      redirectScreenWithError(response);
    }
  };

  const handleCustomSubmit = (data) => {
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      setLoading(true);
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  return { handleCustomSubmit };
};

export default useHandlers;
