const TitleAndDescription = function(title = null, description = null) {
  if(title === null) {
    title = 'Campanha | Neo Pharma Excelência';
  }

  if(description === null) {
    description = 'Todo dia é dia de concorrer a prêmios incríveis.';
  }

  document.querySelector('meta[name="title"]').setAttribute('content', title);
  document.querySelector('meta[property="og:title"]').setAttribute('content', title);
  document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);

  document.querySelector('meta[name="description"]').setAttribute('content', description);
  document.querySelector('meta[property="og:description"]').setAttribute('content', description);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
}

export default TitleAndDescription;
