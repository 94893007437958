/* eslint-disable react-hooks/exhaustive-deps */
import './RegisterPessoalDataStyles.scss';
import step1PersonalDataMobile from '../../../../../../../Media/Incentive/Pages/Register/step1-personal-data-mobile.svg';
import { Controller } from 'react-hook-form';
import InputText from '../../../../../../../../Components/InputText';
import Button from '../../../../../../../../Components/Button';
import { useRef } from 'react';

import useValidationRegisterPessoalData from './useValidationsRegisterPessoalData';
import InputSelect from '../../../../../../../../Components/InputSelect';
import { optionsSexList } from '../../../../../../../../Shared/optionsSexList';
import { checkIfPhoneIsCompleted } from '../../../../../../../../Shared/validations';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateBirthOnBlur } from '../../../../../../../Utils/validateBirth';
import PasswordChecklist from 'react-password-checklist';

const RegisterPessoalData = ({
  control,
  errors,
  setStepRegister,
  watch,
  setError,
  clearErrors,
  scrollToErrorUpdate,
  setScrollToErrorUpdate,
}) => {
  const inputMobile = watch('mobile');
  const inputEmail = watch('email');
  const inputEmailConfirmation = watch('email_confirmation');
  const inputPassword = watch('password');
  const inputPasswordConfirmation = watch('password_confirmation');

  const recaptchaRef = useRef(null);

  const {
    checkIfEmailIsValid,
    checkIfEmailIsEqual,
    handleChangeStep,
    checkIfPasswordIsEqual,
    checkIfNameHasSurname,
  } = useValidationRegisterPessoalData({
    inputMobile,
    inputEmail,
    inputEmailConfirmation,
    setError,
    clearErrors,
    watch,
    setStepRegister,
    inputPassword,
    inputPasswordConfirmation,
    errors,
    scrollToErrorUpdate,
    setScrollToErrorUpdate,
  });

  return (
    <section
      className="register-pessoal-data w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[18px] lg:!pl-[150px] lg:!pr-[150px]"
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      <img src={step1PersonalDataMobile} alt="" />

      <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[70px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[45px] lg:pr-[45px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
        <h1 className="font-semibold">
          INFORME SEUS
          <br className="lg:hidden" /> DADOS PESSOAIS
        </h1>
      </div>

      <div className="w-full flex flex-col gap-[20px] mt-[20px]">
        <Controller
          control={control}
          name="cpf"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                disabled
                label="CPF"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('cpf');
                }}
                inputMode="numeric"
                value={value}
                mask="999.999.999-99"
                placeholder="000.000.000-00"
                errors={errors?.cpf}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Nome completo"
                styleLabel="!text-[#031E78]"
                placeholder="Nome completo"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('name');
                }}
                onBlur={() => checkIfNameHasSurname()}
                value={value}
                errors={errors?.name}
              />
            );
          }}
        />
        <div className="flex flex-col lg:flex-row gap-[18px] ">
          <Controller
            control={control}
            name="birth"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Data de nascimento"
                  styleLabel="!text-[#031E78]"
                  placeholder="Data de nascimento"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('birth');
                  }}
                  inputMode="numeric"
                  onBlur={() =>
                    validateBirthOnBlur({
                      setError,
                      inputBirth: watch('birth'),
                      clearErrors,
                    })
                  }
                  mask="99/99/9999"
                  value={value}
                  errors={errors?.birth}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="sex"
            render={({ field: { onChange, value } }) => {
              return (
                <InputSelect
                  label="Gênero"
                  placeholder="Gênero"
                  value={value}
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('sex');
                  }}
                  optionsSelect={optionsSexList}
                  errors={errors?.sex}
                />
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="mobile"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Celular"
                value={value}
                placeholder="Celular"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('mobile');
                }}
                inputMode="numeric"
                mask="(99) 99999-9999"
                errors={errors?.mobile}
                onBlur={() => {
                  checkIfPhoneIsCompleted({
                    inputMobile: value,
                    setError,
                    clearErrors,
                  });
                }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="email"
          placeholder="E-mail"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="E-mail"
                value={value}
                placeholder="E-mail"
                styleLabel="!text-[#031E78]"
                onBlur={() => {
                  checkIfEmailIsValid();
                  checkIfEmailIsEqual();
                }}
                onChange={(e) => {
                  onChange(e.target.value.trim());
                  clearErrors('email');
                }}
                errors={errors?.email}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="email_confirmation"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Confirmar e-mail"
                value={value}
                placeholder="Confirmar e-mail"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value.trim());
                  clearErrors('email_confirmation');
                }}
                onBlur={() => checkIfEmailIsEqual()}
                errors={errors?.email_confirmation}
              />
            );
          }}
        />
        <div className="flex flex-col lg:flex-row gap-[18px]">
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Criar senha"
                  value={value}
                  placeholder="Criar senha"
                  styleLabel="!text-[#031E78]"
                  inputPassword
                  onBlur={() => checkIfPasswordIsEqual()}
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('password');
                  }}
                  errors={errors?.password}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="password_confirmation"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Confirmar senha"
                  value={value}
                  placeholder="Confirmar senha"
                  styleLabel="!text-[#031E78]"
                  inputPassword
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('password_confirmation');
                  }}
                  errors={errors?.password_confirmation}
                  onBlur={() => checkIfPasswordIsEqual()}
                />
              );
            }}
          />
        </div>
        {watch('password') ? (
          <PasswordChecklist
            className="style-checklist"
            rules={['minLength', 'number', 'capital', 'lowercase', 'match']}
            value={watch('password') ? watch('password') : ''}
            messages={{
              minLength: 'A senha deve conter no mínimo 6 caracteres.',
              number: 'A senha deve conter ao menos um número.',
              capital: 'A senha deve conter ao menos uma letra maiúscula.',
              lowercase: 'A senha deve conter ao menos uma letra minúscula.',
              match: 'As senhas coincidem.',
            }}
            minLength={6}
            valueAgain={
              watch('password_confirmation')
                ? watch('password_confirmation')
                : ''
            }
          />
        ) : undefined}

        <div className="flex items-center justify-center w-full">
          <Button
            text="PROSSEGUIR"
            variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full lg:w-[287px]"
            type="button"
            onClick={() => handleChangeStep(recaptchaRef)}
          />
        </div>
      </div>
      <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
        Restrito a farmácias aptas <br className="lg:hidden" />
        a participar do programa. <br />
        Os dados cadastrados <br className="lg:hidden" />
        devem ser de acordo <br className="lg:hidden" />
        com <br className="hidden lg:block" /> o estabelecimento{' '}
        <br className="lg:hidden" />e seus responsáveis.
      </div>
    </section>
  );
};

export default RegisterPessoalData;
