import { createContext, useState } from 'react';

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popupData, setPopupData] = useState({
    status: false,
    value: '',
    date: '',
  });

  return (
    <PopupContext.Provider value={{ popupData, setPopupData }}>
      {children}
    </PopupContext.Provider>
  );
};
