import { useContext } from 'react';
import { ValidateFirstStep } from '../../../../../../../../Backend/validateStepsRegister';
import { validateEmail } from '../../../../../../../Utils/validateEmail';
import { LoadingContext } from '../../../../../../../../Shared/LoadingContext';

const useValidationRegisterPessoalData = ({
  inputMobile,
  inputEmail,
  inputEmailConfirmation,
  inputPassword,
  inputPasswordConfirmation,
  setError,
  clearErrors,
  watch,
  setStepRegister,
  errors,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const checkIfEmailIsValid = () => {
    const isValid = validateEmail(inputEmail);

    if (isValid || !inputEmail) {
      clearErrors('email');
    } else {
      setError('email', { type: 'manual', message: 'Insira um email válido.' });
    }
  };

  const checkIfEmailIsEqual = () => {
    if (
      inputEmailConfirmation === inputEmail ||
      inputEmailConfirmation?.length === 0
    ) {
      clearErrors('email_confirmation');
    } else {
      setError('email_confirmation', {
        type: 'manual',
        message: 'Os e-mails não correspondem',
      });
    }
  };

  const checkIfPasswordIsEqual = () => {
    if (
      !inputPasswordConfirmation ||
      inputPasswordConfirmation === inputPassword
    ) {
      clearErrors('password_confirmation');
    } else if (
      inputPasswordConfirmation &&
      inputPasswordConfirmation !== inputPassword
    ) {
      setError('password_confirmation', {
        type: 'manual',
        message: 'As senhas não correspondem',
      });
    }
  };

  const testPassword = () => {
    const hasUpperCase = /[A-Z]/.test(inputPassword);
    const hasLowerCase = /[a-z]/.test(inputPassword);
    const hasNumber = /[0-9]/.test(inputPassword);
    const hasSixCharacters = inputPassword?.length >= 6;
    if (hasUpperCase && hasLowerCase && hasNumber && hasSixCharacters) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfNameHasSurname = () => {
    const inputName = watch('name');

    if (!!inputName?.split(' ')[1]) {
      clearErrors('name');
    } else {
      setError('name', { type: 'manual', message: 'Digite o nome completo.' });
    }
  };

  const validateFieldsFirstStepWithBackend = async (recaptchaToken) => {
    const validationFields = await ValidateFirstStep({
      mobile: inputMobile,
      email: inputEmail,
      email_confirmation: inputEmailConfirmation,
      recaptchaToken,
    });

    if (validationFields.success) {
      setStepRegister(2);
    } else {
      for (const key in validationFields.data) {
        if (validationFields.data.hasOwnProperty(key)) {
          const error = validationFields.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
    }
    setLoading(false);
  };

  const handleChangeStep = async (recaptchaRef) => {
    setLoading(true);

    const fieldsToCheck = [
      'name',
      'birth',
      'sex',
      'mobile',
      'email',
      'email_confirmation',
      'password',
      'password_confirmation',
    ];

    let formIsValid = true;

    for (const field of fieldsToCheck) {
      const isFieldEmpty = !watch(field);

      if (isFieldEmpty) {
        setError(field, {
          type: 'manual',
          message: 'Este campo é obrigatório',
        });
        formIsValid = false;
        setLoading(false);
      }
    }

    if (Object.keys(errors).length > 0) {
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      formIsValid = false;
      setLoading(false);
    }

    const resultPassword = testPassword();

    if (formIsValid) {
      if (resultPassword === true) {
        recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
          recaptchaRef.current.reset();
          validateFieldsFirstStepWithBackend(recaptchaToken);
        });
      } else {
        setLoading(false);
        setError('password', '');
      }
    }
  };

  return {
    checkIfEmailIsValid,
    checkIfEmailIsEqual,
    checkIfPasswordIsEqual,
    handleChangeStep,
    checkIfNameHasSurname,
  };
};

export default useValidationRegisterPessoalData;
