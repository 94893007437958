import { statesBrazilianList } from '../Shared/statesBrazilianList';

export const getUfAndCity = async ({ zipValue, setValue }) => {
  return await fetch(`https://viacep.com.br/ws/${zipValue}/json/`).then(
    (response) =>
      response.json().then(async (res) => {
        const selectedState = statesBrazilianList.find(
          (state) => state.value === res.uf
        );
        if (selectedState && res.localidade) {
          setValue('state', selectedState?.value);
          setValue('district', res?.bairro);
          setValue('city', res?.localidade);
          setValue('address', res?.logradouro);
        }
      })
  );
};

export const getUfAndCityInArrayPharma = async ({
  zipValue,
  setValue,
  index,
  clearErrors,
}) => {
  return await fetch(`https://viacep.com.br/ws/${zipValue}/json/`).then(
    (response) =>
      response.json().then(async (res) => {
        const selectedState = statesBrazilianList.find(
          (state) => state.value === res.uf
        );
        if (selectedState && res.localidade) {
          setValue(`state.${index}`, selectedState?.value);
          setValue(`district.${index}`, res?.bairro);
          setValue(`city.${index}`, res?.localidade);
          setValue(`address.${index}`, res?.logradouro);

          clearErrors(`state.${index}`);
          clearErrors(`district.${index}`);
          clearErrors(`city.${index}`);
          clearErrors(`address.${index}`);
        }
      })
  );
};
