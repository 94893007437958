import * as Yup from 'yup';
export const schema = Yup.object({
  cnpj: Yup.string().required('Esta informação é necessária.'),
  corporate_name: Yup.string().required('Esta informação é necessária.'),
  company: Yup.string().required('Esta informação é necessária.'),
  zip: Yup.string()
    .required('Esta informação é necessária.')
    .test('verify if zip is complete', 'Digite um CEP válido', (value) => {
      const isCompleted = value.replace(/-/, '').length === 8;

      return isCompleted;
    }),
  address: Yup.string().required('Esta informação é necessária.'),
  number: Yup.string().required('Esta informação é necessária.'),
  comp: Yup.string().nullable(),
  district: Yup.string().required('Esta informação é necessária.'),
  city: Yup.string().required('Esta informação é necessária.'),
  manager_name: Yup.string().required('Esta informação é necessária.'),
  manager_email: Yup.string()
    .required('Esta informação é necessária.')
    .email('Digite um email válido.'),
});
