import { useContext } from 'react';
import { ValidateSecondStep } from '../../../../../../../../Backend/validateStepsRegister';
import { validateEmail } from '../../../../../../../Utils/validateEmail';
import {
  fieldsInFirstStep,
  fieldsInSecondStep,
  fieldsInThirdStep,
} from '../../Utils/variables';
import { LoadingContext } from '../../../../../../../../Shared/LoadingContext';

const useValidationRegisterPessoalData = ({
  inputEmailManager,
  setError,
  clearErrors,
  watch,
  setStepRegister,
  errors,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const checkIfEmailIsValid = () => {
    const isValid = validateEmail(inputEmailManager);

    if (isValid || !inputEmailManager) {
      clearErrors('manager_email');
    } else {
      setError('manager_email', {
        type: 'manual',
        message: 'Insira um email válido.',
      });
    }
  };

  const checkIfHasEmptyInput = ({ formIsValid }) => {
    const fieldsToCheck = [
      'zip',
      'city',
      'company',
      'corporate_name',
      'address',
      'number',
      'district',
      'manager_name',
      'manager_email',
    ];
    fieldsToCheck.forEach((field) => {
      const isFieldEmpty = !watch(field);

      if (isFieldEmpty) {
        setError(field, {
          type: 'manual',
          message: 'Este campo é obrigatório',
        });
        formIsValid = false;
      } else {
        return 'all_inputs_are_filled';
      }
    });
  };

  const veryfIfHasErroInOtherPages = () => {
    const listOfInputsWithErrors = Object.keys(errors);

    const whereIsErrors = () => {
      let page;
      listOfInputsWithErrors.forEach((inputWithError) => {
        const hasErrorInFirstPage = !!fieldsInFirstStep.find(
          (field) => field === inputWithError
        );

        const hasErrorInSecondPage = !!fieldsInSecondStep.find(
          (field) => field === inputWithError
        );

        const hasErrorInThirdPage = !!fieldsInThirdStep.find(
          (field) => field === inputWithError
        );

        if (hasErrorInFirstPage) {
          page = 1;
        } else if (hasErrorInSecondPage) {
          page = 2;
        } else if (hasErrorInThirdPage) {
          page = 3;
        } else if (
          !hasErrorInFirstPage &&
          !hasErrorInSecondPage &&
          !hasErrorInThirdPage
        ) {
          page = 3;
        }
      });

      return page;
    };

    setStepRegister(whereIsErrors());
  };
  const validateStepWithBackend = async (recaptchaToken) => {
    const validationFields = await ValidateSecondStep({
      cnpj: watch('cnpj'),
      recaptchaToken,
    });

    if (validationFields.success) {
      setStepRegister(3);
      setLoading(false);
    } else {
      for (const key in validationFields.data) {
        if (validationFields.data.hasOwnProperty(key)) {
          const error = validationFields.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
      setLoading(false);
    }
  };
  const handleChangeStep = async (recaptchaRef) => {
    let formIsValid = true;

    const resultOfCheckFill = checkIfHasEmptyInput({ formIsValid });

    if (resultOfCheckFill === 'all_inputs_are_filled') {
      veryfIfHasErroInOtherPages({ formIsValid, errors });
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;

      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      setLoading(false);
    }
    if (formIsValid) {
      recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
        recaptchaRef.current.reset();
        setLoading(true);
        validateStepWithBackend(recaptchaToken);
      });
    }
  };

  const handleReturnStep = (stepIndex) => {
    clearErrors('cnpj');
    clearErrors('corporate_name');
    clearErrors('company');
    clearErrors('zip');
    clearErrors('address');
    clearErrors('number');
    clearErrors('comp');
    clearErrors('district');
    clearErrors('city');
    clearErrors('manager_name');
    clearErrors('manager_email');

    setStepRegister(stepIndex);
  };

  return {
    checkIfEmailIsValid,
    handleChangeStep,
    handleReturnStep,
  };
};

export default useValidationRegisterPessoalData;
