import DefaultTemplate from '../../Templates/DefaultTemplate';
import AOS from 'aos';

import './HowToStyles.scss';

import BougthWon from '../../../Media/Incentive/Pages/Howto/comprou-ganhou.webp';
import AcceleratorsImg from '../../../Media/Incentive/Pages/Howto/aceleradores-do-mes.png';
import Blister from '../../../Media/Incentive/Pages/Howto/blister.svg';
import Training from '../../../Media/Incentive/Pages/Howto/training.svg';
import Attention from '../../../Media/Incentive/Pages/Howto/attention.svg';
import Pdv from '../../../Media/Incentive/Pages/Howto/pdv.svg';
import { useContext, useEffect, useState } from 'react';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import Button from '../../../../Components/Button';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { useNavigate } from 'react-router-dom';
import Init from "../../../Utils/Initializer";
import { HowScorePage } from '../../../../Backend/Page';

const HowTo = () => {
  Init();
  const [showScreen, setShowScreen] = useState();

  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);
  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const distributor = localStorage.getItem('distributor');
  const nextPoints = JSON.parse(localStorage.getItem('nextPoints'));

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    const logPage = async () => {
      const response = await HowScorePage();
      return response.success;
    }
    logPage();
  }, []);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="howto pb-[65px]">
            <div className="howto__banner relative">
              <img className="howto__img relative" />

              <div
                data-aos="fade-right"
                data-aos-duration="2000"
                className="absolute w-full bottom-0 lg:top-[490px] lg:w-[50%]"
              >
                <div className="howto__title flex items-center pl-[37px] lg:pl-[139px] h-[96px] lg:h-[139px] ">
                  COMO
                  <br /> PONTUAR
                </div>
              </div>
            </div>
            <div className="howto__container pl-[20px] pr-[20px] pt-[26px] pb-[54px]">
              <h4>VEJA AS FORMAS QUE VOCÊ PODE CONQUISTAR PONTOS! </h4>
              <div className="flex flex-col lg:flex-row lg:justify-center lg:gap-[40px] lg:mt-[130px]">
                <div className="howto__container__bought-won w-full lg:w-[603px] lg:h-[591px] rounded-[30px] py-1 flex flex-col justify-center items-center">
                  <img className='w-[300px] lg:w-[419px] lg:h-[306px]' src={BougthWon} alt="Comprou Ganhou!" />
                  <p className="lg:leading-[1.2]">
                    A cada <span>R${nextPoints?.multiple}</span>
                    <br className="lg:hidden" />
                    em compras
                    <br className="hidden lg:block" /> de produtos{' '}
                    <br className="lg:hidden" />
                    Neo Química acumulados <br />
                    no mês, você ganha <br className="lg:hidden" />
                    <span>{nextPoints?.points} pontos!</span>*
                  </p>
                  {/* <div className="pl-[17px] pb-[24px] pt-[30px] pr-[17px] w-full lg:flex lg:items-center lg:justify-center">
                    <button className="howto__container__bought-won__products">
                      PRODUTOS PARTICIPANTES
                    </button>
                  </div> */}
                </div>
                <div className="howto__container__accelerators flex flex-col items-center justify-center lg:justify-between mt-[124px] lg:mt-0  rounded-[30px] relative">
                  <img
                    src={AcceleratorsImg}
                    alt=""
                    className="absolute bottom-[88%]"
                  />
                  <p className="text-center mb-[32px]">
                    A cada mês, uma campanha <br className="lg:hidden" />
                    diferente <br className="hidden lg:block" /> para você{' '}
                    <br className="lg:hidden" />
                    turbinar sua pontuação:
                  </p>

                  <div className="howto__container__accelerators__items-container mb-[25px] flex gap-[45px] justify-center flex-col items-center">
                    <div className="howto__container__accelerators__items-container__item">
                      <img src={Blister} alt="" />
                      <p>Produtos foco</p>
                    </div>
                    <div className="howto__container__accelerators__items-container__item">
                      <img src={Training} alt="" />
                      <p>Treinamentos</p>
                    </div>
                    <div className="howto__container__accelerators__items-container__item">
                      <img src={Pdv} alt="" />
                      <p>Execução de PDV</p>
                    </div>
                  </div>

                  <span>E muito mais!</span>

                  {/* <div className="pl-[17px] pb-[24px] pt-[30px] pr-[17px] w-full lg:flex lg:items-center lg:justify-center">
                    <button className="howto__container__bought-won__products">
                      CONFIRA OS ACELERADORES <br /> DE PONTUAÇÃO
                    </button>
                  </div> */}
                  <Button
                    text="CONFIRA OS ACELERADORES DE PONTUAÇÃO"
                    variant="bg-[#031E78] text-[#ffd800] h-[50px] lg:px-[80px] w-full flex items-center"
                    type="button"
                    onClick={() => {
                      setLoading(true);
                      navigate('/aceleradores');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="howto__attention w-full flex flex-col items-center justify-center">
              <div className="flex flex-col lg:flex-row lg:w-full lg:items-center lg:justify-center lg:gap-[62px]">
                <div className="flex flex-col ">
                  <div className="howto__attention__card-img w-[320px] flex mb-5">
                    <div className="flex items-center justify-center">
                      <img src={Attention} alt="" />
                    </div>
                    <p className="flex items-center">ATENÇÃO!</p>
                  </div>
                  <p className="pl-[35px] lg:pl-[18px] lg:!text-[24px]">
                    Para pontuar, só serão <br className="lg:hidden" />
                    consideradas
                    <br className="hidden lg:block" /> as{' '}
                    <span>
                      compras <br className="lg:hidden" />
                      com distribuidores
                      <br className="hidden lg:block" /> do seu{' '}
                      <br className="lg:hidden" />
                      próprio estado.
                    </span>
                  </p>
                </div>

                <div className="howto__attention__distributor w-full lg:w-[444px] ">
                  <p>
                    CONFIRA O<br className="lg:hidden" /> DISTRIBUIDOR <br />
                    DO SEU ESTADO:
                  </p>
                  <span>{distributor}</span>
                </div>
              </div>

              <p className="howto__attention__role text-center">
                * Válido para compras realizadas a partir de{' '}
                <br className="lg:hidden" />
                01/03/2021, somente em Distribuidores{' '}
                <br className="lg:hidden" />
                Parceiros Neo Química.
              </p>
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default HowTo;
