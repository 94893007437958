import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetBlog = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/blog');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const GetBlogDetail = (uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/blog/detalhe', {
        post_uuid: uuid,
      });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
