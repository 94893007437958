export const verifyCompleteName = (inputName) => {
  const hasSurName = !!inputName?.split(' ')[1];
  return hasSurName;
};

export const verifyCompleteNameOnBlur = ({
  setError,
  inputName,
  clearErrors,
  fieldName,
}) => {
  const hasSurName = verifyCompleteName(inputName);

  if (!hasSurName && inputName?.length > 0) {
    setError(fieldName, { type: 'manual', message: 'Digite o nome completo.' });
  } else {
    clearErrors(fieldName);
  }
};
