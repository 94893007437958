import './RecoverPasswordStyles.scss';

import { LoginTemplate } from '../../Templates/LoginTemplate';
import RecoverPasswordSVG from '../../../Media/Incentive/Pages/RecoverPassword/recover-password.svg';
import InputText from '../../../../Components/InputText';
import Button from '../../../../Components/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useContext, useEffect, useRef, useState } from 'react';
import RecoverPasswordSuccess from '../RecoverPasswordSuccess';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { ForgotPassword } from '../../../../Backend/forgotPassword';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from "../../../Utils/Initializer";

const RecoverPassword = () => {
  Init();
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const recaptchaRef = useRef(null);
  const { setLoading } = useContext(LoadingContext);
  const makeForgotPasswordRequest = async (cpf, recaptchaToken) => {
    setLoading(true);
    const response = await ForgotPassword(cpf, recaptchaToken);
    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      setError('cpf', {
        type: 'manual',
        message: response.data?.cpf[0]
          ? response.data?.cpf[0]
          : 'Ocorreu um erro, tente novamente',
      });
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    recaptchaRef.current.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      makeForgotPasswordRequest(data.cpf, recaptchaToken);
    });
  };

  const navigate = useNavigate();

  const isLogged = localStorage.getItem('isLogged');
  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false, errorIsYellow: true });
    }
  }, [errors, scrollToErrorUpdate]);
  return (
    <LoginTemplate variation="variation-2">
      {success ? (
        <RecoverPasswordSuccess />
      ) : (
        <section className="recover-password w-[320px] h-[454px] rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
          <img src={RecoverPasswordSVG} alt="" />
          <h1 className="text-[24px] text-center mt-[15px] mb-[5px]">
            ESQUECEU SUA SENHA?
          </h1>
          <p className="text-[18px] text-[#ffd800] text-center leading-[1] mb-[20px]">
            Informe seu CPF <br />
            <span>para criar uma nova.</span>
          </p>
          <form noValidate onSubmit={handleSubmit(onSubmit)} className="w-full">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <Controller
              name="cpf"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputText
                    label="CPF"
                    errors={errors.cpf}
                    placeholder="Insira aqui seu CPF"
                    classInputContainer={`${errors.cpf ? '' : 'mb-5'}`}
                    onChange={onChange}
                    mask="999.999.999-99"
                    inputType="text"
                    inputMode="numeric"
                    styleLabel="!text-white"
                    yellowError
                  />
                );
              }}
            />

            <Button
              variant="bg-[#F7D64C] w-full text-[#031C7A] mb-[14px]"
              text="SOLICITAR SENHA"
              type="submit"
            />
            <Button
              variant="bg-[#28CEE3] w-full text-[#031C7A]"
              text="VOLTAR"
              type="button"
              onClick={() => navigate('/entrar')}
            />
          </form>
        </section>
      )}
    </LoginTemplate>
  );
};

export default RecoverPassword;
