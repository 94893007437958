import * as Yup from 'yup';

export const schema = Yup.object({
  password: Yup.string()
    .test('valid', '', (value) => {
      if (!value) {
        return false;
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);

      return hasUpperCase && hasLowerCase && hasNumber;
    })
    .min(6, 'A senha deve conter no mínimo 6 caracteres.')
    .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula.')
    .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula.')
    .matches(/[0-9]/, 'A senha deve conter ao menos um número.'),
  password_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('password')], 'As senhas não correspondem.'),
});
