/* eslint-disable react-hooks/exhaustive-deps */
import NotFound from './Resources/Views/Incentive/NotFound';
import Health from './Resources/Views/Core/Health';
import { Routes, Route } from 'react-router-dom';
import Home from './Resources/Views/Incentive/Home';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from './Shared/LoadingContext';
import LoadingIcons from 'react-loading-icons';
import July from './Resources/Views/Incentive/Accelerators';
import Result from './Resources/Views/Incentive/Result';
import Award from './Resources/Views/Incentive/Award';
import HowTo from './Resources/Views/Incentive/HowTo';
import Blog from './Resources/Views/Incentive/Blog';
import Post from './Resources/Views/Incentive/Blog/Post';
import Login from './Resources/Views/Incentive/Login';
import RecoverPassword from './Resources/Views/Incentive/RecoverPassword';
import CreatePassword from './Resources/Views/Incentive/CreatePassword';
import Welcome from './Resources/Views/Incentive/Welcome';
import Register from './Resources/Views/Incentive/Register';
import EditProfile from './Resources/Views/Incentive/EditProfile';
import RegisterNewPharma from './Resources/Views/Incentive/RegisterNewPharma';
import EditPharma from './Resources/Views/Incentive/EditPharma';
import ManagePharma from './Resources/Views/Incentive/ManagePharma';
import Logout from './Resources/Views/Incentive/Logout/Logout';
import Maintenance from './Resources/Views/Incentive/Maintenance';
import { ConfirmDataFirstLogin } from './Resources/Views/Incentive/ConfirmDataFirstLogin';
import { GetRelease } from './Backend/release';
import Contact from './Resources/Views/Incentive/Contact';
import RescueAwards from './Resources/Views/Incentive/RescueAwards';
import Popup from './Resources/Views/Incentive/Popup';
import PopupBlackFriday from './Components/PopupBlackFriday/';

import { PopupContext } from './Shared/PopupContext';
import { GetPopup } from './Backend/popup';


function App() {
  const { loadingState, setLoading } = useContext(LoadingContext);
  const [release, setRelease] = useState();

  const verifyRelease = async () => {
    const response = await GetRelease();
    if (response.status === 200) {
      setRelease(true);
    } else {
      setRelease(false);
    }

    setLoading(false);
  };


  useEffect(() => {
    verifyRelease();
  }, []);



  /* Popup */
  const popupViewed = localStorage.getItem('popupViewed');

  const { setPopupData } = useContext(PopupContext);

  const getPopupData = async () => {
    const response = await GetPopup();

    if (response.success) {
      setPopupData(response.data);
    }
  };

  useEffect(() => {
    if(popupViewed === 'true') {
      getPopupData();
    }
  }, []);

  /** */

  return (
    <>
      <Popup />

      {localStorage.getItem('isLogged') === 'logged' ?(
        <>
          <PopupBlackFriday />
        </>
      ) : null}
      
      {loadingState && (
        <div className="loading">
          <div className="ico">
            <LoadingIcons.SpinningCircles />
          </div>
        </div>
      )}
      {release === true && (
        <Routes>
          <Route path="/sonora-health" element={<Health />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/entrar" element={<Login />} />
          <Route path="/bem-vindo" element={<Welcome />} />
          <Route path="/recuperar-senha" element={<RecoverPassword />} />
          <Route path="/criar-senha" element={<CreatePassword />} />
          <Route path="/cadastre-se" element={<Register />} />
          <Route path="/meu-perfil" element={<EditProfile />} />
          <Route path="/editar-farmacia" element={<EditPharma />} />
          <Route
            path="/confirmar-cadastro"
            element={<ConfirmDataFirstLogin />}
          />
          <Route
            path="/cadastrar-nova-farmacia"
            element={<RegisterNewPharma />}
          />
          <Route path="/gerenciar-farmacias" element={<ManagePharma />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/aceleradores" element={<July />} />
          <Route path="/resultados" element={<Result />} />
          <Route path="/premiacoes" element={<Award />} />
          <Route path="/como-pontuar" element={<HowTo />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post/:uuid" element={<Post />} />
          <Route path="/fale-conosco" element={<Contact />} />
          <Route path="/resgatar-premios" element={<RescueAwards />} />
        </Routes>
      )}

      {release === false && (
        <Routes>
          <Route path="*" element={<Maintenance />} />
        </Routes>
      )}
    </>
  );
}

export default App;
