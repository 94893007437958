import React, { useContext } from 'react';
import Client from '../../../../../Backend/Client';
import { LoadingContext } from '../../../../../Shared/LoadingContext';

const LikeButton = ({ post_uuid, setLiked, liked }) => {
  const { setLoading } = useContext(LoadingContext);
  const handleCurtirClick = async () => {
    setLoading(true);
    try {
      const response = await Client.post('/incentive/blog/like', { post_uuid });
      if (response.data === 'liked') {
        setLiked(true);
      } else {
        setLiked(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  return (
    <>
      <div>
        <button onClick={handleCurtirClick}>
          <div>
            <p className="text-[#585858] font-semibold mb-1">
              {liked ? 'Descurtir' : 'Curtir'}
            </p>
          </div>
          <div>{liked ? '💙' : '🤍'}</div>
        </button>
      </div>
    </>
  );
};

export default LikeButton;
