import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomeStyles.scss';
import compre from '../../../Media/Incentive/Icons/compre.svg';
import aproveite from '../../../Media/Incentive/Icons/aproveite.svg';
import conquiste from '../../../Media/Incentive/Icons/conquiste.svg';
import resgate from '../../../Media/Incentive/Icons/resgate.svg';
import product from '../../../Media/Incentive/Pages/Home/os-produtos-neoquimica.webp';
import comprou1 from '../../../Media/Incentive/Pages/Home/comprou-1.png';
import comprou2 from '../../../Media/Incentive/Pages/Home/comprou-2.png';
import premius from '../../../Media/Incentive/Pages/Home/premius-3.png';
import premius2 from '../../../Media/Incentive/Pages/Home/produtos-resgate.webp';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import PointsLevel from '../../../../Components/PointsLevel';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { GetBanners } from '../../../../Backend/banners';
import { HomePage, BannerHit } from '../../../../Backend/Page';
import Init from "../../../Utils/Initializer";
import { LoadingContext } from '../../../../Shared/LoadingContext';

const Home = () => {
  Init();
  const [showScreen, setShowScreen] = useState();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    nav: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          nav: true,
        },
      },
    ],
  };

  const slider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const [images, setImages] = useState([]);
  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const nextPoints = JSON.parse(localStorage.getItem('nextPoints'));

  const GetImages = async () => {
    try {
      const response = await GetBanners();
      setImages(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    GetImages();
  }, []);

  useEffect(() => {
    const logPage = async () => {
      const response = await HomePage();
      return response.success;
    }
    logPage();
  }, []);

  const handleBannerClick = event => {

    const target = event.currentTarget.getAttribute('target');
    const href = event.currentTarget.getAttribute('href');
    const uuid = event.currentTarget.getAttribute('data-item');

    if(target !== '__blank') {
      event.preventDefault();
      setLoading(true);
    }

    const hit = async (uuid) => {
      const response = await BannerHit(uuid);
      return response.success;
    }


    hit(uuid).then(() => {
      if(target !== '__blank') {
        window.location.href = href;
        //setLoading(false);
      }
    });


  };

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="home">
            <div className="home-slider">
              <Slider {...settings}>
                {images.map((item, index) => {
                  const url = item?.url ? new URL(item?.url) : '';
                  const currentDomain = window.location.hostname;
                  const isSameHost = url.hostname === currentDomain;

                  return (
                    <div key={index}>
                      <a href={item.url} target={isSameHost ? '' : '__blank'} data-item={item?.uuid}
                        onClick={handleBannerClick}
                        >
                        <picture>
                          <source
                            media="(max-width: 767px)"
                            srcSet={item.image_mobile}
                          ></source>
                          <img
                            className="block m-auto hover:cursor-pointer"
                            src={item.image}
                            alt=""
                          />
                        </picture>
                      </a>
                    </div>
                  );
                })}
              </Slider>
            </div>

            <div className="home-welcome lg:mt-6 mt-4 mb-8">
              <div data-aos="fade-right" data-aos-duration="2000">
                <h1 className="font-semibold leading-tight">
                  SEJA MUITO BEM-VINDO, <br />
                  PARCEIRO NEO QUÍMICA.
                </h1>
              </div>
              <div className="lg:mt-0 mt-6">
                <p className="leading-tight font-medium lg:text-[22px] text-[16px] text-[#001C7A] lg:w-[493px] w-[257px] mx-auto">
                  Para que possamos demonstrar o<br />
                  reconhecimento que a sua parceria
                  <br className="lg:hidden" />
                  {` `}merece,
                  <br className="hidden lg:block" />
                  reunimos aqui diversas novidades,
                  <br className="hidden lg:block" />
                  vantagens, prêmios, entre outras incríveis{` `}
                  <br className="hidden lg:block" />
                  soluções.
                  <strong className="font-semibold">
                    {` `}Acompanhe, participe e aproveite!
                  </strong>
                </p>
              </div>
            </div>

            <div className="home-intro flex lg:flex-row flex-col justify-center lg:gap-20 gap-8 lg:mt-16">
              <div>
                <h3 className="font-semibold leading-tight lg:w-[513px] w-[258px]">
                  FIQUE LIGADO NO QUE PREPARAMOS PARA VOCÊ E SEUS NEGÓCIOS!
                </h3>
                <div className="w-full flex items-center justify-center">
                  <iframe
                    title="video"
                    className="w-[334px] lg:w-[554px]"
                    src="https://player.vimeo.com/video/557190000"
                    allow=""
                    scrolling="no"
                    allowfullscreen=""
                    width="760"
                    height="440"
                    frameborder=""
                  ></iframe>
                </div>
              </div>
              <div className="lg:mt-[83px]">
                <PointsLevel data={nextPoints} />
              </div>
            </div>

            <div
              id="howto"
              className="home-howto bg-[#F7D64C] lg:py-16 py-10 lg:mt-16 mt-8 mb-12"
            >
              <div className="home-howto-slider">
                <Slider {...slider}>
                  <div data-aos="zoom-in" data-aos-duration="1000">
                    <div className="w-[260px] flex flex-col justify-center items-center mx-auto">
                      <picture >
                        <img
                          src={compre}
                          className="mx-auto block relative top-[12px]"
                        />
                      </picture>
                      <div className="bg-white rounded-[2rem] min-h-[397px]">
                        <div className="bg-[#001C7A] rounded-full w-[260px] h-[62px] flex justify-center items-center">
                          <h1 className="text-[#F7D64C] text-[24px] font-semibold mt-2">
                            1. COMPRE
                          </h1>
                        </div>
                        <div>
                          <h2 className="text-[#001C7A] text-[22px] font-semibold text-center leading-tight mt-3">
                            os produtos
                            <br />
                            Neo Química.
                          </h2>
                        </div>
                        <div className="mt-3">
                          <picture>
                            <img
                              src={product}
                              alt="Produtos Néo Química."
                              width={261}
                              className='px-1 relative top-[65px]'
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="zoom-in" data-aos-duration="1200">
                    <div className="w-[260px] flex flex-col justify-center items-center mx-auto">
                      <picture>
                        <img
                          src={aproveite}
                          className="mx-auto block relative top-[12px]"
                        />
                      </picture>
                      <div className="bg-white rounded-[2rem] min-h-[397px]">
                        <div className="bg-[#001C7A] rounded-full w-[260px] h-[62px] flex justify-center items-center">
                          <h1 className="text-[#F7D64C] text-[24px] font-semibold mt-2">
                            2. APROVEITE
                          </h1>
                        </div>
                        <div>
                          <h2 className="text-[#001C7A] text-[22px] font-semibold text-center leading-tight mt-3 mb-1">
                            as mecânicas
                          </h2>
                        </div>
                        <div className="pb-[11px] mt-5">
                          <picture>
                            <img
                              src={comprou2}
                              alt="Comprou, Ganhou!"
                              className="mx-auto block"
                              width={187}
                            />
                          </picture>
                          <div>
                            <h2 className="text-[#001C7A] text-[22px] font-semibold text-center leading-tight mt-1 mb-2">
                              e os
                            </h2>
                          </div>
                          <picture>
                            <img
                              src={comprou1}
                              alt="Aceleradores do mês"
                              className="mx-auto block"
                              width={220}
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="zoom-in" data-aos-duration="1400">
                    <div className="w-[260px] flex flex-col justify-center items-center mx-auto">
                      <picture>
                        <img
                          src={conquiste}
                          className="mx-auto block relative top-[12px]"
                        />
                      </picture>
                      <div className="bg-white rounded-[2rem] min-h-[397px]">
                        <div className="bg-[#001C7A] rounded-full w-[260px] h-[62px] flex justify-center items-center">
                          <h1 className="text-[#F7D64C] text-[24px] font-semibold mt-2">
                            3. CONQUISTE
                          </h1>
                        </div>
                        <div>
                          <h2 className="text-[#001C7A] text-[22px] font-semibold text-center leading-tight mt-3">
                            muitos pontos
                            <br />
                            ao longo do
                            <br />
                            programa.
                          </h2>
                        </div>
                        <div className="mt-5 pb-[21px]">
                          <picture>
                            <img
                              src={premius}
                              alt="Conquiste"
                              className="mx-auto block mt-[30px]"
                              width={250}
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="zoom-in" data-aos-duration="1600">
                    <div className="w-[260px] flex flex-col justify-center items-center mx-auto">
                      <picture>
                        <img
                          src={resgate}
                          className="mx-auto block relative top-[12px]"
                        />
                      </picture>
                      <div className="bg-white rounded-[2rem] min-h-[397px]">
                        <div className="bg-[#001C7A] rounded-full w-[260px] h-[62px] flex justify-center items-center">
                          <h1 className="text-[#F7D64C] text-[24px] font-semibold mt-2">
                            4. RESGATE
                          </h1>
                        </div>
                        <div>
                          <h2 className="text-[#001C7A] text-[22px] font-semibold text-center leading-tight mt-3">
                            prêmios incríveis e<br />
                            de sua escolha em
                            <br />
                            um catálogo com
                            <br />
                            milhares de opções
                            <br />
                            de produtos, serviços
                            <br />e comodidades.
                          </h2>
                        </div>
                        <div className="pb-2">
                          <picture>
                            <img
                              src={premius2}
                              alt="Produtos Néo Química."
                              width={218}
                              className='m-auto block py-1'
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default Home;
