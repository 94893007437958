/* eslint-disable react-hooks/exhaustive-deps */
import './RegisterPharmaDataStyles.scss';
import step1PersonalDataMobile from '../../../../../../../Media/Incentive/Pages/Register/step2-personal-data-mobile.svg';
import { Controller } from 'react-hook-form';
import InputText from '../../../../../../../../Components/InputText';
import Button from '../../../../../../../../Components/Button';
import { useEffect, useRef } from 'react';

import useValidationRegisterPessoalData from './useValidationsRegisterPharmaData';
import { getUfAndCity } from '../../../../../../../../Backend/getUfAndCity';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrollToFirstError } from '../../../../../../../Utils/ScrollToErrors';

const RegisterPharmaData = ({
  control,
  errors,
  setStepRegister,
  watch,
  setError,
  clearErrors,
  setValue,
  scrollToErrorUpdate,
  setScrollToErrorUpdate,
}) => {
  const inputEmailManager = watch('manager_email');
  const zipValue = watch('zip') ? watch('zip').replace(/-/, '') : '';

  const recaptchaRef = useRef(null);

  const { checkIfEmailIsValid, handleChangeStep, handleReturnStep } =
    useValidationRegisterPessoalData({
      inputEmailManager,
      setError,
      clearErrors,
      watch,
      setStepRegister,
      errors,
      scrollToErrorUpdate,
      setScrollToErrorUpdate,
    });

  const checkIfNameHasSurname = () => {
    const inputName = watch('manager_name');

    if (!!inputName?.split(' ')[1]) {
      clearErrors('manager_name');
    } else {
      setError('manager_name', {
        type: 'manual',
        message: 'Digite o nome completo.',
      });
    }
  };
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false });
    }
  }, [errors, scrollToErrorUpdate]);

  // useEffect(() => {
  //   if (zipValue && zipValue.length === 8) {
  //     getUfAndCity({ zipValue, setValue });
  //   }
  // }, [zipValue]);

  return (
    <section
      className="register-pharma-data w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[18px] lg:!pl-[150px] lg:!pr-[150px]"
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      <img src={step1PersonalDataMobile} alt="" />

      <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[50px] lg:w-[765px] h-[99px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[45px] lg:pr-[45px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
        <h1 className="font-semibold">
          PREENCHA <br className="lg:hidden" />
          AS INFORMAÇÕES <br className="lg:hidden" />
          DA SUA FARMÁCIA
        </h1>
      </div>

      <div className="w-full flex flex-col gap-[20px] mt-[20px]">
        <Controller
          control={control}
          name="cnpj"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                disabled
                label="CNPJ"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('cnpj');
                }}
                inputMode="numeric"
                value={value}
                mask="99.999.999/9999-99"
                placeholder="00.000.000/0000-00"
                onBlur={() => {}}
                errors={errors?.cpf}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="corporate_name"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Razão social"
                styleLabel="!text-[#031E78]"
                placeholder="Razão social"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('corporate_name');
                }}
                value={value}
                errors={errors?.corporate_name}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="company"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Nome fantasia"
                styleLabel="!text-[#031E78]"
                placeholder="Nome fantasia"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('company');
                }}
                value={value}
                errors={errors?.company}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="zip"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="CEP"
                styleLabel="!text-[#031E78]"
                placeholder="CEP"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('zip');
                }}
                inputMode="numeric"
                onBlur={() => {
                  if (zipValue && zipValue.length === 8) {
                    getUfAndCity({ zipValue, setValue });
                  }
                }}
                value={value}
                mask="99999-999"
                errors={errors?.zip}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Endereço"
                placeholder="Endereço"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('address');
                }}
                value={value}
                errors={errors?.address}
              />
            );
          }}
        />
        <div className="flex flex-col lg:flex-row gap-[18px]">
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Número"
                  placeholder="Número"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('number');
                  }}
                  inputMode="numeric"
                  value={value}
                  errors={errors?.number}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="comp"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Complemento"
                  placeholder="Complemento"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('comp');
                  }}
                  value={value}
                  errors={errors?.comp}
                />
              );
            }}
          />
        </div>

        <div className="flex flex-col lg:flex-row gap-[18px]">
          <Controller
            control={control}
            name="district"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Bairro"
                  placeholder="Bairro"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('district');
                  }}
                  value={value}
                  errors={errors?.district}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Cidade"
                  placeholder="Cidade"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('city');
                  }}
                  value={value}
                  errors={errors?.city}
                />
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="manager_name"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label={
                  <span>
                    Nome do responsável <br className="lg:hidden" />
                    para receber comunicações
                  </span>
                }
                onBlur={() => checkIfNameHasSurname()}
                placeholder="Nome do responsável"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('manager_name');
                }}
                value={value}
                errors={errors?.manager_name}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="manager_email"
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label={
                  <span>
                    Email do responsável <br className="lg:hidden" />
                    para receber comunicações
                  </span>
                }
                placeholder="Email do responsável"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value.trim());
                  clearErrors('manager_email');
                }}
                onBlur={() => checkIfEmailIsValid()}
                value={value}
                errors={errors?.manager_email}
              />
            );
          }}
        />

        <div className="flex items-center flex-col lg:flex-row justify-center w-full gap-[18px] lg:gap-[28px]">
          <Button
            text="VOLTAR"
            variant="bg-[#28CEE3] text-[#031E78] w-full  lg:w-[220px]"
            type="button"
            onClick={() => handleReturnStep(1)}
          />
          <Button
            text="PROSSEGUIR"
            variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full  lg:w-[220px]"
            type="button"
            onClick={() => handleChangeStep(recaptchaRef)}
          />
        </div>
      </div>
      <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
        Restrito a farmácias aptas <br className="lg:hidden" />
        a participar do programa. <br />
        Os dados cadastrados <br className="lg:hidden" />
        devem ser de acordo <br className="lg:hidden" />
        com <br className="hidden lg:block" /> o estabelecimento{' '}
        <br className="lg:hidden" />e seus responsáveis.
      </div>
    </section>
  );
};

export default RegisterPharmaData;
