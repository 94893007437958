import { useContext, useState } from 'react';

import { UpdateValidateSecondStep } from '../../../../../Backend/validateStepsRegister';
import { validateEmail } from '../../../../Utils/validateEmail';
import {
  fieldsInFirstStep,
  fieldsInSecondStep,
  fieldsInThirdStep,
} from '../../Register/Components/CorrectCpfAndCnpj/Utils/variables';
import { LoadingContext } from '../../../../../Shared/LoadingContext';

const useValidationRegisterPharmaData = ({
  inputEmailManager,
  index,
  setError,
  clearErrors,
  watch,
  setStep,
  errors,
  isLastPharma,
  isFirstPharma,
  setPharmaIndex,
  setArrayStores,
  arrayStores,
  scrollToErrorUpdate,
  setScrollToErrorUpdate,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const checkIfEmailIsValid = () => {
    const isValid = validateEmail(inputEmailManager);

    if (isValid || !inputEmailManager) {
      clearErrors(`manager_email.${index}`);
    } else {
      setError(`manager_email.${index}`, {
        type: 'manual',
        message: 'Insira um email válido.',
      });
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
    }
  };

  const checkIfHasEmptyInput = ({ formIsValid }) => {
    const fieldsToCheck = [
      `zip.${index}`,
      `city.${index}`,
      `company.${index}`,
      `corporate_name.${index}`,
      `address.${index}`,
      `number.${index}`,
      `district.${index}`,
      `manager_name.${index}`,
      `manager_email.${index}`,
    ];

    fieldsToCheck.forEach((field) => {
      const isFieldEmpty = !watch(field);

      if (isFieldEmpty) {
        setError(field, {
          type: 'manual',
          message: 'Este campo é obrigatório',
        });
        setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        formIsValid = false;
      } else {
        return 'all_inputs_are_filled';
      }
    });
  };

  const validateStepWithBackend = async (recaptchaToken) => {
    const validationFields = await UpdateValidateSecondStep({
      cnpj: watch(`cnpj.${index}`),
      recaptchaToken,
    });

    if (validationFields.success) {
      const hasThisStoreInState = arrayStores?.find(
        (store) => store.uuid === watch(`uuid.${index}`)
      );

      if (!hasThisStoreInState) {
        setArrayStores((prev) => {
          const newObjOfStore = {
            uuid: watch(`uuid.${index}`),
            cnpj: watch(`cnpj.${index}`),
            company: watch(`company.${index}`),
            corporate_name: watch(`corporate_name.${index}`),
            zip: watch(`zip.${index}`),
            manager_name: watch(`manager_name.${index}`),
            manager_email: watch(`manager_email.${index}`),
            address: watch(`address.${index}`),
            number: watch(`number.${index}`),
            comp: watch(`comp.${index}`),
            district: watch(`district.${index}`),
            city: watch(`city.${index}`),
          };
          return [...prev, newObjOfStore];
        });
      }
      setLoading(false);
      return isLastPharma ? setStep(3) : setPharmaIndex(index + 1);
    } else {
      setLoading(false);
    }
  };

  const handleChangeStep = async (recaptchaRef) => {
    let formIsValid = true;
    const resultOfCheckFill = checkIfHasEmptyInput({ formIsValid });

    if (resultOfCheckFill === 'all_inputs_are_filled') {
      formIsValid = true;
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
      setLoading(false);
      setScrollToErrorUpdate(scrollToErrorUpdate + 1);
    }
    if (formIsValid) {
      recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
        setLoading(true);
        recaptchaRef.current.reset();
        validateStepWithBackend(recaptchaToken);
      });
    }
  };

  const handleReturnStep = (stepIndex) => {
    clearErrors(`cnpj.${index}`);
    clearErrors(`corporate_name.${index}`);
    clearErrors(`company.${index}`);
    clearErrors(`zip.${index}`);
    clearErrors(`address.${index}`);
    clearErrors(`number.${index}`);
    clearErrors(`comp.${index}`);
    clearErrors(`district.${index}`);
    clearErrors(`city.${index}`);
    clearErrors(`state.${index}`);
    clearErrors(`manager_name.${index}`);
    clearErrors(`manager_email.${index}`);

    setStep(stepIndex);
  };

  const handleReturnPharma = (indexBeforePage) => {
    clearErrors(`cnpj.${index}`);
    clearErrors(`corporate_name.${index}`);
    clearErrors(`company.${index}`);
    clearErrors(`zip.${index}`);
    clearErrors(`address.${index}`);
    clearErrors(`number.${index}`);
    clearErrors(`comp.${index}`);
    clearErrors(`district.${index}`);
    clearErrors(`city.${index}`);
    clearErrors(`state.${index}`);
    clearErrors(`manager_name.${index}`);
    clearErrors(`manager_email.${index}`);
    setPharmaIndex(indexBeforePage);
  };

  return {
    checkIfEmailIsValid,
    handleChangeStep,
    handleReturnStep,
    handleReturnPharma,
  };
};

export default useValidationRegisterPharmaData;
