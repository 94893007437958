import './ManagePharmaStyles.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import Button from '../../../../Components/Button';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import {
  GetDetailPdv,
  GetManagePharmaList,
} from '../../../../Backend/managePharma';
import { formatCNPJ } from '../../../Utils/formatCnpj';

const ManagePharma = () => {
  const [showScreen, setShowScreen] = useState();
  const [pharmaList, setPharmaList] = useState([]);

  const { verifyIfIsLogged } = useVerifyIfIsLogged();
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const getListPharmas = async () => {
    const response = await GetManagePharmaList();
    if (response.success) {
      setPharmaList(response.data);
    }
  };

  const getDetailPharma = async (storeUuid) => {
    setLoading(true);
    const response = await GetDetailPdv(storeUuid);

    if (response.success) {
      navigate('/editar-farmacia', {
        state: response.data,
      });
      setLoading(false);
    } else {
      setLoading(false);

      return;
    }
  };

  useEffect(() => {
    setLoading(true);
    verifyIfIsLogged(setShowScreen);
    getListPharmas();
  }, []);

  return (
    <>
      {showScreen === true && (
        <LoginTemplate variation="variation-1">
          <section
            className="manage-pharma w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
    items-center justify-center mb-[60px] mt-[130px] lg:!pl-[100px] lg:!pr-[100px]"
          >
            <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[70px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[20px] lg:pr-[20px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
              <h1 className="font-semibold">
                GERENCIAR <br className="lg:hidden" />
                FARMÁCIAS
              </h1>
            </div>
            <p className="text-center text-[20px] text-[#031E78] leading-[1.2] mb-[25px] mt-[20px]">
              <strong>Encontre</strong> e <strong>cadastre </strong>
              <br className="lg:hidden" />
              aqui todas as{' '}
              <strong>
                farmácias
                <br className="lg:hidden" /> associadas ao seu acesso.
              </strong>
              <br className="lg:hidden" />
              <br />
              Caso necessário,
              <br className="lg:hidden" />
              <strong> atualize os dados</strong> de{' '}
              <br className="lg:hidden" />
              cada ponto de venda.
            </p>

            <Button
              variant="bg-[#031C7A] w-full text-[#F7D64C] w-[287px]"
              text="ADICIONAR NOVA FARMÁCIA"
              type="button"
              onClick={() => {
                setLoading(true);
                navigate('/cadastrar-nova-farmacia');
              }}
            />

            <div className="mt-[30px] bg-[#031C7A] w-[300px] lg:w-[794px] flex flex-col items-center justify-center lg:pt-[16px] lg:pl-[39px] lg:pr-[39px] lg:pb-[24px] rounded-[25px]">
              <h3 className="text-[20px] text-[#F7D64C] font-[600] mb-[15px] lg:mb-[14px] mt-[17px] lg:mt-[0]">
                FARMÁCIAS CADASTRADAS
              </h3>
              {pharmaList.length > 0 &&
                pharmaList?.map((item, index) => {
                  return (
                    <div className="flex flex-col gap-[2px] w-[264px] manage-pharma__table-mobile mb-[17px] lg:hidden">
                      <div className="w-full manage-pharma__table-mobile__title rounded-tl-[15px] rounded-tr-[15px]">
                        CNPJ
                      </div>
                      <div className="w-full manage-pharma__table-mobile__value">
                        {formatCNPJ(item.cnpj)}
                      </div>
                      <div className="w-full manage-pharma__table-mobile__title">
                        Nome Fantasia
                      </div>
                      <div className="w-full manage-pharma__table-mobile__value">
                        {item.company}
                      </div>
                      <div className="w-full manage-pharma__table-mobile__title">
                        CEP
                      </div>
                      <div className="w-full manage-pharma__table-mobile__value">
                        {item.zip}
                      </div>
                      <div className="w-full manage-pharma__table-mobile__value rounded-bl-[15px] rounded-br-[15px]">
                        <Button
                          onClick={() => {
                            setLoading(true);
                            navigate('/editar-farmacia', {
                              state: { cnpj: item.cnpj },
                            });
                          }}
                          text="Editar"
                          variant="outlined text-[#031E78] font-[500] w-[105px] lowercase hover:text-[#031E78]"
                        />
                      </div>
                    </div>
                  );
                })}
              <table cellSpacing={2} className="hidden lg:block">
                <thead>
                  <tr>
                    <th className="rounded-tl-[14px] w-[202px]">CNPJ</th>
                    <th className="w-[254px]">Nome Fantasia</th>
                    <th className="rounded-tr-[14px] w-[149px]">CEP</th>
                  </tr>
                </thead>
                <tbody>
                  {pharmaList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="w-[202px]">{formatCNPJ(item.cnpj)}</td>
                        <td className="w-[254px]">{item.company}</td>
                        <td className="w-[149px]">{item.zip}</td>
                        <td className="button-cell w-[105px]">
                          <Button
                            onClick={() => getDetailPharma(item.uuid)}
                            text="Editar"
                            variant="outlined text-[#031E78] font-[500] w-[105px] lowercase hover:text-[#031E78]"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </LoginTemplate>
      )}
    </>
  );
};

export default ManagePharma;
