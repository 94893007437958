import { useLocation } from 'react-router-dom';
import Button from '../../../../../Components/Button';
import EmailSvg from '../../../../Media/Incentive/Pages/LoginCreatePassword/email.svg';
import './CreatePasswordOneEmailStyles.scss';
import { useContext, useState } from 'react';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import { SendPasswordFirstLogin } from '../../../../../Backend/firstLogin';

const CreatePasswordOneEmail = ({ setStep }) => {
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();
  const { setLoading } = useContext(LoadingContext);
  const email = location.state.stores[0].email;
  const storeUuuid = location.state.stores[0].uuid;
  const userUuid = location.state.user;

  const verifyResponse = (response) => {
    if (response.success) {
      setStep(4);
      setLoading(false);
    } else {
      setErrorMessage(
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.'
      );
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const response = await SendPasswordFirstLogin({
      user: userUuid,
      store: storeUuuid,
    });

    verifyResponse(response);
  };

  return (
    <section className="create-password-one-email w-[320px] rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mt-[30px] mb-[60px]">
      <img src={EmailSvg} alt="" />
      <h1 className="text-[24px] text-center mt-[15px] mb-[19px] leading-[1.2]">
        CRIE SUA NOVA SENHA
      </h1>
      <p className="text-[18px] text-center leading-[1.1] mb-[20px] break-all">
        Você receberá
        <br />
        as <span>instruções para cadastro</span> <br />
        de{' '}
        <span>
          nova senha no e-mail
          <br />
          {email}
        </span>
      </p>
      {errorMessage && (
        <p className="m-0 leading-[1.2] !text-[#cc0000]">{errorMessage}</p>
      )}

      {!email ? (
        <p className="m-0 text-center leading-[1.2] !text-[#cc0000]">
          Nenhum e-mail cadastrado. Favor entrar em contato com o suporte
        </p>
      ) : (
        <Button
          variant="bg-[#F7D64C] w-full text-[#031C7A]"
          text="ENVIAR"
          type="button"
          onClick={() => handleSubmit()}
        />
      )}
    </section>
  );
};

export default CreatePasswordOneEmail;
