import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import CreatePasswordSuccessSvg from '../../../Media/Incentive/Icons/checked-success.svg';
import './CreatePasswordSuccessStyles.scss';
import Init from "../../../Utils/Initializer";

const CreatePasswordSuccess = () => {
  Init();
  const navigate = useNavigate();

  return (
    <section className="create-password-success w-[320px]  rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mb-[60px] mt-[30px]">
      <img src={CreatePasswordSuccessSvg} alt="" />
      <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
        NOVA SENHA GERADA
      </h1>
      <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
        Sua solicitação
        <br />
        foi atendida com sucesso.
        <br />
        <br />
        <span>Utilize a senha criada</span> para <br /> aproveitar as vantagens
        <br /> da nossa parceria.
      </p>

      <Button
        variant="bg-[#F7D64C] w-full text-[#031C7A]"
        text="VOLTAR AO LOGIN   "
        type="button"
        onClick={() => navigate('/entrar')}
      />
    </section>
  );
};

export default CreatePasswordSuccess;
