import Client from './Client';
import { formatResponse } from './formatResponse';

export async function RegisterUser({
  cpf,
  name,
  email,
  email_confirmation,
  password,
  password_confirmation,
  birth,
  sex,
  mobile,
  cnpj,
  corporate_name,
  company,
  zip,
  address,
  number,
  comp,
  district,
  city,
  state,
  manager_name,
  manager_email,
  optin_rules,
  optin_commercial,
  optin_privacy,
  recaptchaToken,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/register', {
        cpf,
        name,
        email,
        email_confirmation,
        password,
        password_confirmation,
        birth,
        sex,
        mobile,
        cnpj,
        corporate_name,
        company,
        zip,
        address,
        number,
        comp,
        district,
        city,
        state,
        manager_name,
        manager_email,
        optin_rules,
        optin_commercial,
        optin_privacy,
        'g-recaptcha-response': recaptchaToken,
        // source: localStorage.getItem('source') || null,
        // medium: localStorage.getItem('medium') || null,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export const VerifyRegister = async (cpf, cnpj, recaptchaToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/verificar-cadastro', {
        cpf,
        cnpj,
        'g-recaptcha-response': recaptchaToken,
      });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const ChangeCnpjManager = async ({
  cpf,
  cnpj,
  name,
  email,
  mobile,
  recaptchaToken,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await await Client.post(
        '/incentive/alterar-responsavel-pdv',
        {
          cpf,
          cnpj,
          name,
          email,
          mobile,
          'g-recaptcha-response': recaptchaToken,
        }
      );

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
