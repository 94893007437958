export const NoChecked = ({ error }) => {
  return (
    <svg
      id="Componente_19_1"
      data-name="Componente 19 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        id="Elipse_158"
        data-name="Elipse 158"
        cx="12"
        cy="12"
        r="12"
        fill="#fff"
      />
    </svg>
  );
};

export const Checked = () => {
  return (
    <svg
      id="Componente_19_3"
      data-name="Componente 19 – 3"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        id="Elipse_158"
        data-name="Elipse 158"
        cx="12"
        cy="12"
        r="12"
        fill="#fff"
      />
      <circle
        id="Elipse_159"
        data-name="Elipse 159"
        cx="6"
        cy="6"
        r="6"
        transform="translate(6 6)"
        fill="#031e78"
      />
    </svg>
  );
};

export const CheckedFaq = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <path
        id="Caminho_153"
        data-name="Caminho 153"
        d="M18.875,5.462A13.408,13.408,0,1,1,9.389,9.389a13.325,13.325,0,0,1,9.486-3.927m0-2.087a15.5,15.5,0,1,0,15.5,15.5,15.5,15.5,0,0,0-15.5-15.5Z"
        transform="translate(-3.375 -3.375)"
        fill="#031c7a"
      />
      <path
        id="Caminho_154"
        data-name="Caminho 154"
        d="M26.65,10.629a11.334,11.334,0,1,0,3.316,8.011A11.256,11.256,0,0,0,26.65,10.629Z"
        transform="translate(-3.139 -3.139)"
        fill="#031c7a"
      />
    </svg>
  );
};

export const NoCheckedFaq = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <path
        id="Caminho_153"
        data-name="Caminho 153"
        d="M18.875,5.462A13.408,13.408,0,1,1,9.389,9.389a13.325,13.325,0,0,1,9.486-3.927m0-2.087a15.5,15.5,0,1,0,15.5,15.5,15.5,15.5,0,0,0-15.5-15.5Z"
        transform="translate(-3.375 -3.375)"
        fill="#031c7a"
      />
    </svg>
  );
};
