/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import ConfirmPessoalData from './ConfirmPessoalData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import ConfirmPharmaData from './ConfirmPharmaData';
import ConfirmOptins from './ConfirmeOptins';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { GetUserCpf } from '../../../../Backend/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateDataFirstLogin } from '../../../../Backend/firstLogin';
import {
  fieldsInFirstStep,
  fieldsInSecondStep,
  fieldsInThirdStep,
} from '../Register/Components/CorrectCpfAndCnpj/Utils/variables';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from "../../../Utils/Initializer";

export const ConfirmDataFirstLogin = () => {
  Init();
  const [step, setStep] = useState(1);
  const [pharmaIndex, setPharmaIndex] = useState(0);
  const [arrayStores, setArrayStores] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const recaptchaRef = useRef(null);

  const location = useLocation();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const verifyScreenOfFieldWithError = (fieldWithError) => {
    const fieldErrorIsInFirstStep = fieldsInFirstStep.find(
      (fieldInFirstStep) => fieldInFirstStep === fieldWithError
    );

    const fieldErrorIsInSecondStep = fieldsInSecondStep.find(
      (fieldInSecondStep) => fieldInSecondStep === fieldWithError?.split('.')[0]
    );

    const fieldErrorIsInThirdStep = fieldsInThirdStep.find(
      (fieldInThirdtStep) => fieldInThirdtStep === fieldWithError
    );
    if (fieldErrorIsInFirstStep) {
      return 1;
    }

    if (fieldErrorIsInSecondStep) {
      return 2;
    }

    if (fieldErrorIsInThirdStep) {
      return 3;
    }
  };

  const redirectScreenWithError = (response) => {
    const arrOfErrorsFields = Object.keys(response.data);

    let page;

    arrOfErrorsFields.forEach((errorField) => {
      const pageNumber = verifyScreenOfFieldWithError(errorField);

      page = pageNumber ? pageNumber : 3;

      if (!pageNumber) {
        setError('optin_commercial', {
          type: 'manual',
          message: 'Ocorreu um erro. Tente novamente.',
        });
      }
    });

    setStep(page);
  };

  const onSubmit = async (data, recaptchaToken) => {
    const payload = {
      cpf: data.cpf,
      name: data.name,
      birth: data.birth,
      sex: data.sex,
      mobile: data.mobile,
      email: data.email,
      email_confirmation: data.email_confirmation,
      stores: arrayStores,
      optin_rules: data.optin_rules,
      optin_privacy: data.optin_privacy,
      optin_commercial: data.optin_commercial,
    };

    const response = await updateDataFirstLogin(payload, recaptchaToken);

    if (response.success === true) {
      setLoading(false);
      navigate('/');
    } else {
      setLoading(false);

      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
        }

        setScrollToErrorUpdate(scrollToErrorUpdate + 1);
      }

      redirectScreenWithError(response);
    }

    setLoading(false);
  };

  const handleCustomSubmit = (data) => {
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      setLoading(true);
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const setCpf = async () => {
    const response = await GetUserCpf();
    if (response.success) {
      setValue('cpf', response?.data?.cpf);
    }
  };

  useEffect(() => {
    setCpf();

    setValue('name', location.state.user.name);
    setValue('birth', location.state.user.birth);
    setValue('sex', location.state.user.sex);
    setValue('mobile', location.state.user.mobile);
    setValue('email', location.state.user.email);
    setValue('email_confirmation', location.state.user.email);
  }, []);

  useEffect(() => {
    if (step === 1) {
      setPharmaIndex(0);
    }
  }, [step]);

  const isLogged = localStorage.getItem('isLogged');
  useEffect(() => {
    if (!isLogged) {
      navigate('/entrar');
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [step, pharmaIndex]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true, errorIsYellow: false });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <LoginTemplate variation="variation-1">
      <form onSubmit={handleSubmit(handleCustomSubmit)}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
        {step === 1 && (
          <ConfirmPessoalData
            control={control}
            setStep={setStep}
            watch={watch}
            setError={setError}
            errors={errors}
            clearErrors={clearErrors}
            setScrollToErrorUpdate={setScrollToErrorUpdate}
            scrollToErrorUpdate={scrollToErrorUpdate}
          />
        )}
        {step === 2 && (
          <>
            {location?.state?.stores?.map((store, index) => {
              const isLastPharma = location.state.stores.length - 1 === index;
              const isFirstPharma = index === 0;
              return (
                <>
                  {pharmaIndex === index && (
                    <ConfirmPharmaData
                      index={index}
                      isFirstPharma={isFirstPharma}
                      control={control}
                      isLastPharma={isLastPharma}
                      storeData={store}
                      arrayStores={arrayStores}
                      setArrayStores={setArrayStores}
                      setStep={setStep}
                      setPharmaIndex={setPharmaIndex}
                      watch={watch}
                      setError={setError}
                      errors={errors}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      step={step}
                      setScrollToErrorUpdate={setScrollToErrorUpdate}
                      scrollToErrorUpdate={scrollToErrorUpdate}
                    />
                  )}
                </>
              );
            })}
          </>
        )}
        {step === 3 && (
          <ConfirmOptins
            control={control}
            setStep={setStep}
            watch={watch}
            setError={setError}
            errors={errors}
            clearErrors={clearErrors}
            setValue={setValue}
            setScrollToErrorUpdate={setScrollToErrorUpdate}
            scrollToErrorUpdate={scrollToErrorUpdate}
          />
        )}
      </form>
    </LoginTemplate>
  );
};
