/* eslint-disable react-hooks/exhaustive-deps */
import './EditProfileStyles.scss';
import { Controller, useForm } from 'react-hook-form';
import { useContext, useEffect, useRef, useState } from 'react';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import InputText from '../../../../Components/InputText';
import InputSelect from '../../../../Components/InputSelect';
import { optionsSexList } from '../../../../Shared/optionsSexList';
import Button from '../../../../Components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { checkIfPhoneIsCompleted } from '../../../../Shared/validations';
import { useNavigate } from 'react-router-dom';
import EditProfileSuccess from '../EditProfileSuccess';
import { Profile } from '../../../../Backend/auth';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import ReCAPTCHA from 'react-google-recaptcha';
import { PostEditProfile } from '../../../../Backend/editProfile';
import moment from 'moment';
import { validateBirthOnBlur } from '../../../Utils/validateBirth';
import { validateEmail } from '../../../Utils/validateEmail';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from '../../../Utils/Initializer';
import PasswordChecklist from 'react-password-checklist';

const EditProfile = () => {
  Init();
  const {
    control,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const [success, setSuccess] = useState(false);
  const [showScreen, setShowScreen] = useState();
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const inputMobile = watch('mobile');

  const onSubmit = async (data, recaptchaToken) => {
    const response = await PostEditProfile({
      cpf: data.cpf,
      name: data.name,
      birth_at: data.birth_at,
      sex: data.sex,
      mobile: data.mobile,
      birth: moment(data.birth, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      email: data.email,
      email_confirmation: data.email_confirmation,
      password: data.password,
      password_confirmation: data.password_confirmation,
      recaptchaToken: recaptchaToken,
    });

    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      for (const key in response.data) {
        setLoading(false);

        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
      setLoading(false);
    }
  };

  const recaptchaRef = useRef(null);

  const inputEmail = watch('email');
  const inputEmailConfirmation = watch('email_confirmation');
  const inputPassword = watch('password');
  const inputPasswordConfirmation = watch('password_confirmation');

  const checkIfPasswordIsEqual = () => {
    if (
      !inputPasswordConfirmation ||
      inputPasswordConfirmation === inputPassword
    ) {
      clearErrors('password_confirmation');
    } else if (
      inputPasswordConfirmation &&
      inputPasswordConfirmation !== inputPassword
    ) {
      setError('password_confirmation', {
        type: 'manual',
        message: 'As senhas não correspondem',
      });
    }
  };

  const checkIfEmailIsValid = () => {
    const isValid = validateEmail(inputEmail);

    if (isValid || !inputEmail) {
      clearErrors('email');
    } else {
      setError('email', { type: 'manual', message: 'Insira um email válido.' });
    }
  };

  const checkIfEmailIsEqual = () => {
    if (
      inputEmailConfirmation === inputEmail ||
      inputEmailConfirmation?.length === 0
    ) {
      clearErrors('email_confirmation');
    } else {
      setError('email_confirmation', {
        type: 'manual',
        message: 'Os e-mails não correspondem',
      });
    }
  };

  const handleCustomSubmit = (data) => {
    setLoading(true);
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  useEffect(() => {
    if (inputMobile?.length === 15) {
      clearErrors('mobile');
    }
  }, [inputMobile]);

  const getDataForm = async () => {
    const response = await Profile();

    if (response.success) {
      setValue('cpf', response.data.cpf);
      setValue('name', response.data.name);
      setValue('birth', response.data.birth);
      setValue('sex', response.data.sex);
      setValue('mobile', response.data.mobile);
      setValue('email', response.data.email);
      setValue('email_confirmation', response.data.email);
    } else {
      navigate('/entrar');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('name');
    }
  };
  useEffect(() => {
    setLoading(true);
    getDataForm();
  }, []);

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const checkIfNameHasSurname = () => {
    const inputName = watch('name');

    if (!!inputName.split(' ')[1]) {
      clearErrors('name');
    } else {
      setError('name', {
        type: 'manual',
        message: 'Digite o nome completo.',
      });
    }
  };

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  useEffect(() => {
    if (!!inputPassword === false) {
      setValue('password', undefined);
    }
  }, [inputPassword]);

  return (
    <>
      {showScreen === true && (
        <>
          {success ? (
            <EditProfileSuccess />
          ) : (
            <LoginTemplate variation="variation-1">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />

              <form
                noValidate
                onSubmit={handleSubmit(handleCustomSubmit)}
                className="mt-50"
              >
                <section
                  className="edit-profile w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[130px] lg:!pl-[150px] lg:!pr-[150px]"
                >
                  <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[41px] pl-[30px] pr-[30px] lg:pl-[45px] lg:pr-[45px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
                    <h1 className="font-semibold">MEU PERFIL</h1>
                  </div>

                  <div className="w-full flex flex-col gap-[20px] mt-[20px]">
                    <Controller
                      control={control}
                      name="cpf"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            disabled
                            label="CPF"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('cpf');
                            }}
                            inputMode="numeric"
                            value={value}
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            errors={errors?.cpf}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="name"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Nome completo"
                            styleLabel="!text-[#031E78]"
                            placeholder="Nome completo"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('name');
                            }}
                            onBlur={() => checkIfNameHasSurname()}
                            value={value}
                            errors={errors?.name}
                          />
                        );
                      }}
                    />
                    <div className="flex flex-col lg:flex-row gap-[18px] ">
                      <Controller
                        control={control}
                        name="birth"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Data de nascimento"
                              styleLabel="!text-[#031E78]"
                              placeholder="Data de nascimento"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('birth');
                              }}
                              inputMode="numeric"
                              onBlur={() =>
                                validateBirthOnBlur({
                                  setError,
                                  inputBirth: watch('birth'),
                                  clearErrors,
                                })
                              }
                              mask="99/99/9999"
                              value={value}
                              errors={errors?.birth}
                            />
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="sex"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputSelect
                              label="Gênero"
                              placeholder="Gênero"
                              value={value}
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('sex');
                              }}
                              optionsSelect={optionsSexList}
                              errors={errors?.sex}
                            />
                          );
                        }}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="mobile"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Celular"
                            value={value}
                            placeholder="Celular"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('mobile');
                            }}
                            inputMode="numeric"
                            mask="(99) 99999-9999"
                            errors={errors?.mobile}
                            onBlur={() => {
                              checkIfPhoneIsCompleted({
                                inputMobile: value,
                                setError,
                                clearErrors,
                              });
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="email"
                      placeholder="E-mail"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="E-mail"
                            value={value}
                            placeholder="E-mail"
                            styleLabel="!text-[#031E78]"
                            onBlur={() => {
                              checkIfEmailIsValid();
                              checkIfEmailIsEqual();
                            }}
                            onChange={(e) => {
                              onChange(e.target.value.trim());
                              clearErrors('email');
                            }}
                            errors={errors?.email}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="email_confirmation"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Confirmar e-mail"
                            value={value}
                            placeholder="Confirmar e-mail"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value.trim());
                              clearErrors('email_confirmation');
                            }}
                            onBlur={() => checkIfEmailIsEqual()}
                            errors={errors?.email_confirmation}
                          />
                        );
                      }}
                    />
                    <div className="flex flex-col lg:flex-row gap-[18px]">
                      <Controller
                        control={control}
                        name="password"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Criar senha"
                              value={value}
                              placeholder="Criar senha"
                              styleLabel="!text-[#031E78]"
                              inputPassword
                              onBlur={() => {
                                if (value) {
                                  checkIfPasswordIsEqual();
                                }
                              }}
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('password');
                              }}
                              errors={errors?.password}
                            />
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="password_confirmation"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Confirmar senha"
                              value={value}
                              placeholder="Confirmar senha"
                              styleLabel="!text-[#031E78]"
                              inputPassword
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('password_confirmation');
                              }}
                              errors={errors?.password_confirmation}
                              onBlur={() => checkIfPasswordIsEqual()}
                            />
                          );
                        }}
                      />
                    </div>
                    {watch('password') ? (
                      <PasswordChecklist
                        className="style-checklist"
                        rules={[
                          'minLength',
                          'number',
                          'capital',
                          'lowercase',
                          'match',
                        ]}
                        value={watch('password') ? watch('password') : ''}
                        messages={{
                          minLength:
                            'A senha deve conter no mínimo 6 caracteres.',
                          number: 'A senha deve conter ao menos um número.',
                          capital:
                            'A senha deve conter ao menos uma letra maiúscula.',
                          lowercase:
                            'A senha deve conter ao menos uma letra minúscula.',
                          match: 'As senhas coincidem.',
                        }}
                        minLength={6}
                        valueAgain={
                          watch('password_confirmation')
                            ? watch('password_confirmation')
                            : ''
                        }
                      />
                    ) : undefined}
                    <div className="flex items-center flex-col lg:flex-row gap-[30px] justify-center w-full">
                      <Button
                        text="VOLTAR"
                        variant="bg-[#28CEE3] text-[#031E78] text-[18px] w-full lg:w-[220px]"
                        type="button"
                        onClick={() => navigate('/')}
                      />
                      <Button
                        text="PROSSEGUIR"
                        variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full lg:w-[220px]"
                        type="submit"
                      />
                    </div>
                  </div>
                </section>
              </form>
            </LoginTemplate>
          )}
        </>
      )}
    </>
  );
};

export default EditProfile;
