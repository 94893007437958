import { useContext, useEffect } from 'react';
import Client from '../../../../Backend/Client';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../../Shared/LoadingContext';

const Logout = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const handleLogout = async () => {
    try {
      await Client.get('/incentive/logout');
      navigate('/entrar');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('name');
      setLoading(false);
    } catch (error) {
      navigate('/entrar');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('name');
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <h1>Carregando...</h1>;
};

export default Logout;
