import { useState } from 'react';
import InputMask from 'react-input-mask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../Input/InputStyles.scss';

import disabledIcon from '../../Resources/Media/Common/disabled-icon.svg';

const InputText = (props) => {
  const [hideInput, setHideInput] = useState('password');

  const handleChangeTypeInputPassword = () => {
    if (hideInput === 'text') {
      setHideInput('password');
    } else {
      setHideInput('text');
    }
  };

  return (
    <>
      <div
        className={`${
          props.errors ? (props.yellowError ? 'yellow-error' : 'error') : ''
        } 
         inputs-container ${props.classInputContainer}`}
      >
        <label
          className={`px-5 py-1 font-bold ${props.styleLabel} ${
            props.disabled ? '!text-[#596aa6]' : ''
          } lg:!text-[20px]`}
        >
          {props.label}
        </label>
        <div
          style={{ border: '1px solid #1c2ccb' }}
          className={`${props.errors ? 'error' : ''} ${
            props.disabled ? 'disabled' : ''
          } input-field md:h-10 xl:h-14`}
        >
          <InputMask
            {...props}
            placeholder={props.placeholder}
            type={props.inputPassword ? hideInput : 'text'}
            mask={props.mask}
            maskChar={null}
            maxLength={props.maxLength}
            className={props.disabled ? 'input-mask-disabled' : ''}
          />
          {!!props.inputPassword && (
            <div
              onClick={handleChangeTypeInputPassword}
              className="btn-eye-pass"
            >
              {hideInput === 'password' ? <FaEyeSlash /> : <FaEye />}
            </div>
          )}
          {props.disabled && (
            <img src={disabledIcon} alt="" className="mr-[17px]" />
          )}
        </div>
        {props.errors && (
          <div className="errors-message">
            {/* <img src={RightCornerElement} alt="" /> */}
            <p className={`${props.yellowError ? 'yellow-error' : 'error'}`}>
              {props.errors.message}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputText;
