export function formatResponse(response) {
  if (response?.response?.status === 401) {
    window.location.href = '/logout';
  }
  if (response.status === 200) {
    return { success: true, code: 200, data: response.data };
  } else {
    return {
      success: false,
      code: response.status,
      data: response.response.data.errors,
    };
  }
}

export function formatResponseCheckCnpj(response) {
  if (response.status === 200) {
    return { success: true, code: 200, data: response.data };
  } else {
    return {
      success: false,
      code: response.response.status,
      data: response.response.data,
    };
  }
}

export function formatResponseLogin(response) {
  if (response.status === 200) {
    return { success: true, code: 200, data: response.data };
  } else {
    return {
      success: false,
      code: response.response.status,
      data: response.response.data,
    };
  }
}

export function formatResponseExpires(response) {
  if (response.status === 200) {
    return { success: true, code: 200, data: response.data };
  } else {
    return {
      success: false,
      code: response.status,
      data: response.response.data.errors,
    };
  }
}