import './FooterStyles.scss';
import Selo from '../../Resources/Media/Common/Logos/logo-footer.png';
// import Facebook from '../../Resources/Media/Incentive/Icons/facebook.svg';
// import Instagram from '../../Resources/Media/Incentive/Icons/instagram.svg';
// import Youtube from '../../Resources/Media/Incentive/Icons/youtube.svg';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { LoadingContext } from '../../Shared/LoadingContext';

const Footer = () => {
  const location = useLocation();
  const { setLoading } = useContext(LoadingContext);
  const isLogged = !!localStorage.getItem('isLogged');
  return (
    <footer className="footer relative lg:mt-10">
      <div className="flex absolute items-center justify-center mt-6 footer__logo w-[81px] h-[78px] lg:w-[118px] lg:h-[114px] lg:top-[-50px]">
        <img src={Selo} alt="" className="w-[60px] lg:w-[95px]" />
      </div>
      <section
        className={`footer__links w-full  flex items-center justify-center flex-col pt-[65px] ${
          isLogged ? 'lg:pt-[123px]' : 'lg:pt-[100px]'
        }`}
      >
        {/* <div className="w-full flex items-center justify-center relative lg:hidden">
          
        </div> */}

        {/* <div className="w-full flex items-center justify-center mt-[70px]">
          <div className="footer__links__divider" />
        </div> */}

        {isLogged && (
          <div className="flex flex-col lg:flex-row text-center w-[320px] gap-[30px] lg:w-[max-content] lg:pt-[23px] lg:pb-[23px] lg:items-center lg:justify-center footer__links__container mb-[15px]">
            <Link
              to="/"
              onClick={() => {
                if (location.pathname !== '/') {
                  setLoading(true);
                }
              }}
            >
              HOME
            </Link>
            <Link
              to="/como-pontuar"
              onClick={() => {
                if (location.pathname !== '/como-pontuar') {
                  setLoading(true);
                }
              }}
            >
              COMO PONTUAR
            </Link>
            <Link
              to="/aceleradores"
              onClick={() => {
                if (location.pathname !== '/aceleradores') {
                  setLoading(true);
                }
              }}
            >
              ACELERADORES
            </Link>
            <Link
              to="/resultados"
              onClick={() => {
                if (location.pathname !== '/resultados') {
                  setLoading(true);
                }
              }}
            >
              RESULTADOS
            </Link>
            <Link
              to="/premiacoes"
              onClick={() => {
                if (location.pathname !== '/premiacoes') {
                  setLoading(true);
                }
              }}
            >
              PREMIAÇÕES
            </Link>
            <Link
              to="/blog"
              onClick={() => {
                if (location.pathname !== '/blog') {
                  setLoading(true);
                }
              }}
            >
              BLOG
            </Link>
            <Link
              to="/fale-conosco"
              onClick={() => {
                if (location.pathname !== '/fale-conosco') {
                  setLoading(true);
                }
              }}
            >
              FALE CONOSCO
            </Link>
            <Link
              to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Neopharma_Excelencia.pdf`}
            >
              REGULAMENTO
            </Link>
          </div>
        )}

        <div
          className={`flex flex-col gap-2 mb-[20px] ${
            isLogged ? '' : 'pt-[25px]'
          }  lg:pt-[45px] w-[80%] border-t ${
            isLogged ? '' : 'border-t-gray-300'
          } lg:border-none`}
          // style={{ borderTop: '1px solid #bcbcbc' }}
        >
          <span>
            Importante: Esta é uma plataforma da Neo Química destinada{' '}
            <br className="lg:hidden" />a profissionais de saúde que trabalham
            em farmácias.
          </span>

          <span>© Copyright Neo Química</span>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
