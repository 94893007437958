import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetResults = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/resultados');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const GetResultsTable = (period) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        `/incentive/resultados/periodo/${period}`
      );
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const ChangeCurrentStore = (uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get(`/incentive/resultados/store/${uuid}`);
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
