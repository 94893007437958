import Footer from '../../../../Components/Footer';
import Header from '../../../../Components/Header';

const DefaultTemplate = (props) => {
  return (
    <main className="pt-[60px] sm:pt-0">
      <Header />
      {props.children}
      <Footer />
    </main>
  );
};

export default DefaultTemplate;
