import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string().required('Esta informação é necessária'),
  birth: Yup.string().required('Esta informação é necessária.'),
  sex: Yup.string().required('Esta informação é necessária.'),
  mobile: Yup.string().required('Esta informação é necessária.'),
  email: Yup.string().required('Esta informação é necessária.'),
  email_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('email')], 'Os e-mails não correspondem'),
  cnpj: Yup.array().of(Yup.string().required('Esta informação é necessária.')),
  corporate_name: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  company: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  zip: Yup.array().of(Yup.string().required('Esta informação é necessária.')),
  address: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  number: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),

  district: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  city: Yup.array().of(Yup.string().required('Esta informação é necessária.')),
  manager_name: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  manager_email: Yup.array().of(
    Yup.string().required('Esta informação é necessária.')
  ),
  optin_rules: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf(
      [true],
      'Aceite o regulamento e receber informações do Neo Pharma Excelência para prosseguir.'
    ),
  optin_privacy: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf(
      [true],
      'Aceite os termos de políticas de privacidade para prosseguir.'
    ),
  optin_commercial: Yup.boolean()
    .required('Esta informação é necessária.')
    .oneOf([true], 'É necessário confirmar para prosseguir.'),
});
