import './InputCheckboxStyles.scss';
// import RightCornerElement from '../../Resources/Media/Incentive/Icons/triangle.png';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import {
  NoChecked,
  Checked,
} from '../../Resources/Media/Incentive/Icons/CheckIconsOptins';

const InputCheckbox = (props) => {
  return (
    <div className={`${props.errors ? 'error' : ''} container-input-checkbox`}>
      <div>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              onChange={(_, newValue) => {
                if (props.onChange) {
                  props.onChange();
                }

                field.onChange(newValue);
              }}
              value={field.value}
              icon={<NoChecked error={props.errors} />}
              checkedIcon={<Checked />}
              sx={{ paddingRight: '8px !important', height: '14px' }}
            />
          )}
        />
        <label
          className={`description-label text-[20px] ${props.styleLabel}`}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      </div>
      {props.errors && (
        <div className="errors-message">
          {/* <img src={RightCornerElement} alt="" /> */}
          <p className="error"> {props.errors.message}</p>
        </div>
      )}
    </div>
  );
};

export default InputCheckbox;
