export const scrollToFirstError = ({ hasHeader, errorIsYellow }) => {
  const headerHeight = hasHeader === true ? 92 : 0;
  const firstErrorField = errorIsYellow
    ? document.querySelector('.yellow-error')
    : document.querySelector('.error');

  if (firstErrorField) {
    const errorFieldPosition = firstErrorField?.getBoundingClientRect()?.top;
    const scrollPosition = errorFieldPosition - headerHeight;

    window.scrollBy({
      top: scrollPosition,
      behavior: 'smooth',
    });
  }
};
