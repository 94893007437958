/* eslint-disable react-hooks/exhaustive-deps */
import './RegisterPharmaDataStyles.scss';
import step1PersonalDataMobile from '../../../../../Resources/Media/Incentive/Pages/Register/step2-personal-data-mobile.svg';
import { Controller } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import InputText from '../../../../../Components/InputText';
import Button from '../../../../../Components/Button';
import useValidationRegisterPharmaData from './useValidationsRegisterPharmaData';
import { getUfAndCityInArrayPharma } from '../../../../../Backend/getUfAndCity';
import { useLocation } from 'react-router-dom';
import { scrollToFirstError } from '../../../../Utils/ScrollToErrors';

const ConfirmPharmaData = ({
  control,
  errors,
  setStep,
  watch,
  setError,
  clearErrors,
  setValue,
  storeData,
  isLastPharma,
  isFirstPharma,
  index,
  setPharmaIndex,
  setArrayStores,
  arrayStores,
  step,
}) => {
  const location = useLocation();

  const userStores = location.state.stores;
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const inputEmailManager = watch(`manager_email.${index}`);
  const zipValue = watch(`zip.${index}`)
    ? watch(`zip.${index}`).replace(/-/, '')
    : '';

  const recaptchaRef = useRef(null);

  const {
    checkIfEmailIsValid,
    handleChangeStep,
    handleReturnStep,
    handleReturnPharma,
  } = useValidationRegisterPharmaData({
    setArrayStores,
    inputEmailManager,
    setError,
    clearErrors,
    watch,
    setStep,
    errors,
    isLastPharma,
    isFirstPharma,
    setPharmaIndex,
    index,
    arrayStores,
    setScrollToErrorUpdate,
    scrollToErrorUpdate,
  });

  const checkIfNameHasSurname = () => {
    const inputName = watch(`manager_name.${index}`);

    if (!!inputName?.split(' ')[1]) {
      clearErrors(`manager_name.${index}`);
    } else {
      setError(`manager_name.${index}`, {
        type: 'manual',
        message: 'Digite o nome completo.',
      });
    }
  };
  const changeStep = () => {
    handleChangeStep(recaptchaRef);
  };

  useEffect(() => {
    setValue(`cnpj.${index}`, storeData?.cnpj);
    setValue(`corporate_name.${index}`, storeData?.corporate_name);
    setValue(`company.${index}`, storeData?.company);
    setValue(`zip.${index}`, storeData?.zip);
    setValue(`address.${index}`, storeData?.address);
    setValue(`number.${index}`, storeData?.number);
    setValue(`comp.${index}`, storeData?.comp);
    setValue(`district.${index}`, storeData?.district);
    setValue(`city.${index}`, storeData?.city);
    setValue(
      `manager_name.${index}`,
      storeData?.contact ? storeData?.contact : location.state.user.name
    );
    setValue(
      `manager_email.${index}`,
      storeData?.email ? storeData?.email : location.state.user.email
    );
    setValue(`uuid.${index}`, storeData?.uuid);
  }, []);

  useEffect(() => {
    const currentStoreInStepSavedToPayload = arrayStores?.find(
      (storeSavedToPayload) =>
        storeSavedToPayload?.uuid === watch(`uuid.${index}`)
    );
    if (!!currentStoreInStepSavedToPayload) {
      setValue(`cnpj.${index}`, currentStoreInStepSavedToPayload?.cnpj);
      setValue(
        `corporate_name.${index}`,
        currentStoreInStepSavedToPayload?.corporate_name
      );
      setValue(`company.${index}`, currentStoreInStepSavedToPayload?.company);
      setValue(`zip.${index}`, currentStoreInStepSavedToPayload?.zip);
      setValue(`address.${index}`, currentStoreInStepSavedToPayload?.address);
      setValue(`number.${index}`, currentStoreInStepSavedToPayload?.number);
      setValue(`comp.${index}`, currentStoreInStepSavedToPayload?.comp);
      setValue(`district.${index}`, currentStoreInStepSavedToPayload?.district);
      setValue(`city.${index}`, currentStoreInStepSavedToPayload?.city);
      setValue(
        `manager_name.${index}`,
        currentStoreInStepSavedToPayload?.manager_name
      );
      setValue(
        `manager_email.${index}`,
        currentStoreInStepSavedToPayload?.manager_email
      );
      setValue(`uuid.${index}`, currentStoreInStepSavedToPayload?.uuid);
    }
  }, [step, index]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true, errorIsYellow: false });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <section
      className="register-pharma-data w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[108px] lg:!pl-[150px] lg:!pr-[150px]"
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      <img src={step1PersonalDataMobile} alt="" />

      <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] pb-[10px] pt-[10px] rounded-[50px] lg:w-[765px] pl-[30px] pr-[30px] lg:pl-[45px] lg:pr-[45px] flex flex-col items-center justify-center mt-[25px]">
        <h1 className="font-semibold">
          PREENCHA <br className="lg:hidden" />
          AS INFORMAÇÕES <br className="lg:hidden" />
          {`${userStores.length > 1 ? 'DE SUAS FARMÁCIAS' : 'DA SUA FARMÁCIA'}`}
        </h1>
      </div>

      {userStores.length > 1 && (
        <div className="bg-[#031E78] text-white rounded-[50px] pl-[30px] pr-[30px] pb-[8px] pt-[8px] mt-5">
          FARMÁCIA {index + 1} DE {userStores.length}
        </div>
      )}

      <div className="w-full flex flex-col gap-[20px] mt-[20px]">
        <Controller
          control={control}
          name={`cnpj.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                disabled
                label="CNPJ"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('cnpj');
                }}
                inputMode="numeric"
                value={value}
                mask="99.999.999/9999-99"
                placeholder="00.000.000/0000-00"
                onBlur={() => {}}
                errors={errors?.cpf?.[index]}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={`corporate_name.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Razão social"
                styleLabel="!text-[#031E78]"
                placeholder="Razão social"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('corporate_name');
                }}
                value={value}
                errors={errors?.corporate_name?.[index]}
              />
            );
          }}
        />

        <Controller
          control={control}
          name={`company.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Nome fantasia"
                styleLabel="!text-[#031E78]"
                placeholder="Nome fantasia"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('company');
                }}
                value={value}
                errors={errors?.company?.[index]}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={`zip.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="CEP"
                styleLabel="!text-[#031E78]"
                placeholder="CEP"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors(`zip.${index}`);
                }}
                inputMode="numeric"
                onBlur={() => {
                  if (zipValue && zipValue.length === 8) {
                    getUfAndCityInArrayPharma({
                      zipValue,
                      setValue,
                      index,
                      clearErrors,
                    });
                  }
                }}
                value={value}
                mask="99999-999"
                errors={errors?.zip?.[index]}
              />
            );
          }}
        />

        <Controller
          control={control}
          name={`address.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label="Endereço"
                placeholder="Endereço"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors('address');
                }}
                value={value}
                errors={errors?.address?.[index]}
              />
            );
          }}
        />
        <div className="flex flex-col lg:flex-row gap-[18px]">
          <Controller
            control={control}
            name={`number.${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Número"
                  placeholder="Número"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('number');
                  }}
                  inputMode="numeric"
                  value={value}
                  errors={errors?.number?.[index]}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={`comp.${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Complemento"
                  placeholder="Complemento"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('comp');
                  }}
                  value={value}
                  errors={errors?.comp?.[index]}
                />
              );
            }}
          />
        </div>

        <div className="flex flex-col lg:flex-row gap-[18px]">
          <Controller
            control={control}
            name={`district.${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Bairro"
                  placeholder="Bairro"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('district');
                  }}
                  value={value}
                  errors={errors?.district?.[index]}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={`city.${index}`}
            render={({ field: { onChange, value } }) => {
              return (
                <InputText
                  label="Cidade"
                  placeholder="Cidade"
                  styleLabel="!text-[#031E78]"
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors('city');
                  }}
                  value={value}
                  errors={errors?.city?.[index]}
                />
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name={`manager_name.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label={
                  <span>
                    Nome do responsável <br className="lg:hidden" />
                    para receber comunicações
                  </span>
                }
                onBlur={() => checkIfNameHasSurname()}
                placeholder="Nome do responsável"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors(`manager_name.${index}`);
                }}
                value={value ? value : ''}
                errors={errors?.manager_name?.[index]}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={`manager_email.${index}`}
          render={({ field: { onChange, value } }) => {
            return (
              <InputText
                label={
                  <span>
                    Email do responsável <br className="lg:hidden" />
                    para receber comunicações
                  </span>
                }
                placeholder="Email do responsável"
                styleLabel="!text-[#031E78]"
                onChange={(e) => {
                  onChange(e.target.value.trim());
                  clearErrors('manager_email');
                }}
                onBlur={() => checkIfEmailIsValid()}
                value={value ? value : ''}
                errors={errors?.manager_email?.[index]}
              />
            );
          }}
        />

        <div className="flex items-center flex-col lg:flex-row justify-center w-full gap-[18px] lg:gap-[28px]">
          <Button
            text="VOLTAR"
            variant="bg-[#28CEE3] text-[#031E78] w-full  lg:w-[220px]"
            type="button"
            onClick={() => {
              isFirstPharma
                ? handleReturnStep(1)
                : handleReturnPharma(index - 1);
            }}
          />
          <Button
            text="PROSSEGUIR"
            variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full  lg:w-[220px]"
            type="button"
            onClick={() => changeStep()}
          />
        </div>
      </div>
      <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
        Restrito a farmácias aptas <br className="lg:hidden" />
        a participar do programa. <br />
        Os dados cadastrados <br className="lg:hidden" />
        devem ser de acordo <br className="lg:hidden" />
        com <br className="hidden lg:block" /> o estabelecimento{' '}
        <br className="lg:hidden" />e seus responsáveis.
      </div>
    </section>
  );
};

export default ConfirmPharmaData;
