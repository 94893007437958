import axios from 'axios';

const Client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
});

Client.defaults.withCredentials = true;
Client.get('/sanctum/csrf-cookie').then((r) => {});

export default Client;
