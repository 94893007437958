import Woman from '../../../Media/Incentive/Popup/woman.png';

import Button from '../../../../Components/Button';
import { useContext, useEffect } from 'react';
import { PopupContext } from '../../../../Shared/PopupContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CloseIcon from '@mui/icons-material/Close';

const Popup = () => {
  const { popupData, setPopupData } = useContext(PopupContext);

  useEffect(() => {
    if (popupData.status === true) {
      AOS.init();
    }
  }, [popupData]);

  return (
    <>
    {popupData.status ? (
      <div
      className={`popup  w-[100vw] h-[100vh]  items-center flex-col justify-center fixed z-[99999] ${
        popupData.status ? 'flex popup-open' : 'hidden'
      } transition-all duration-300`}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClick={() =>
        setPopupData((prev) => {
          return { ...prev, status: false };
        })
      }      
    >
      <div
        data-aos="fade-down"
        data-aos-duration="1000"
        className="px-[15px] relative"
      >
        <button
          className="absolute right-[40px] top-[20px] md:right-[30px] md:top-[10px]"
          onClick={() =>
            setPopupData((prev) => {
              return { ...prev, status: false };
            })
          }
        >
          <CloseIcon />
        </button>
        <div className="pt-[45px] md:pt-[20px] pb-[15px] px-[38px] rounded-tl-[24px] w-full rounded-tr-[24px] bg-white flex items-center justify-center flex-col md:flex-row md:gap-[24px]">
          <img src={Woman} alt="" className="w-[164px] md:w-[244px]" />
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-[24px] text-[red] font-[600] mt-[24px] md:mt-0">
              PONTOS A EXPIRAR!
            </h1>
            <p className="text-[20px] text-center font-[500] text-[#031e78] mt-[25px]">
              Você possui <span className="text-[red]">{popupData.value}</span>{' '}
              pontos disponíveis <br className="hidden md:block" /> para
              resgate. Caso não os utilize, eles{' '}
              <br className="hidden md:block" /> serão{' '}
              <span className="text-[red]">expirados!</span>
            </p>
            <p className="mt-[30px] text-[20px] text-center font-[500] text-[#031e78]">
              Acesse a Loja Virtual até{' '}
              <span className="text-[red]">{popupData.date}</span> e{' '}
              <br className="hidden md:block" />
              aproveite!
            </p>
            <Button
              variant="md:mt-8 md-mb-[10px] mt-[30px] bg-[#ffd800] w-[352px] text-[#031e78] hover:text-[#ffd800] hover:border hover:border-[#ffd800] transition-all duration-300 !font-[500]"
              text="RESGATAR AGORA"
              onClick={() => window.open('/resgatar-premios', '_blank')}
            />
          </div>
        </div>
        <div className="h-[58px] w-full bg-[#18cee3] rounded-bl-[24px] rounded-br-[24px]" />
      </div>
    </div>
    ) : undefined
    }
    </>
  );
};

export default Popup;
