import Button from '../../../../../Components/Button';
import EmailSvg from '../../../../Media/Incentive/Pages/LoginCreatePassword/email.svg';
import './CreatePasswordMoreThanOneEmailStyles.scss';
import { useContext, useRef, useState } from 'react';
import { Checkbox } from '@mui/material';
import {
  NoChecked,
  Checked,
} from '../../../../Media/Incentive/Icons/CheckIcons';
import { useLocation } from 'react-router-dom';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import { SendPasswordFirstLogin } from '../../../../../Backend/firstLogin';
import ReCAPTCHA from 'react-google-recaptcha';

const CreatePasswordMoreThanOneEmail = ({ setStep }) => {
  const [selectedEmailUuidStore, setSelectedEmailUuidStore] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const { setLoading } = useContext(LoadingContext);

  const location = useLocation();

  const stores = location.state.stores;
  const userId = location.state.user;

  const recaptchaRef = useRef(null);

  const verifyResponse = (response) => {
    if (response.success) {
      setStep(4);
      setLoading(false);
    } else {
      setErrorMessage(
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.'
      );
      setLoading(false);
    }
  };

  const handleSubmit = async (recaptchaToken) => {
    setLoading(true);

    const response = await SendPasswordFirstLogin({
      user: userId,
      store: selectedEmailUuidStore,
      recaptchaToken,
    });

    verifyResponse(response);
  };

  const handleCustomSubmit = () => {
    if (selectedEmailUuidStore) {
      recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
        setLoading(true);
        recaptchaRef.current.reset();
        handleSubmit(recaptchaToken);
      });
    } else {
      setErrorMessage('Esta informação é necessária.');
    }
  };

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />

      <section className="create-password-more-than-one-email w-[320px] rounded-[10px] flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] mt-[30px] mb-[60px]">
        <img src={EmailSvg} alt="" />
        <h1 className="text-[24px] text-center mt-[15px] mb-[19px] leading-[1.2]">
          SELECIONE O<br />
          E-MAIL PARA CRIAR
          <br />A NOVA SENHA
        </h1>
        <p className="text-[18px] text-center leading-[1.1] mb-[20px]">
          Existe mais de uma farmácia
          <br />
          vinculada ao seu CPF.
          <br />
          <br />
          Selecione o e-mail para o qual
          <br />
          você deseja receber o link de
          <br />
          criação de nova senha.
        </p>

        <div className="bg-white pt-[15px] pb-[15px] pl-[30px] pr-[30px] rounded-[30px] w-[100%] flex flex-col gap-[10px] mb-[22px]">
          {stores.map((item) => {
            return (
              <div className="flex items-start">
                <Checkbox
                  onChange={() => setSelectedEmailUuidStore(item.uuid)}
                  checked={item.uuid === selectedEmailUuidStore}
                  icon={<NoChecked />}
                  value={item.uuid}
                  checkedIcon={<Checked />}
                  sx={{ padding: 0, marginRight: 1, marginTop: '2px' }}
                />
                <label
                  className="text-[16px] leading-[1] break-all text-[#031C7A]"
                  onClick={() => setSelectedEmailUuidStore(item.id)}
                ></label>
                <div className="flex flex-col w-full">
                  <p className="leading-[1] !text-[#031C7A]">
                    {item.email?.split('@')[0]}
                  </p>
                  <p className="leading-[1] !text-[#031C7A]">
                    @{item.email?.split('@')[1]}
                  </p>
                </div>
              </div>
            );
          })}
          {errorMessage && (
            <p className="m-0 !text-[#cc0000]">{errorMessage}</p>
          )}
        </div>

        <Button
          variant="bg-[#F7D64C] w-full text-[#031C7A]"
          text="ENVIAR"
          type="button"
          onClick={() => handleCustomSubmit()}
        />
      </section>
    </>
  );
};

export default CreatePasswordMoreThanOneEmail;
