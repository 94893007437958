import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import RegisterPessoalData from './Steps/RegisterPessoalData';
import RegisterPharmaData from './Steps/RegisterPharmaData';
import RegisterOptins from './Steps/RegisterOptins';

import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import ReCAPTCHA from 'react-google-recaptcha';
import useHandlers from './useHandlers';
import { scrollToFirstError } from '../../../../../Utils/ScrollToErrors';

const CorrectCpfAndCnpj = ({ cpf, cnpj, setStep }) => {
  const [stepRegister, setStepRegister] = useState(1);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const recaptchaRef = useRef(null);

  const { handleCustomSubmit } = useHandlers({
    setError,
    recaptchaRef,
    setStep,
    setStepRegister,
    setScrollToErrorUpdate,
    scrollToErrorUpdate,
  });

  useEffect(() => {
    setValue('cpf', cpf);
    setValue('cnpj', cnpj);
  }, [cnpj, cpf, setValue]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [stepRegister]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <form onSubmit={handleSubmit(handleCustomSubmit)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      {stepRegister === 1 && (
        <RegisterPessoalData
          control={control}
          setStepRegister={setStepRegister}
          watch={watch}
          setError={setError}
          errors={errors}
          clearErrors={clearErrors}
          cpf={cpf}
          cnpj={cnpj}
          scrollToErrorUpdate={scrollToErrorUpdate}
          setScrollToErrorUpdate={setScrollToErrorUpdate}
        />
      )}
      {stepRegister === 2 && (
        <RegisterPharmaData
          control={control}
          setStepRegister={setStepRegister}
          watch={watch}
          setError={setError}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          scrollToErrorUpdate={scrollToErrorUpdate}
          setScrollToErrorUpdate={setScrollToErrorUpdate}
        />
      )}
      {stepRegister === 3 && (
        <RegisterOptins
          control={control}
          setStepRegister={setStepRegister}
          watch={watch}
          setError={setError}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          scrollToErrorUpdate={scrollToErrorUpdate}
          setScrollToErrorUpdate={setScrollToErrorUpdate}
        />
      )}
    </form>
  );
};

export default CorrectCpfAndCnpj;
