import './LoginTemplateStyles.scss';
import Footer from '../../../../Components/Footer';
import LogoHeaderMobile from '../../../Media/Common/Logos/logo-header.png';
import LogoVariation2 from '../../../Media/Common/Logos/LoginTemplate/logo-variation-2.svg';
import Header from '../../../../Components/Header';

export const LoginTemplate = (props) => {
  const isLogged = localStorage.getItem('isLogged');
  return (
    <main
      className={`flex flex-col items-center min-h-[100vh] justify-between lg:relative`}
    >
      {isLogged && <Header />}
      <div className="relative w-full flex items-center justify-center ">
        {!isLogged && (
          <img
            src={LogoHeaderMobile}
            alt=""
            className={`mt-[-45px] w-[120px] h-[167px] lg:w-[179px] lg:h-[235px] lg:mt-[-70px] ${
              props.variation === 'variation-2' ? 'lg:hidden' : ''
            }`}
          />
        )}

        {props.variation === 'variation-2' && (
          <div className="lg:!w-[220px] lg:!h-[410px] absolute bg-[#28CEE3] rounded-[110px] !bottom-[-150px] left-[50px] hidden lg:block" />
        )}

        {props.variation === 'variation-3' && (
          <div className="lg:!w-[220px] lg:!h-[410px] absolute bg-[#28CEE3] rounded-[110px] !bottom-[-0px] left-[-550px] hidden lg:block" />
        )}

        <div
          className={`first-asset absolute top-[10px] lg:top-[-106px] w-[83px] lg:!w-[400px] h-[63px] lg:!h-[202px] right-[0] rounded-tl-[101px] rounded-bl-[101px] z-[-2] lg:z-[-1]`}
        />
        <div
          className={`second-asset absolute top-[37px] w-[63px] lg:!w-[300px] h-[63px] lg:!h-[202px] right-[0] rounded-tl-[101px] rounded-bl-[101px] top-[40px] z-[-1] lg:z-[-2]`}
        />
      </div>

      {props.variation === 'variation-1' && (
        <>
          <div className="fourth-asset bg-[#ffd800] absolute hidden lg:block lg:!w-[300px] lg:!h-[202px] left-0 lg:!top-[28%] rounded-tr-[101px] rounded-br-[101px]">
            <div className="fifth-asset bg-[#28cee3] absolute hidden lg:block lg:!w-[400px] lg:!h-[202px] top-[48%] rounded-tr-[101px] rounded-br-[101px]" />
          </div>
          <div className="relative flex variation-1 right-[0]">
            {props.children}
          </div>
        </>
      )}

      {props.variation === 'variation-2' && (
        <div
          className={`relative flex lg:max-w-[1110px] lg:w-[88%] variation-2 justify-end h-[100%] items-center lg:mt-[200px] lg:mb-[105px] ${
            isLogged ? 'mt-[50px]' : ''
          }`}
        >
          <div className="fifth-asset absolute hidden lg:block lg:!w-[1294px] lg:!h-[96px] top-[384px] lg:!right-[65%] rounded-tr-[101px] rounded-br-[101px]">
            <div className="fourth-asset absolute hidden lg:flex lg:!w-[1294px] lg:!h-[386px] left-[285px] top-[-350px] !rounded-[257px] pr-[170px] justify-end">
              <img src={LogoVariation2} alt="" width="280" height="386" />
            </div>
          </div>

          {props.children}
        </div>
      )}

      {props.variation === 'variation-3' && <>{props.children}</>}

      <div className="relative w-full h-[1px]">
        <div className="third-asset lg:!w-[220px] lg:!h-[316px]" />
      </div>

      <div className="relative w-full lg:flex lg:flex-col lg:items-center">
        <Footer />
      </div>
    </main>
  );
};
