import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import RegisterNewPharmaSuccessSvg from '../../../Media/Incentive/Icons/checked-success.svg';
import './RegisterNewPharmaSuccessStyles.scss';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import Init from "../../../Utils/Initializer";

const RegisterNewPharmaSuccess = ({ setStep }) => {
  Init();
  const navigate = useNavigate();
  return (
    <LoginTemplate variation="variation-2">
      <section className="recover-password-success w-[320px] h-[520px] rounded-[10px]  flex flex-col items-center justify-center pt-[29px] pr-[17px] pl-[17px] pb-[27px] ">
        <img src={RegisterNewPharmaSuccessSvg} alt="" />
        <h1 className="text-[24px] text-center mt-[15px] mb-[5px] leading-[1.2]">
          FARMÁCIA CADASTRADA <br />
          COM SUCESSO
        </h1>
        <p className="text-[18px] text-center leading-[1.2] mb-[20px]">
          <span>Parabéns!</span> Agora você tem <br />
          <span>
            mais um ponto de venda
            <br /> associado
          </span>{' '}
          ao programa
          <br />
          Neo Pharma Excelência.
          <br />
          <br />
          <span>Aproveite</span> para ter <br /> mais{' '}
          <span>
            conquistas com
            <br /> nossa parceria.
          </span>
          <br />
        </p>

        <div className="flex items-center flex-col justify-center w-full gap-[14px]">
          <Button
            variant="bg-[#F7D64C] w-full text-[#031C7A]"
            text="ADICIONAR NOVA FARMÁCIA"
            type="button"
            onClick={() => setStep(0)}
          />

          <Button
            variant="bg-[#28CEE3] w-full text-[#031C7A]"
            text="GERENCIAR FARMÁCIAS"
            type="button"
            onClick={() => navigate('/gerenciar-farmacias')}
          />
        </div>
      </section>
    </LoginTemplate>
  );
};

export default RegisterNewPharmaSuccess;
