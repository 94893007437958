import Client from './Client';
import { formatResponse, formatResponseLogin } from './formatResponse';

import qs from 'qs';

export async function Login(login, password, recaptchaToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        '/incentive/login',
        qs.stringify({
          login,
          password,
          'g-recaptcha-response': recaptchaToken,
        })
      );
      resolve(formatResponseLogin(response));
    } catch (error) {
      resolve(formatResponseLogin(error));
    }
  });
}

export async function Profile() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/profile');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function Logout() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/logout');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function IsLogged() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/auth');
      resolve(formatResponse(response));
    } catch (error) {
      localStorage.removeItem('name');
      localStorage.removeItem('isLogged');
      window.location.href = '/entrar';
      return { success: false };
    }
  });
}

export async function GetUserCpf() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/user', {
        param: 'return_only_cpf',
      });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
