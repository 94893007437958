const PageView = function() {
  window.dataLayer?.push({
    event: 'page_view',
    page: {
      location:
        document.location.origin +
        document.location.pathname +
        document.location.search,
      title: document.title,
    }
  });
}

export default PageView;
