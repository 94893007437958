import './ButtonStyles.scss';

const Button = (props) => {
  return (
    <>
      <button
        {...props}
        className={`${props.variant} bg-[#04287F] hover:bg-white button flex justify-center items-center rounded-full font-semibold !text-[18px]'`}
      >
        {props.text}
      </button>
    </>
  );
};
export default Button;
