import React, { useState, useEffect } from 'react';

import popupBlackFriday from '../../Resources/Media/Incentive/Popup/nq_np_excelencia_bf_popup.jpg';

const PopupBlackFriday = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  const popupViewed = localStorage.getItem('popupViewed');

  useEffect(() => {
    
    if (!popupViewed) {
      setIsVisible(true);
      setTimeout(() => {
        localStorage.setItem('popupViewed', 'true');
      }, 2000);
    } else {
      setIsVisible(false);
    }
  }, [popupViewed]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99999]">
      <div onClick={handleClose} className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 rounded">
        <a href="https://parceirohypera.com.br/" target="_blank" rel="noreferrer">
          <img src={popupBlackFriday} alt="Black Friday Sale" className="mx-auto" />
        </a>
        <button onClick={handleClose} className="mt-4 block mx-auto bg-[#FFFFFF] text-[#001C7A] text-[13px] w-[30px] h-[30px] rounded-full absolute top-[20px] right-[20px]">
          X
        </button>
      </div>
    </div>
  );
};

export default PopupBlackFriday;
