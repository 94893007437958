import './CnpjNotEligibleStyles.scss';
import phoneSvg from '../../../../../Media/Incentive/Icons/phone.png';
import whatsappIcon from '../../../../../Media/Incentive/Icons/whatsapp.svg';
import { LoginTemplate } from '../../../../Templates/LoginTemplate';

const CnpjNotEligible = () => {
  return (
    <LoginTemplate variation="variation-1">
      <section
        className="cnpj-not-eligible w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
    items-center justify-center mb-[60px] mt-[130px] lg:!pl-[100px] lg:!pr-[100px]"
      >
        <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[70px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[20px] lg:pr-[20px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
          <h1 className="font-semibold">
            CNPJ NÃO ELEGÍVEL <br className="lg:hidden" />
            AO PROGRAMA
          </h1>
        </div>
        <p className="text-center text-[20px] text-[#031E78] leading-[1.2] mb-[25px] mt-[20px]">
          Sua farmácia{' '}
          <strong>
            não está apta <br className="lg:hidden" />a participar
          </strong>{' '}
          do Programa <br className="lg:hidden" />
          Neo Pharma Excelência.
          <br className="lg:hidden" />
          <br />
          Em caso de <strong>dúvidas</strong>, entre{' '}
          <br className="lg:hidden" />
          em contato com seu <br className="lg:hidden" />
          <strong>
            representante <br className="hidden lg:block" /> Neo Pharma Excelência {' '}
          </strong>
          <br className="lg:hidden" />
          ou com nossa <strong>central.</strong>
        </p>

        <div className="w-full bg-[#031E78] flex flex-col items-start lg:items-center rounded-[27px] p-[15px] gap-[17px] lg:w-[512px]">
          <h2 className="font-semibold text-[20px] text-[#ffd800]">
            CENTRAL DE ATENDIMENTO
          </h2>
          <div className="flex lg:flex-row flex-col items-start lg:items-center lg:w-full lg:justify-center lg:gap-[25px]">
            <div className="flex items-center justify-center gap-[9px] ml-[38px]">
              <img src={phoneSvg} width="40" />
              <a href="tel:+551142003120" className="text-white text-[20px]">
                (11) 4200-3120
              </a>
            </div>
            <div className="hidden lg:block h-[25px] w-[2px] bg-white" />
            <div className="flex items-center justify-center gap-[9px] ml-[38px] lg:ml-[0]">
              <img src={whatsappIcon} />
              <a
                href="https://wa.me/5541987981042?text=Ol%C3%A1,%20preciso%20falar%20sobre%20Neo%20Pharma%20Excel%C3%AAncia."
                className="text-white text-[20px]"
              >
                (41) 9 8798-1042
              </a>
            </div>
          </div>
        </div>

        <div className="bg-[#28CEE3] text-[18px] w-full lg:w-[488px] text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
          Restrito a farmácias aptas <br className="lg:hidden" />
          a participar do programa. <br />
          Os dados cadastrados <br className="lg:hidden" />
          devem ser de acordo <br className="lg:hidden" />
          com <br className="hidden lg:block" /> o estabelecimento{' '}
          <br className="lg:hidden" />e seus responsáveis.
        </div>
      </section>
    </LoginTemplate>
  );
};

export default CnpjNotEligible;
