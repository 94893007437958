import Client from './Client';
import { formatResponse } from './formatResponse';

export const SendPasswordFirstLogin = ({ user, store, recaptchaToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/email-primeiro-login', {
        user,
        store,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (err) {
      resolve(formatResponse(err));
    }
  });
};

export const SendPasswordFirstLoginV2 = ({ user, recaptchaToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/email-primeiro-login-v2', {
        user,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (err) {
      resolve(formatResponse(err));
    }
  });
};

export const FirstLoginSave = (
  password,
  password_confirmation,
  token,
  recaptchaToken
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/primeiro-acesso-confirm', {
        password,
        password_confirmation,
        token,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const updateDataFirstLogin = (payload, recaptchaToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        '/incentive/atualizar-dados/primeiro-acesso',
        {
          ...payload,
          'g-recaptcha-response': recaptchaToken,
        }
      );

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
