/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './AcceleratorsStyles.scss';

import DefaultTemplate from '../../Templates/DefaultTemplate';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { GetAccelerators } from '../../../../Backend/accelerators';
import TableAccelerators from './Table';
import Init from "../../../Utils/Initializer";

const July = () => {
  Init();
  const navigate = useNavigate();
  const [showScreen, setShowScreen] = useState();
  const [banners, setBanners] = useState();
  const [template, setTemplate] = useState();
  const [lastMonth, setLastMonth] = useState();
  const [month, setMonth] = useState();
  const [acceleratorsList, setAcceleratorsList] = useState();

  const [july] = useState(false);

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  /* prettier-ignore */
  const months = {
    '01': 'JANEIRO',
    '02': 'FEVEREIRO',
    '03': 'MARÇO',
    '04': 'ABRIL',
    '05': 'MAIO',
    '06': 'JUNHO',
    '07': 'JULHO',
    '08': 'AGOSTO',
    '09': 'SETEMBRO',
    '10': 'OUTUBRO',
    '11': 'NOVEMBRO',
    '12': 'DEZEMBRO',
  };

  const GetData = async () => {
    const response = await GetAccelerators();

    if (response.success) {
      setBanners({
        firstBanner: {
          desktop: response.data.files.banner,
          mobile: response.data.files.banner_mobile,
        },
        secondBanner: {
          desktop: response.data.files.product,
          mobile: response.data.files.product_mobile,
        },
        thirdBanner: {
          desktop: response.data.files.secondary,
          mobile: response.data.files.secondary_mobile,
        },
      });

      setTemplate(response.data.template);

      setLastMonth(response.data.lastMonthP);

      getMonth(response.data.lastMonthP.period);

      setAcceleratorsList({
        acceleratorsPrimary: response.data.acceleratorsPrimary,
        acceleratorsSecondary: response.data.acceleratorsSecondary,
      });

      verifyIfIsLogged(setShowScreen);
    } else {
      navigate('/');
    }
  };

  const getMonth = (period) => {
    const numberMonth = String(period).slice(-2);

    setMonth(months[numberMonth]);
  };

  const formatedMultValues = (lastMonthP) => {
    const { multiple, multiple_type } = lastMonthP;

    let value;
    if (multiple_type === 'V') {
      value = `R$${multiple},00`;
    } else if (multiple_type === 'U') {
      value = `${multiple} unidades`;
    }

    return value;
  };

  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="accelerators">
            <div className="accelerators-banner">
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={banners?.firstBanner?.mobile}
                ></source>
                <img
                  className="lg:w-[1140px] block m-auto"
                  src={banners?.firstBanner?.desktop}
                  alt="banner"
                />
              </picture>
            </div>

            <div
              class="accelerators-welcome lg:mt-12 mt-5"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <h1 className="lg:text-left text-center font-semibold leading-tight">
                {template === 2 && (
                  <>
                    AUMENTE SUA <br />
                    PONTUAÇÃO
                  </>
                )}
                {template === 1 && (
                  <>
                    ACELERE SUAS <br />
                    CONQUISTAS
                  </>
                )}
              </h1>

              <h2 className="text-center font-bold lg:w-[475px]">
                {formatedMultValues(lastMonth).toUpperCase()}
                <br />=<br />
                {lastMonth?.point} PONTOS*
                <br />
                {/* <p className="font-light lg:text-[20px] text-[14px] mt-1">
                  *Limitado a 500 pontos.
                </p> */}
              </h2>
            </div>

            <div className="accelerators-list lg:-mt-[9rem] -mt-10">
              <div>
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcSet={banners?.secondBanner?.mobile}
                  ></source>
                  <img
                    src={banners?.secondBanner?.desktop}
                    alt="Produdos Neo Química"
                    className="lg:w-[1088px] w-[318px] mx-auto relative lg:top-[178px] top-[52px]"
                  />
                </picture>
              </div>
              <div className="bg-[#031E78] lg:w-[1088px] w-[320px] mx-auto pb-[3rem]">
                <div className="text-center lg:pt-32 pt-10">
                  <h1 className="text-white lg:text-[30px] text-[20px] font-semibold leading-tight">
                    ACELERADORES DE {month}.
                    <br />
                    {lastMonth?.title}
                  </h1>
                  {template === 1 && (
                    <p className="text-white font-medium lg:text-[20px] text-[14px] leading-tight my-5 lg:w-full w-[280px] mx-auto">
                      Aproveite para alavancar sua pontuação e{' '}
                      <strong className="text-[#FFD800] font-semibold">
                        resgatar prêmios incríveis:{' '}
                      </strong>
                      <br className="hidden lg:block" />a cada{' '}
                      <strong className="text-[#FFD800] font-semibold">
                        compra de {formatedMultValues(lastMonth)} de produtos
                        foco,
                        <br className="lg:hidden" /> você ganha{' '}
                        {lastMonth?.point} pontos.
                      </strong>
                    </p>
                  )}
                  {template === 2 && (
                    <p className="text-white font-medium lg:text-[20px] text-[14px] leading-tight my-5 lg:w-full w-[230px] mx-auto">
                      <strong className="text-[#FFD800] font-semibold">
                        Compre {formatedMultValues(lastMonth)}
                      </strong>{' '}
                      de produtos selecionados e{' '}
                      <strtong className="text-[#FFD800] font-semibold">
                        ganhe {lastMonth?.point} pontos
                      </strtong>
                      {` `}
                      <br className="hidden lg:block" />
                      para resgatar os prêmios que quiser em nossa Loja Virtual.
                    </p>
                  )}
                </div>

                {banners?.thirdBanner?.desktop ? (
                  <picture className="mb-[49px]">
                    <source
                      media="(max-width: 767px)"
                      srcSet={banners?.thirdBanner?.mobile}
                    ></source>
                    <img
                      src={banners?.thirdBanner?.desktop}
                      alt="Produdos Neo Química"
                      className="lg:w-[628px] w-[286px] mx-auto relative "
                    />
                  </picture>
                ) : undefined}

                <div className="lg:w-[904px] w-[286px] mx-auto border-t-2 border-white mt-[49px]">
                  <div className="flex justify-center lg:items-center items-baseline mt-5 pl-[35px]">
                    <TableAccelerators acceleratorsList={acceleratorsList} />
                  </div>
                  <div>
                    <h5 className="lg:text-[20px] text-[18px] text-white font-[400] text-center mt-5 leading-tight">
                      *Limitado a {lastMonth?.point_max} pontos
                    </h5>
                  </div>
                </div>
                <div className="accelerators-maps lg:w-[904px] w-[286px] mx-auto border-t-2 border-white mt-5" />

                {/* <div className="accelerators-maps lg:w-[904px] w-[286px] mx-auto border-t-2 border-white mt-5">
                  <h1 className="text-white lg:text-[22px] text-[18px] font-bold text-center my-5">
                    UFs ACELERADORES JUNHO
                  </h1>

                  <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-10 gap-8">
                    <div>
                      <picture>
                        <img
                          src={mapa}
                          alt="Mapa"
                          className="lg:w-[388px] w-[286px] mx-auto"
                        />
                      </picture>
                    </div>
                    <div className="rounded-[30px] w-[260px] bg-[#FFD800] py-4">
                      <h1 className="text-[#031E78] text-[22px] font-bold text-center">
                        UFs
                      </h1>
                      <div className="flex justify-center items-center gap-10 mt-3">
                        <div>
                          <ul>
                            <li>AC</li>
                            <li>AL</li>
                            <li>CE</li>
                            <li>DF</li>
                            <li>ES</li>
                            <li>GO</li>
                            <li>MA</li>
                            <li>MG</li>
                            <li>MT</li>
                            <li>PA</li>
                          </ul>
                        </div>
                        <div>
                          <ul>
                            <li>PB</li>
                            <li>PE</li>
                            <li>PI</li>
                            <li>PR</li>
                            <li>RJ</li>
                            <li>RS</li>
                            <li>SC</li>
                            <li>SE</li>
                            <li>SP</li>
                            <li>TO</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="my-5 text-center">
                <h1 className="lg:text-[24px] text-[20px] text-[#04287F] font-semibold">
                  A CADA MÊS, UMA INCRÍVEL NOVIDADE PARA TURBINAR SUA PONTUAÇÃO.
                </h1>
                <p className="lg:text-[16px] text-[14px] text-[#04287F] font-medium">
                  Aproveite os aceleradores mensais e conquiste ainda mais
                  pontos no programa para resgatar prêmios de sua escolha!
                </p>

                <div className="lg:w-[720px] py-5 rounded-full bg-[#04287F] mx-auto my-5">
                  <p className="lg:text-[20px] text-[18px] text-[#FFD800] font-medium leading-tight">
                    Fique ligado e participe das campanhas especiais de{' '}
                    <strong className="font-semibold">
                      produtos foco,
                      <br />
                      treinamentos, execução de PDV
                    </strong>
                    , entre diversas outras.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default July;
