import React, { useContext, useEffect, useState } from 'react';
import DefaultTemplate from '../../../Templates/DefaultTemplate';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DOMPurify from 'dompurify';

import './PostStyles.scss';

import LikeButton from '../LikeButton';
import { useParams } from 'react-router-dom';
import useVerifyIfIsLogged from '../../../../Utils/useVerifyIfIsLogged';
import { GetBlogDetail } from '../../../../../Backend/blog';
import { LoadingContext } from '../../../../../Shared/LoadingContext';

const Post = () => {
  const [showScreen, setShowScreen] = useState();
  const [blogDetails, setBlogDetails] = useState();
  const { setLoading } = useContext(LoadingContext);
  const [liked, setLiked] = useState();
  const [video, setVideo] = useState();

  const { uuid } = useParams();

  const backgroundImages = [blogDetails?.banner];

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImages})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const createMarkup = () => {
    return { __html: blogDetails?.content };
  };

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  const getData = async () => {
    const response = await GetBlogDetail(uuid);
    if (response.success) {
      setBlogDetails({
        title: response?.data?.post?.[0]?.title,
        content: response?.data?.post?.[0]?.content,
        highlight: response?.data?.post?.[0]?.highlight,
        banner: response?.data?.post?.[0]?.image_full,
        category: response?.data?.post?.[0]?.category,
        uuid: response?.data?.post?.[0]?.uuid,
      });
      if (response?.data?.post?.[0]?.embed_video) {
        setVideo(response?.data?.post?.[0]?.embed_video);
      }

      setLiked(response?.data?.liked);
    }

    setLoading(false);
  };

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {showScreen === true && (
        <DefaultTemplate>
          <section className="post overflow-hidden">
            <div className="post-banner" style={backgroundStyle}>
              <div data-aos="fade-right">
                <div class="title flex items-end">
                  <div class="title-col bg-[#031C7A]">
                    <h1 className="text-[#F7D64C] font-semibold leading-none lg:w-full w-[161px]">
                      {blogDetails?.title?.toUpperCase()}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#FFD800] flex justify-center items-center lg:h-[124px] h-[143px] mt-[80px] lg:mt-0">
              <h2 className="text-[#031C7A] font-semibold lg:text-[30px] text-center text-[18px] lg:w-[594px] w-[234px] leading-tight">
                {blogDetails?.highlight.toUpperCase()}
              </h2>
            </div>

            <div className="post-details">
              {video ? (
                <div
                  style={{
                    padding: '56.25% 0 0 0',
                    position: 'relative',
                    marginBottom: '20px',
                  }}
                >
                  <iframe
                    src={video}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title="NeoPharma_Excelencia_Video_Blog_Beneficios_Final_0911.mp4"
                  ></iframe>
                </div>
              ) : undefined}
              <script src="https://player.vimeo.com/api/player.js"></script>

              <div dangerouslySetInnerHTML={createMarkup()} />
            </div>

            <div className="post-widgets w-[max-content] mx-auto mb-20 mt-10">
              <div className="flex lg:flex-row flex-col justify-center items-end lg:gap-[20px]">
                <div className="flex flex-col justify-center items-center  w-full mx-auto">
                  <p className="text-[#00287F] mb-1">Categoria</p>
                  <div className="bg-[#031E78] w-[max-content] px-[20px] mx-auto py-3 rounded-full">
                    <h1 className="text-white text-[18px] text-center font-semibold">
                      {blogDetails?.category.toUpperCase()}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center  mx-auto lg:mt-0 mt-5">
                  <LikeButton
                    post_uuid={blogDetails?.uuid}
                    setLiked={setLiked}
                    liked={liked}
                  />
                </div>
              </div>
            </div>
          </section>
        </DefaultTemplate>
      )}
    </>
  );
};

export default Post;
