/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import './EditPharmaStyles.scss';
// import step1PersonalDataMobile from '../../../../../../../Media/Incentive/Pages/Register/step2-personal-data-mobile.svg';
import { Controller, useForm } from 'react-hook-form';
import { getUfAndCity } from '../../../../Backend/getUfAndCity';
import InputText from '../../../../Components/InputText';
import Button from '../../../../Components/Button';
import { LoginTemplate } from '../../Templates/LoginTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { validateCnpjOnBlur } from '../../../Utils/validateCnpj';
import { verifyCompleteNameOnBlur } from '../../../Utils/verifyCompleteName';
import EditPharmaSuccess from '../EditPharmaSuccess';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { PostManagerEdit } from '../../../../Backend/managePharma';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import Init from '../../../Utils/Initializer';

const EditPharma = () => {
  Init();
  const [success, setSuccess] = useState(false);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);

  const [showScreen, setShowScreen] = useState();
  const {
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const { setLoading } = useContext(LoadingContext);

  const location = useLocation();

  const recaptchaRef = useRef(null);

  const navigate = useNavigate();

  const zipValue = watch('zip') ? watch('zip').replace(/-/, '') : '';

  const onSubmit = async (data, recaptchaToken) => {
    const response = await PostManagerEdit({ ...data, recaptchaToken });

    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      for (const key in response.data) {
        setLoading(false);

        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
      setLoading(false);
    }
  };

  const handleCustomSubmit = (data) => {
    setLoading(true);
    recaptchaRef.current?.executeAsync().then((recaptchaToken) => {
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const { verifyIfIsLogged } = useVerifyIfIsLogged();

  useEffect(() => {
    verifyIfIsLogged(setShowScreen);
  }, []);

  useEffect(() => {
    setValue('cnpj', location.state.cnpj);
    setValue('corporate_name', location.state.corporate_name);
    setValue('company', location.state.company);
    setValue('zip', location.state.zip);
    setValue('address', location.state.address);
    setValue('number', location.state.number);
    setValue('comp', location.state.comp);
    setValue('district', location.state.district);
    setValue('city', location.state.city);
    setValue('manager_name', location.state.contact);
    setValue('manager_email', location.state.email);
    setValue('uuid', location.state.uuid);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  return (
    <>
      {showScreen === true && (
        <>
          {success ? (
            <EditPharmaSuccess />
          ) : (
            <LoginTemplate variation="variation-1">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <section
                className="edit-pharma w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[130px] lg:!pl-[150px] lg:!pr-[150px] "
              >
                {/* <img src={step1PersonalDataMobile} alt="" /> */}

                <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[50px] w-[255px] lg:w-[578px] h-[99px] lg:h-[40px] flex flex-col items-center justify-center pt-[7px] mt-[30px]">
                  <h1 className="font-semibold">
                    EDITE AS
                    <br className="lg:hidden" /> INFORMAÇÕES{' '}
                    <br className="lg:hidden" /> DA FARMÁCIA
                  </h1>
                </div>

                <form
                  noValidate
                  onSubmit={handleSubmit(handleCustomSubmit)}
                  className="w-full"
                >
                  <div className="w-full flex flex-col gap-[20px] mt-[20px]">
                    <Controller
                      control={control}
                      name="cnpj"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="CNPJ"
                            styleLabel="!text-[#031E78]"
                            disabled
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('cnpj');
                            }}
                            inputMode="numeric"
                            onBlur={() => {
                              if (value) {
                                validateCnpjOnBlur({
                                  inputCnpj: value,
                                  setError,
                                  clearErrors,
                                });
                              }
                            }}
                            value={value}
                            mask="99.999.999/9999-99"
                            placeholder="00.000.000/0000-00"
                            errors={errors?.cnpj}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="corporate_name"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Razão social"
                            styleLabel="!text-[#031E78]"
                            placeholder="Razão social"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('corporate_name');
                            }}
                            value={value}
                            errors={errors?.corporate_name}
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name="company"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Nome fantasia"
                            styleLabel="!text-[#031E78]"
                            placeholder="Nome fantasia"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('company');
                            }}
                            value={value}
                            errors={errors?.company}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="zip"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="CEP"
                            styleLabel="!text-[#031E78]"
                            placeholder="CEP"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('zip');
                              if (watch('zip') && watch('zip').length === 8) {
                                getUfAndCity({ zipValue, setValue });
                              }
                            }}
                            inputMode="numeric"
                            value={value}
                            mask="99999-999"
                            errors={errors?.zip}
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="Endereço"
                            placeholder="Endereço"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('address');
                            }}
                            value={value}
                            errors={errors?.address}
                          />
                        );
                      }}
                    />
                    <div className="flex flex-col lg:flex-row gap-[18px]">
                      <Controller
                        control={control}
                        name="number"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Número"
                              placeholder="Número"
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value.replace(/[^0-9]/g, ''));
                                clearErrors('number');
                              }}
                              inputMode="numeric"
                              value={value}
                              errors={errors?.number}
                            />
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="comp"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Complemento"
                              placeholder="Complemento"
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('comp');
                              }}
                              value={value}
                              errors={errors?.comp}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-[18px]">
                      <Controller
                        control={control}
                        name="district"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Bairro"
                              placeholder="Bairro"
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('district');
                              }}
                              value={value}
                              errors={errors?.district}
                            />
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="city"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="Cidade"
                              placeholder="Cidade"
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('city');
                              }}
                              value={value}
                              errors={errors?.city}
                            />
                          );
                        }}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="manager_name"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label={
                              <span>
                                Nome do responsável <br className="lg:hidden" />
                                para receber comunicações
                              </span>
                            }
                            placeholder="Nome do responsável"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('manager_name');
                            }}
                            onBlur={() => {
                              verifyCompleteNameOnBlur({
                                inputName: value,
                                fieldName: 'manager_name',
                                setError,
                                clearErrors,
                              });
                            }}
                            value={value}
                            errors={errors?.manager_name}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="manager_email"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label={
                              <span>
                                Email do responsável{' '}
                                <br className="lg:hidden" />
                                para receber comunicações
                              </span>
                            }
                            placeholder="Email do responsável"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value.trim());
                              clearErrors('manager_email');
                            }}
                            value={value}
                            errors={errors?.manager_email}
                          />
                        );
                      }}
                    />

                    <div className="flex items-center flex-col lg:flex-row justify-center w-full gap-[18px] lg:gap-[28px]">
                      <Button
                        text="VOLTAR"
                        variant="bg-[#28CEE3] text-[#031E78] w-full  lg:w-[220px]"
                        type="button"
                        onClick={() => navigate('/gerenciar-farmacias')}
                      />
                      <Button
                        text="PROSSEGUIR"
                        variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full  lg:w-[220px]"
                        type="submit"
                      />
                    </div>
                  </div>
                </form>

                <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
                  Restrito a farmácias aptas <br className="lg:hidden" />
                  a participar do programa. <br />
                  Os dados cadastrados <br className="lg:hidden" />
                  devem ser de acordo <br className="lg:hidden" />
                  com <br className="hidden lg:block" /> o estabelecimento{' '}
                  <br className="lg:hidden" />e seus responsáveis.
                </div>
              </section>
            </LoginTemplate>
          )}
        </>
      )}
    </>
  );
};

export default EditPharma;
