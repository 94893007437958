import Client from './Client';
import { formatResponse } from './formatResponse';

export const GetFaqData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/fale-conosco');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};

export const PostFaqContact = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/fale-conosco', {
        contact_contacted: payload.contact_contacted,
        contact_message: payload.contact_message,
        contact_subject: payload.contact_subject,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
};
