/* eslint-disable react-hooks/exhaustive-deps */
import './RegisterOptins.scss';
import step3PersonalDataMobile from '../../../../../../../Media/Incentive/Pages/Register/step3-personal-data-mobile.svg';
import Button from '../../../../../../../../Components/Button';
import InputCheckbox from '../../../../../../../../Components/InputCheckbox';

const RegisterOptins = ({ control, errors, setStepRegister }) => {
  return (
    <section
      className="register-optins w-[320px] lg:!w-[904px] rounded-[10px] flex flex-col
     items-center justify-center mb-[60px] mt-[18px] lg:!pl-[150px] lg:!pr-[150px]"
    >
      <img src={step3PersonalDataMobile} alt="" />

      <div className="bg-[#ffd800] text-[24px] lg:text-[30px] text-center leading-[1] rounded-[35px] h-[70px] lg:h-[40px] pl-[30px] pr-[30px] lg:pl-[18px] lg:pr-[18px] flex flex-col items-center justify-center pt-[7px] mt-[25px]">
        <h1 className="font-semibold">
          ACEITE OS TERMOS
          <br className="lg:hidden" /> DE PARTICIPAÇÃO
        </h1>
      </div>

      <div className="flex flex-col gap-[21px] mt-[21px] mb-[21px] lg:mb-[33px] lg:mt-[33px] lg:gap-[24px] lg:pl-[20px] lg:pr-[20px] w-full">
        <InputCheckbox
          control={control}
          name="optin_rules"
          errors={errors.optin_rules}
          label={
            <span className="text-[18px] lg:text-[20px] leading-[1.2] text-[#031E78]">
              Li, aceito o{' '}
              <a
                href={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Neopharma_Excelencia.pdf`}
                target='_blank'
              >
                regulamento
              </a>{' '}
              <br className="lg:hidden" />
              e receber <br /> informações <br className="lg:hidden" />
              do Neo Pharma Excelência.
            </span>
          }
        />
        <InputCheckbox
          control={control}
          name="optin_privacy"
          errors={errors.optin_privacy}
          label={
            <span className="text-[18px] lg:text-[20px] leading-[1.2] text-[#031E78]">
              Li e aceito os Termos <br className="lg:hidden" />
              da <a href={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Privacidade_Neopharma_Excelencia.pdf`} 
                 target='_blank'
              >Política de Privacidade 
              </a>
              <br />
              do{' '}
              Programa Neo Pharma Excelência
            </span>
          }
        />
        <InputCheckbox
          control={control}
          name="optin_commercial"
          errors={errors.optin_commercial}
          label={
            <span className="text-[18px] lg:text-[20px] leading-[1.2] text-[#031E78]">
              Confirmo que sou <br className="lg:hidden" />
              o representante <br className="lg:hidden" />
              da farmácia e que <br /> informei <br className="lg:hidden" />
              corretamente os dados <br className="lg:hidden" />
              do estabelecimento <br />
              para <br className="lg:hidden" />
              participar do programa.
            </span>
          }
        />
      </div>

      <div className="flex items-center flex-col lg:flex-row justify-center w-full gap-[18px] lg:gap-[28px]">
        <Button
          text="VOLTAR"
          variant="bg-[#28CEE3] text-[#031E78] text-[18px] w-full lg:w-[220px]"
          type="button"
          onClick={() => setStepRegister(2)}
        />
        <Button
          text="CONCLUIR"
          variant="bg-[#ffd800] text-[#031E78] text-[18px] w-full lg:w-[220px]"
          type="submit"
        />
      </div>

      <div className="bg-[#28CEE3] text-[18px] w-full text-center rounded-[12px] text-[#031E78] font-medium leading-[1] pt-2 pb-2 mt-[30px] lg:pb-[13px] lg:pt-[13px]">
        Restrito a farmácias aptas <br className="lg:hidden" />
        a participar do programa. <br />
        Os dados cadastrados <br className="lg:hidden" />
        devem ser de acordo <br className="lg:hidden" />
        com <br className="hidden lg:block" /> o estabelecimento{' '}
        <br className="lg:hidden" />e seus responsáveis.
      </div>
    </section>
  );
};

export default RegisterOptins;
