export const fieldsInFirstStep = [
  'cpf',
  'cnpj',
  'name',
  'email',
  'email_confirmation',
  'birth',
  'sex',
  'mobile',
  'password',
  'password_confirmation',
];

export const fieldsInSecondStep = [
  'zip',
  'city',
  'company',
  'corporate_name',
  'address',
  'number',
  'comp',
  'district',
  'manager_name',
  'manager_email',
];

export const fieldsInThirdStep = [
  'optin_rules',
  'optin_privacy',
  'optin_commercial',
];
